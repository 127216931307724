import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getMessaging, isSupported } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
};

// Remove debug logging in production
if (process.env.NODE_ENV !== 'production') {
  console.log('Firebase config (without sensitive data):', {
    authDomain: firebaseConfig.authDomain,
    projectId: firebaseConfig.projectId,
    storageBucket: firebaseConfig.storageBucket,
    messagingSenderId: firebaseConfig.messagingSenderId
  });
}

// Initialize Firebase
let app;
try {
  app = initializeApp(firebaseConfig);
} catch (error) {
  console.error('Failed to initialize Firebase app:', error);
  throw error;
}

export const firebaseApp = app;
export const auth = getAuth(app);

// Initialize Firestore
let db;
try {
  db = getFirestore(app);
} catch (error) {
  console.error('Failed to initialize Firestore:', error);
  db = null;
}
export { db };

// Initialize Messaging
export const initializeMessaging = async () => {
  if (typeof window === 'undefined') return null;
  
  try {
    if (await isSupported()) {
      return getMessaging(app);
    }
    return null;
  } catch (err) {
    console.error('Failed to initialize Firebase messaging:', err);
    return null;
  }
};
