<template>
  <div class="admin-statistics-page">
    <h1>Administrativ statistik</h1>
    <AdminStatisticsCard />
  </div>
</template>

<script setup>
import AdminStatisticsCard from '@/components/AdminStatisticsCard.vue'
import { onMounted } from 'vue'
import { useMainStore } from '@/stores'

const store = useMainStore()

onMounted(async () => {
  await Promise.all([
    store.fetchUsers(),
    store.fetchPasses(),
    store.fetchSessions() // Make sure this action exists in your store
  ])
})
</script>

<style scoped>
.admin-statistics-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
</style>