<template>
  <div
    class="modal"
    @click="$emit('close')"
  >
    <div
      class="modal-content"
      @click.stop
    >
      <button
        class="close-button"
        @click="$emit('close')"
      >
        &times;
      </button>
      <ReceiptPage
        :payment="payment"
        :user="user"
      />
    </div>
  </div>
</template>

<script>
import ReceiptPage from '@/views/ReceiptPage.vue'

export default {
  name: 'PaymentReceiptModal',
  components: {
    ReceiptPage
  },
  props: {
    payment: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  emits: ['close']
}
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
}

.close-button {
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  background: none;
}
</style>