<template>
  <div class="receipt-page">
    <div
      ref="receiptContent"
      class="receipt"
    >
      <div class="header">
        <img
          src="@/assets/logo.webp"
          alt="Company Logo"
          class="logo"
        >
        <h1 class="invoice-title">
          KVITTO
        </h1>
      </div>

      <div class="invoice-info">
        <div
          v-if="payment.invoiceId"
          class="invoice-number"
        >
          Fakturanummer: {{ payment.invoiceId }}
        </div>
        <div class="invoice-date">
          Betalningsdatum: {{ formatDate(payment.paymentDate) }}
        </div>
      </div>

      <div class="receipt-details">
        <div class="seller">
          <h3>Från</h3>
          <template v-if="isBeforeCutoffDate">
            <p><strong>Banan i Trosa AB</strong></p>
            <p>Ejdergatan 6</p>
            <p>619 32 Trosa</p>
            <p>Org: 559359-7288</p>
            <p>Momsreg: SE559359728801E</p>
          </template>
          <template v-else>
            <p><strong>Banan Outdoor</strong></p>
            <p>Ejdergatan 6</p>
            <p>619 32 Trosa</p>
            <p>Org: 802547-2534</p>
          </template>
        </div>
        <div class="buyer">
          <h3>Till</h3>
          <p><strong>{{ user.firstName }} {{ user.lastName }}</strong></p>
          <p>{{ user.address }}</p>
          <p>{{ user.zipCode }} {{ user.city }}</p>
        </div>
      </div>

      <div class="invoice-details">
        <h3>Specifikation</h3>
        <table>
          <thead>
            <tr>
              <th>Beskrivning</th>
              <th>Belopp</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ payment.name }}</td>
              <td>{{ payment.total }} kr</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>Totalt (inkl. moms)</td>
              <td>{{ payment.total }} kr</td>
            </tr>
            <tr>
              <td>Varav moms ({{ payment.vatPercentage }}%)</td>
              <td>{{ payment.vat }} kr</td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div
        v-if="payment.type === 'trainingcard'"
        class="validity"
      >
        <p><strong>Träningskortets giltighetstid:</strong> {{ formatDate(payment.validFrom) }} - {{ formatDate(payment.validTo) }}</p>
      </div>
    </div>

    <button
      class="print-button"
      @click="printReceipt"
    >
      Skriv ut kvitto
    </button>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'ReceiptPage',
  props: {
    payment: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const formatDate = (dateString) => {
      if (!dateString) return '';
      const date = new Date(dateString);
      return date.toLocaleDateString('sv-SE', { year: 'numeric', month: '2-digit', day: '2-digit' });
    };

    const printReceipt = () => {
      const printWindow = window.open('', '_blank');
      printWindow.document.write('<html><head><title>Kvitto</title>');
      printWindow.document.write('<style>');
      printWindow.document.write(`
        body { font-family: Arial, sans-serif; }
        .receipt { padding: 20px; max-width: 800px; margin: 0 auto; }
        .header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 30px; }
        .logo { max-width: 100px; }
        .invoice-title { font-size: 24px; color: #333; margin: 0; }
        .invoice-info { display: flex; justify-content: space-between; margin-bottom: 30px; font-size: 14px; }
        .receipt-details { display: flex; justify-content: space-between; margin-bottom: 30px; }
        .seller, .buyer { flex: 1; }
        h3 { color: #555; border-bottom: 1px solid #ddd; padding-bottom: 5px; margin-bottom: 10px; font-size: 16px; }
        table { width: 100%; border-collapse: collapse; margin-top: 20px; font-size: 12px; }
        th, td { border: 1px solid #ddd; padding: 10px; text-align: left; }
        th { background-color: #f8f8f8; }
        tfoot { font-weight: bold; }
        .validity { margin-top: 30px; font-style: italic; }
      `);
      printWindow.document.write('</style></head><body>');
      printWindow.document.write(document.querySelector('.receipt').outerHTML);
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.focus();
      setTimeout(() => {
        printWindow.print();
        printWindow.close();
      }, 250);
    };

    const isBeforeCutoffDate = computed(() => {
      const cutoffDate = new Date('2024-09-30');
      const paymentDate = new Date(props.payment.paymentDate);
      return paymentDate < cutoffDate;
    });

    return {
      printReceipt,
      formatDate,
      isBeforeCutoffDate
    };
  }
}
</script>

<style scoped>
.receipt-page {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.receipt {
  border: 1px solid #ddd;
  padding: 40px;
  margin-bottom: 20px;
  background-color: #fff;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.logo {
  max-width: 100px;
}

.invoice-title {
  font-size: 24px;
  color: #333;
  margin: 0;
}

.invoice-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  font-size: 14px;
}

.receipt-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.seller, .buyer {
  flex: 1;
}

h3 {
  color: #555;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  margin-bottom: 10px;
  font-size: 16px;
}

.invoice-details table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: 11px;
}

.invoice-details th, .invoice-details td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.invoice-details th {
  background-color: #f8f8f8;
}

.invoice-details tfoot {
  font-weight: bold;
}

.validity {
  margin-top: 30px;
  font-style: italic;
}

.print-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.print-button:hover {
  background-color: #45a049;
}

@media print {
  .print-button {
    display: none;
  }
  
  .receipt-page {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    background-color: white;
    overflow: hidden;
  }
  
  .receipt {
    border: none;
    padding: 20px;
    max-width: none;
    width: 100%;
    box-sizing: border-box;
    page-break-after: avoid;
  }

  body * {
    visibility: hidden;
  }

  .receipt-page, .receipt-page * {
    visibility: visible;
  }

  /* Hide the close button */
  .close-button, 
  [class*="close-button"],
  [id*="close-button"] {
    display: none !important;
  }

  /* Ensure content fits on one page */
  .logo {
    max-width: 100px;
  }

  .invoice-title {
    font-size: 24px;
  }

  h3 {
    font-size: 16px;
  }

  p, td, th {
    font-size: 12px;
  }

  .invoice-details table {
    font-size: 11px;
  }
}
</style>

<style>
@media print {
  /* Global styles to hide modal close buttons and other unwanted elements */
  .modal-close,
  .modal-header .close,
  .close-icon,
  [class*="close-button"],
  [id*="close-button"] {
    display: none !important;
  }
}

@media print {
  /* ... other global styles ... */

  @page {
    size: auto;
    margin: 0mm;
  }

  html, body {
    height: 100%;
    overflow: hidden;
  }
}
</style>