const BLOB_STORAGE_URL = process.env.VUE_APP_BLOB_STORAGE_URL;
const SAS_TOKEN = process.env.VUE_APP_BLOB_SAS_TOKEN;

export const getImageUrl = (imagePath) => {
  if (!imagePath) return null;
  
  // If the imagePath is already a full URL, return it as is
  if (imagePath.startsWith('http://') || imagePath.startsWith('https://')) {
    return imagePath;
  }
  
  // Otherwise, construct the full URL
  const url = `${BLOB_STORAGE_URL}/${imagePath}${SAS_TOKEN ? `?${SAS_TOKEN}` : ''}`;
  console.log('Constructed image URL:', url);
  return url;
}

// Add this function for debugging
export function logStorageConfig() {
  console.log('BLOB_STORAGE_URL:', BLOB_STORAGE_URL);
  console.log('SAS_TOKEN:', SAS_TOKEN ? 'Set' : 'Not set');
  console.log('Environment:', process.env.NODE_ENV);
}