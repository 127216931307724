<template>
  <div class="user-avatars">
    <v-avatar
      v-for="userId in visibleUserIds"
      :key="userId"
      :size="size"
      class="mr-1"
    >
      <v-img
        :src="getUserProfilePicture(userId) || defaultProfilePicture"
        :alt="userNames[userId] || 'Loading...'"
        :loading="!getUserProfilePicture(userId)"
      />
    </v-avatar>
    <span
      v-if="remainingUsers > 0"
      class="remaining-users"
    >+{{ remainingUsers }}</span>
  </div>
</template>

<script>
import { computed, watch, toRefs } from 'vue'
import defaultProfilePicture from '@/assets/default-profile-picture.webp'

export default {
  name: 'UserAvatars',
  props: {
    userIds: {
      type: Array,
      required: true
    },
    maxVisible: {
      type: Number,
      default: 3
    },
    size: {
      type: Number,
      default: 40
    },
    getUserProfilePicture: {
      type: Function,
      required: true
    },
    userNames: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { userIds } = toRefs(props)

    console.log('UserAvatars props:', props)

    const visibleUserIds = computed(() => userIds.value.slice(0, props.maxVisible))
    const remainingUsers = computed(() => Math.max(0, userIds.value.length - props.maxVisible))

    watch(userIds, (newUserIds) => {
      console.log('UserAvatars userIds changed:', newUserIds)
    }, { immediate: true })

    return {
      visibleUserIds,
      remainingUsers,
      defaultProfilePicture
    }
  }
}
</script>

<style scoped>
.user-avatars {
  display: flex;
  align-items: center;
}

.remaining-users {
  margin-left: 10px;
  font-size: 12px;
  font-weight: bold;
}
</style>