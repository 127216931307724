<template>
  <div class="statutes-page container mx-auto px-4 py-8">
    <h1 class="text-3xl font-bold mb-6">
      Stadgar
    </h1>
    <p class="mb-8">
      Här kan du läsa Banan Outdoors stadgar, som reglerar föreningens verksamhet och organisation.
    </p>

    <nav
      aria-label="Table of Contents"
      class="mb-8"
    >
      <h2 class="text-2xl font-semibold mb-4">
        Innehållsförteckning
      </h2>
      <ul class="list-disc list-inside">
        <li
          v-for="(section, index) in statutes"
          :key="index"
        >
          <a
            :href="`#section-${index}`"
            class="text-blue-600 hover:underline"
          >{{ section.title }}</a>
        </li>
      </ul>
    </nav>

    <div class="space-y-6">
      <template
        v-for="(section, index) in statutes"
        :key="index"
      >
        <section :id="`section-${index}`">
          <h2 class="text-2xl font-semibold mt-8 mb-4">
            {{ section.title }}
          </h2>
          <p
            v-for="(paragraph, pIndex) in section.content"
            :key="pIndex"
            class="mb-4"
          >
            {{ paragraph }}
          </p>
        </section>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const statutes = ref([])

onMounted(async () => {
  // Simulate API call or data loading
  await new Promise(resolve => setTimeout(resolve, 100))
  statutes.value = [
    {
      title: '§1 Namn och säte',
      content: ['Föreningen ska benämnas Banan Outdoor och har sitt säte i Trosa.']
    },
    {
      title: '§2 Ändamål och verksamhet',
      content: [
        'Föreningen syftar till att främja hälsa och välbefinnande genom att tillhandahålla träningsaktiviteter enligt konceptet "Banan Outdoor". Föreningen strävar efter att vara öppen för alla och verkar utan vinstsyfte.'
      ]
    },
    {
      title: '§3 Medlemskap',
      content: [
        'Föreningen består av fysiska och juridiska personer som beviljats medlemskap. Alla som stödjer föreningens ändamål har rätt att ansöka om medlemskap. Medlemskap beviljas av styrelsen eller av den som styrelsen delegerar denna rätt till.',
        'Medlemskapet är tills vidare och upphör enligt de regler som anges under avsnittet om utträde och uteslutning.',
        'Medlemmars skyldigheter och rättigheter:',
        'Medlemmar förväntas följa föreningens stadgar och beslut, betala medlemsavgifter, och genom sitt medlemskap accepterar de behandling av personuppgifter i enlighet med dataskyddslagstiftningen. Medlemmar har rätt att delta i föreningens sammankomster och aktiviteter men har inte rätt till föreningens tillgångar vid upplösning.',
        'Utträde och uteslutning:',
        'Medlem som önskar utträda ur föreningen ska meddela detta skriftligen. Föreningen kan besluta om upphörande av medlemskap vid utebliven betalning av medlemsavgift. Medlem kan uteslutas vid beteenden som strider mot föreningens ändamål eller stadgar. Beslut om uteslutning eller varning ska meddelas skriftligen till medlemmen med angivande av skäl och överklagandeprocedur.'
      ]
    },
    {
      title: '§4 Beslutande organ',
      content: [
        'Föreningens beslutande organ är årsmötet, extra årsmöte, och styrelsen.'

      ]
    },
    {
      title: '§5 Styrelse',
      content: [
        'Föreningens styrelse ska bestå av ordförande, sekreterare och kassör. Styrelsen ansvarar för föreningens organisation och verksamhetens drift enligt dessa stadgar och gällande lagstiftning. '
      ]
    },
    {
      title: '§6 Firmateckning',
      content: [
        'Föreningens firma tecknas av styrelsen gemensamt. Styrelsen kan delegera firmateckningsrätten till två styrelseledamöter gemensamt eller till en eller flera särskilt utsedda personer. '
      ]
    },
    {
      title: '§7 Ekonomi och räkenskapsår ',
      content: [
        'Föreningens ekonomi ska hanteras enligt principer som säkerställer en sund ekonomisk förvaltning. Räkenskapsåret löper från 1 januari till 31 december.'
      ]
    },
    {
      title: '§8 Stadgeändringar ',
      content: [
        'För ändringar av dessa stadgar krävs beslut av årsmötet med minst 2/3 av de avgivna rösterna. Förslag till stadgeändringar måste lämnas skriftligen av styrelsen. '
      ]
    },
    {
    title: '$9 Upplösning av föreningen ',
      content: [
        'För upplösning av föreningen krävs beslut av årsmötet med minst 2/3 av de avgivna rösterna. Vid upplösning ska föreningens tillgångar användas för ett av föreningen beslutat ändamål. Föreningens dokumentation ska arkiveras enligt årsmötets beslut. '
      ]
    },
    {
    title: '$10 Tidpunkt och kallelse för årsmöte ',
      content: [
        'Årsmötet ska hållas före utgången av mars varje år. Kallelse och föredragningslista ska tillhandahållas medlemmarna minst tre veckor i förväg. Viktiga punkter som stadgeändringar eller andra stora beslut måste inkluderas i kallelsen. '
      ]
    },   
    {
    title: '§11 Sammansättning och beslutförhet på årsmöte ',
      content: [
        'Årsmötet består av närvarande medlemmar eller deras ombud, med rätt för vårdnadshavare att företräda omyndiga barn. Mötet är beslutsmässigt oavsett antalet närvarande.'
      ]
    },    
    {
    title: '§12 Rösträtt samt yttrande- och förslagsrätt på årsmötet ',
      content: [
        'Rösträtt kräver att medlemskapet beviljats minst två månader före årsmötet och att medlemsavgifter är betalda senast två månader före mötet. '
      ]
    },
    {
    title: '$13 Ärenden vid årsmötet ',
      content: [
        'Inkluderar fastställande av röstlängd, val av mötesfunktionärer, styrelsens och revisorernas rapporter, ansvarsfrihet för styrelsen, fastställande av medlemsavgifter och budget, samt val av styrelsemedlemmar, revisorer och valberedning. '
      ]
    },
    {
    title: '§14 Extra årsmöte ',
      content: [
        'Kan kallas av styrelsen eller på begäran av revisorer eller en betydande del av medlemmarna. Särskilda regler för kallelse och genomförande av extra årsmöten. '
      ]
    },
    {
    title: '§15 Beslut och omröstning ',
      content: [
        'Beslut fattas vanligen med acklamation eller, om begärt, genom omröstning. Majoritetsregler specificeras för olika typer av beslut. '
      ]
    },
    {
    title: '§16 Ikraftträdande ',
      content: [
        'Beslut gäller från årsmötets avslut om inte annat beslutas. '
      ]
    },
    {
    title: '§17 Valberedning och dess arbete ',
      content: [
        'Specifikationer för valberedningens sammansättning, arbetsprocess och uppgifter. '
      ]
    },
    {
    title: '§18 Revision ',
      content: [
        'Föreningens räkenskaper och förvaltning ska årligen granskas av den eller de revisorer som årsmötet utsett. ',
        'Revisorerna har rätt att fortlöpande ta del av föreningens räkenskaper, årsmötes- och styrelseprotokoll och övriga handlingar. Föreningens räkenskaper för det senaste verksamhets- och räkenskapsåret ska vara revisorerna tillhanda senast en månad före årsmötet. Revisorerna ska granska styrelsens räkenskaper och förvaltning för det senaste verksamhets- och räkenskapsåret samt till styrelsen överlämna revisionsberättelse senast 14 dagar före årsmötet. '
      ]
    },
    {
    title: '$19 Styrelsens sammanträden och beslutsförfarande ',
      content: [
        'Styrelsen ska sammanträda på kallelse av ordföranden. Ordföranden är skyldig att kalla till sammanträde då minst två ledamöter har begärt det. Underlåter ordföranden att utfärda kallelse får de som begärt sammanträdet kalla till det. Styrelsen är beslutsmässig när samtliga ledamöter kallats och då minst halva antalet ledamöter är närvarande. För alla beslut krävs att minst hälften av styrelsens samtliga ledamöter är ense om beslutet. Vid lika röstetal har ordföranden utslagsröst. Röstning får inte ske genom ombud. Ordföranden får besluta att ärende ska avgöras genom skriftlig omröstning (beslut per capsulam) eller vid telefonsammanträde eller med hjälp av annan teknisk utrustning. Vid sammanträde eller beslutsfattande enligt föregående stycke ska protokoll upprättas. Protokoll ska undertecknas av mötesordföranden och utsedd protokollsekreterare. Avvikande mening ska antecknas i protokollet. '
      ]
    },
    {
    title: '$20 Överlåtelse av beslutanderätten ',
      content: [
        'Styrelsen får överlåta sin beslutanderätt i enskilda ärenden eller i vissa grupper av ärenden till kommitté eller annat organ eller till enskild medlem, anställd eller annan utsedd person. Sådana uppdrag kan gälla för viss tid eller tillsvidare. Den som fattat beslut med stöd av bemyndigande enligt föregående stycke ska fortlöpande underrätta styrelsen härom.'
      ]
    },
    {
    title: '$21 Licensiering ',
      content: [
        'Föreningen ska licensiera konceptet "Banan Outdoor", inklusive logotyp och domän, från Banan i Trosa AB. Villkor för licensieringen ska regleras i ett separat avtal mellan föreningen och Banan i Trosa AB.'
      ]
    }
  ]
})
</script>