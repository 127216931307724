<template>
  <div>
    <slot 
      v-if="!error" 
    />
    <div v-else>
      <p>An error occurred: {{ error.message }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: null
    }
  },
  errorCaptured(err) {
    this.error = err
    console.error('Error captured in boundary:', err)
    return false
  }
}
</script>