<template>
  <div class="about-page">
    <div class="content-container">
      <h1 class="page-title">
        Om Banan Outdoor
      </h1>
      
      <div class="content-section">
        <p>Banan Outdoor är en ideell förening dedikerad till att främja utomhusträning och hälsosam livsstil i Trosa-området.</p>
        
        <section class="mb-8">
          <h2>Vår vision</h2>
          <p>Att skapa en gemenskap där människor kan njuta av naturen samtidigt som de förbättrar sin hälsa och välbefinnande.</p>
        </section>
        
        <section class="mb-8">
          <h2>Vår historia</h2>
          <p>Grundad 2024 av en grupp entusiaster som ville dela sin passion för utomhusträning med andra men med en rik historia som började 2012.</p>
        </section>
      </div>
    </div>
  </div>
</template>

<style scoped>
.about-page {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: var(--background-color);
  color: var(--text-color);
}

.content-container {
  background-color: var(--card-background);
  border-radius: 0.5rem;
  padding: 2rem;
  box-shadow: var(--shadow-sm);
  transition: box-shadow var(--transition-speed) ease;
}

.content-container:hover {
  box-shadow: var(--shadow-md);
}

.page-title {
  color: var(--text-color);
  font-family: var(--font-heading);
  font-size: 2.5rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--primary-color);
}

.content-section {
  margin-top: 2rem;
}

section {
  margin-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid var(--border-color);
}

section:last-child {
  border-bottom: none;
}

h2 {
  color: var(--text-color);
  font-family: var(--font-heading);
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

p {
  color: var(--text-secondary-color);
  line-height: 1.6;
  margin-bottom: 1rem;
}
</style>
