<template>
  <div class="manage-payments-page">
    <h1>Hantera betalningar</h1>
    <div v-if="loading">
      Laddar...
    </div>
    <div v-else-if="error">
      {{ error }}
    </div>
    <div v-else>
      <v-select
        v-model="selectedStatus"
        :items="statusOptions"
        item-title="title"
        item-value="value"
        label="Filtrera efter status"
        class="mb-4"
      />
      <h2>{{ tableTitle }}</h2>
      <v-data-table
        :headers="headers"
        :items="filteredSubscriptions"
        :items-per-page="10"
        class="elevation-1"
      >
        <template #[`item.userName`]="{ item }">
          {{ item?.userName ?? 'N/A' }}
        </template>
        <template #[`item.type`]="{ item }">
          {{ item?.type === 'membership' ? 'Medlemskap' : item?.type === 'trainingcard' ? 'Träningskort' : 'N/A' }}
        </template>
        <template #[`item.startDate`]="{ item }">
          {{ formatDate(item?.startDate) }}
        </template>
        <template #[`item.endDate`]="{ item }">
          {{ formatDate(item?.endDate) }}
        </template>
        <template #[`item.amount`]="{ item }">
          {{ item?.amount || item?.amountExVat }} {{ item?.currency }}
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
            small
            color="primary"
            :disabled="!item"
            @click="openConfirmationModal(item)"
          >
            Hantera betalning
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <!-- Confirmation Modal -->
    <v-dialog
      v-model="showConfirmationModal"
      max-width="500px"
    >
      <v-card>
        <v-card-title>Hantera betalning</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="confirmPayment">
            <v-menu
              v-model="paymentDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedSubscription.paymentDate"
                  label="Betalningsdatum"
                  prepend-icon="fas fa-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="selectedSubscription.paymentDate"
                @input="paymentDateMenu = false"
              />
            </v-menu>

            <v-select
              v-model="selectedSubscription.paymentMethod"
              :items="paymentMethods"
              label="Betalningsmetod"
            />

            <v-select
              v-model="selectedSubscription.status"
              :items="statusOptionsForModal"
              item-text="title"
              item-value="value"
              label="Status"
            />

            <v-menu
              v-model="startDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedSubscription.startDate"
                  label="Startdatum"
                  prepend-icon="fas fa-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="selectedSubscription.startDate"
                @input="startDateMenu = false"
              />
            </v-menu>

            <v-menu
              v-model="endDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedSubscription.endDate"
                  label="Slutdatum"
                  prepend-icon="fas fa-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="selectedSubscription.endDate"
                @input="endDateMenu = false"
              />
            </v-menu>

            <v-text-field
              v-model.number="selectedSubscription.amountExVat"
              label="Belopp (exkl. moms)"
              type="number"
            />

            <v-text-field
              v-model.number="selectedSubscription.vat"
              label="Moms"
              type="number"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="showConfirmationModal = false"
          >
            Avbryt
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="confirmPayment"
          >
            Bekräfta
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useMainStore } from '@/stores';
import { api } from '@/services/api';

export default {
  name: 'ManagePaymentsPage',
  setup() {
    const store = useMainStore();
    const loading = ref(true);
    const error = ref(null);
    const users = ref([]);
    const showConfirmationModal = ref(false);
    const selectedSubscription = ref({});
    const paymentDateMenu = ref(false);
    const startDateMenu = ref(false);
    const endDateMenu = ref(false);
    const selectedStatus = ref('pending');

    const headers = [
      { text: 'Användare', value: 'userName' },
      { text: 'Typ', value: 'type' },
      { text: 'Startdatum', value: 'startDate' },
      { text: 'Slutdatum', value: 'endDate' },
      { text: 'Belopp', value: 'amount' },
      { text: 'Status', value: 'status' },
      { text: 'Åtgärder', value: 'actions', sortable: false }
    ];

    const paymentMethods = ['Swish', 'Bankgiro', 'Bankkonto', 'Kontant', 'Övrigt'];
    const statusOptions = [
      { title: 'Väntande', value: 'pending' },
      { title: 'Bekräftade', value: 'confirmed' },
      { title: 'Avvisade', value: 'rejected' },
      { title: 'Alla', value: 'all' }
    ];

    console.log('Initial statusOptions:', statusOptions.value);

    const statusOptionsForModal = [
      { title: 'Väntande', value: 'pending' },
      { title: 'Bekräftad', value: 'confirmed' },
      { title: 'Avvisad', value: 'rejected' }
    ];

    const fetchUsers = async () => {
      try {
        if (store.users && store.users.length > 0) {
          users.value = store.users;
        } else {
          const response = await api.getUsers();
          users.value = response.data;
        }
        console.log('Fetched users:', users.value);
      } catch (err) {
        console.error('Error fetching users:', err);
        error.value = 'Kunde inte hämta användare. Försök igen senare.';
      } finally {
        loading.value = false;
      }
    };

    const allSubscriptions = computed(() => {
      const subscriptions = users.value.flatMap(user => 
        (user.memberships || []).map(membership => ({
          ...membership,
          userName: `${user.firstName} ${user.lastName}`,
          userId: user.id
        }))
      );
      console.log('All subscriptions:', subscriptions);
      return subscriptions;
    });

    const filteredSubscriptions = computed(() => {
      const subscriptions = allSubscriptions.value.filter(subscription => subscription != null);
      console.log('Filtered subscriptions before status filter:', subscriptions);
      console.log('Current selectedStatus:', selectedStatus.value);
      if (selectedStatus.value === 'all') {
        return subscriptions;
      }
      const result = subscriptions.filter(subscription => subscription.status === selectedStatus.value);
      console.log('Filtered subscriptions after status filter:', result);
      return result;
    });

    const tableTitle = computed(() => {
      switch (selectedStatus.value) {
        case 'pending':
          return 'Väntande prenumerationer';
        case 'confirmed':
          return 'Bekräftade prenumerationer';
        case 'rejected':
          return 'Avvisade prenumerationer';
        case 'all':
          return 'Alla prenumerationer';
        default:
          return 'Prenumerationer';
      }
    });

    const formatDate = (dateString) => {
      if (!dateString) return 'N/A';
      return new Date(dateString).toLocaleDateString('sv-SE');
    };

    const openConfirmationModal = (subscription) => {
      if (!subscription) return;
      
      const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
      const defaultDate = '0001-01-01T00:00:00';
      
      selectedSubscription.value = { 
        ...subscription,
        paymentDate: subscription.status === 'pending' && 
                     (subscription.paymentDate === defaultDate || new Date(subscription.paymentDate) < new Date('2020-01-01'))
                     ? today
                     : subscription.paymentDate
      };
      
      showConfirmationModal.value = true;
    };

    const confirmPayment = async () => {
      try {
        if (!selectedSubscription.value || !selectedSubscription.value.userId) {
          throw new Error('Invalid subscription data');
        }

        const userIndex = users.value.findIndex(user => user.id === selectedSubscription.value.userId);
        if (userIndex === -1) {
          throw new Error('User not found');
        }

        const user = users.value[userIndex];
        const originalMembership = user.memberships.find(m => m.id === selectedSubscription.value.id);
        const statusChanged = originalMembership.status !== selectedSubscription.value.status;
        const confirmedNow = selectedSubscription.value.status === 'confirmed';

        const updatedMemberships = user.memberships.map(membership => 
          membership.id === selectedSubscription.value.id ? selectedSubscription.value : membership
        );

        let updateObject = {
          memberships: updatedMemberships
        };

        // Only create a payment record if the status is changed to 'confirmed'
        if (statusChanged && confirmedNow) {
          // Calculate the total amount
          const total = Number(selectedSubscription.value.amountExVat) + Number(selectedSubscription.value.vat);

          // Create the payment record
          const paymentRecord = {
            name: selectedSubscription.value.type === 'membership' ? 'Medlemskap' : 'Träningskort',
            price: selectedSubscription.value.amountExVat,
            paymentDate: selectedSubscription.value.paymentDate,
            paymentStatus: 'confirmed',
            validFrom: selectedSubscription.value.startDate,
            validTo: selectedSubscription.value.endDate,
            vat: selectedSubscription.value.vat,
            vatPercentage: `${(selectedSubscription.value.vat / selectedSubscription.value.amountExVat * 100).toFixed(2)}`,
            total: total,
            currency: selectedSubscription.value.currency,
            type: selectedSubscription.value.type
          };

          updateObject.payments = [...(user.payments || []), paymentRecord];
        }

        const response = await api.updateUser(selectedSubscription.value.userId, updateObject);

        if (response.data && !response.data.error) {
          users.value[userIndex].memberships = response.data.memberships;
          if (response.data.payments) {
            users.value[userIndex].payments = response.data.payments;
          }
          showConfirmationModal.value = false;
          console.log('Subscription updated successfully');
          if (statusChanged && confirmedNow) {
            console.log('Payment record created');
          }
          // Optionally, you can add a success message here
        } else {
          throw new Error(response.data?.error || 'Failed to update subscription');
        }
      } catch (err) {
        console.error('Error updating subscription:', err);
        error.value = 'Kunde inte uppdatera prenumerationen. Försök igen senare.';
        // Optionally, you can add an error message to display to the user
      }
    };

    onMounted(() => {
      console.log('Component mounted');
      fetchUsers();
    });

    return {
      loading,
      error,
      filteredSubscriptions,
      headers,
      formatDate,
      showConfirmationModal,
      selectedSubscription,
      paymentDateMenu,
      startDateMenu,
      endDateMenu,
      paymentMethods,
      statusOptions,
      statusOptionsForModal,
      selectedStatus,
      tableTitle,
      openConfirmationModal,
      confirmPayment
    };
  }
};
</script>

<style scoped>
.manage-payments-page {
  padding: 20px;
}
</style>
