<template>
  <div class="admin-page">
    <h1>Administratörspanel</h1>
    <div class="admin-content">
      <div class="admin-links">
        <h2>Administrationslänkar</h2>
        <router-link
          to="/members"
          class="btn btn-secondary"
        >
          <i class="fad fa-users" /> Medlemslista
        </router-link>
        <router-link
          to="/admin/statistics"
          class="btn btn-secondary"
        >
          <i class="fad fa-chart-line" /> Visa statistik
        </router-link>
        <router-link
          to="/admin/manage-payments"
          class="btn btn-secondary"
        >
          <i class="fad fa-credit-card" /> Hantera betalningar
        </router-link>
        <router-link
          to="/admin/edit-places"
          class="btn btn-secondary"
        >
          <i class="fad fa-map-marker-alt" /> Redigera platser
        </router-link>
        <router-link
          to="/admin/edit-exercises"
          class="btn btn-secondary"
        >
          <i class="fad fa-dumbbell" /> Redigera övningar
        </router-link>
        <router-link
          to="/admin/edit-equipment"
          class="btn btn-secondary"
        >
          <i class="fad fa-toolbox" /> Redigera utrustning
        </router-link>
        <router-link
          to="/admin/edit-sessions"
          class="btn btn-secondary"
        >
          <i class="fad fa-calendar-alt" /> Redigera pass
        </router-link>
        <router-link
          to="/admin/new-post"
          class="btn btn-secondary"
        >
          <i class="fad fa-plus-square" /> Skapa nytt inlägg
        </router-link>
        <router-link
          to="/admin/manage-posts"
          class="btn btn-secondary"
        >
          <i class="fad fa-edit" /> Hantera inlägg
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'AdminPage',
  setup() {
    const message = ref('')

    const saveMessage = () => {
      localStorage.setItem('homePageMessage', message.value)
      alert('Meddelandet har sparats!')
    }

    message.value = localStorage.getItem('homePageMessage') || ''

    return {
      message,
      saveMessage
    }
  }
}
</script>

<style scoped>
.admin-page {
  max-width: 1200px; 
  margin: 0 auto;
  padding: 20px;
}

.admin-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

h1, h2 {
  color: #333;
  margin-bottom: 20px;
}

.message-editor, .admin-links {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

textarea {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  resize: vertical;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-align: left;
}

.btn-secondary i {
  margin-right: 10px;
  font-size: 1.2em;
}

.btn-secondary:hover {
  background-color: #545b62;
}

.admin-statistics {
  grid-column: 1 / -1; 
  margin-bottom: 20px;
}
</style>