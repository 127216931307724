<template>
  <div class="contact-page">
    <h1>Kontakta oss</h1>
    <p>Vi finns här för att svara på dina frågor och ta emot feedback.</p>
    <h2>Kontaktinformation</h2>
    <ul>
      <li>E-post: info@bananoutdoor.com</li>
      <li>Postadress: Ejdergatan 6, 619 32 Trosa</li>
      <li>Besöksadress: Friluftsvägen, 619 34 Trosa</li>
      <li>Org.nr: 802547-2534</li>
    </ul>
    <h2>Kontaktformulär</h2>
    <form @submit.prevent="submitForm">
      <input
        v-model="formData.name"
        type="text"
        placeholder="Ditt namn"
        required
      >
      <input
        v-model="formData.email"
        type="email"
        placeholder="Din e-postadress"
        required
      >
      <textarea
        v-model="formData.message"
        placeholder="Ditt meddelande"
        required
      />
      <button 
        type="submit"
        :disabled="isSubmitting"
      >
        {{ isSubmitting ? 'Skickar...' : 'Skicka' }}
      </button>
    </form>
    <p
      v-if="submitStatus"
      :class="submitStatus.type"
    >
      {{ submitStatus.message }}
    </p>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import axios from 'axios'

const formData = reactive({
  name: '',
  email: '',
  message: ''
})
const isSubmitting = ref(false)
const submitStatus = ref(null)

const submitForm = async () => {
  isSubmitting.value = true
  submitStatus.value = null

  const payload = {
    personalizations: [
      {
        to: [{ email: 'info@bananoutdoor.com' }]
      }
    ],
    from: { email: 'info@bananoutdoor.com' },
    subject: 'Meddelande från användare på BananOutdoor.com',
    content: [
      {
        type: 'text/plain',
        value: `Name: ${formData.name}\nEmail: ${formData.email}\nMessage: ${formData.message}`
      }
    ]
  }

  try {
    const response = await axios.post('/send-email', payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    })

    if (response.status === 200) {
      submitStatus.value = { type: 'success', message: 'Tack för ditt meddelande! Vi återkommer så snart som möjligt.' }
      // Reset form fields
      formData.name = ''
      formData.email = ''
      formData.message = ''
    } else {
      throw new Error('Failed to send email')
    }
  } catch (error) {
    console.error('Error submitting form:', error)
    if (error.response) {
      console.error('Server response:', JSON.stringify(error.response.data, null, 2))
    }
    submitStatus.value = { type: 'error', message: 'Det uppstod ett fel när meddelandet skulle skickas. Vänligen försök igen senare.' }
  } finally {
    isSubmitting.value = false
  }
}
</script>

<style scoped>
.contact-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

input, textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

textarea {
  height: 150px;
}

button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.success {
  color: green;
}

.error {
  color: red;
}
</style>
