import { computed, watch } from 'vue'
import { useMainStore } from '@/stores'
import { storeToRefs } from 'pinia'

export function useUserState() {
  const store = useMainStore()
  const { user } = storeToRefs(store)
  
  const currentUser = computed(() => user.value)
  const isLoggedIn = computed(() => !!currentUser.value)
  const isStaff = computed(() => currentUser.value?.role === 'staff')

  watch(currentUser, (newUser) => {
    console.log('Current user changed in useUserState:', newUser)
    console.log('New isStaff value:', isStaff.value)
  }, { immediate: true })

  return {
    currentUser,
    isLoggedIn,
    isStaff
  }
}
