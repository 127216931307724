<template>
  <div class="image-uploader">
    <input
      ref="fileInput"
      type="file"
      :accept="acceptedFileTypes"
      style="display: none;"
      @change="handleFileChange"
    >
    <v-btn
      :color="buttonColor"
      @click="triggerFileInput"
    >
      {{ buttonText }}
    </v-btn>
    <v-progress-circular
      v-if="isUploading"
      indeterminate
      color="primary"
    />
    <div
      v-if="errorMessage"
      class="error-message"
    >
      {{ errorMessage }}
    </div>
    <div
      v-if="previewUrl"
      class="image-preview"
    >
      <img
        :src="previewUrl"
        alt="Preview"
      >
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { BlobServiceClient } from '@azure/storage-blob';
import imageCompression from 'browser-image-compression'; // Add this import

export default {
  name: 'ImageUploader',
  props: {
    acceptedFileTypes: {
      type: String,
      default: 'image/*'
    },
    buttonText: {
      type: String,
      default: 'Upload Image'
    },
    buttonColor: {
      type: String,
      default: 'primary'
    },
    maxCompressedSize: {
      type: Number,
      default: 5 * 1024 * 1024 // 5MB
    }
  },
  emits: ['upload-success', 'upload-error'],
  setup(props, { emit }) {
    const fileInput = ref(null);
    const isUploading = ref(false);
    const errorMessage = ref('');
    const previewUrl = ref('');

    const triggerFileInput = () => {
      fileInput.value.click();
    };

    const compressImage = async (file) => {
      const options = {
        maxSizeMB: props.maxCompressedSize / (1024 * 1024), // Convert to MB
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };

      try {
        return await imageCompression(file, options);
      } catch (error) {
        console.error('Error compressing image:', error);
        throw error;
      }
    };

    const handleFileChange = async (event) => {
      const file = event.target.files[0];
      if (!file) return;

      isUploading.value = true;
      errorMessage.value = '';

      try {
        // Create a preview URL
        previewUrl.value = URL.createObjectURL(file);

        // Compress the image
        const compressedFile = await compressImage(file);

        // Check if the compressed file size exceeds the limit
        if (compressedFile.size > props.maxCompressedSize) {
          throw new Error(`Compressed file size exceeds ${props.maxCompressedSize / (1024 * 1024)}MB limit.`);
        }

        // Upload the compressed file to Azure Blob Storage
        const blobServiceClient = new BlobServiceClient(`${process.env.VUE_APP_BLOB_STORAGE_URL}?${process.env.VUE_APP_BLOB_SAS_TOKEN}`);
        const containerClient = blobServiceClient.getContainerClient('');
        const blobName = `${Date.now()}-${file.name}`;
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);

        await blockBlobClient.uploadData(compressedFile);

        // Construct the correct URL without SAS token
        const imagePath = `${blobName}`;
        console.log('Constructed image path:', imagePath);
        emit('upload-success', imagePath);
      } catch (error) {
        console.error('Error uploading image:', error);
        errorMessage.value = error.message || 'Failed to upload image. Please try again.';
        emit('upload-error', errorMessage.value);
      } finally {
        isUploading.value = false;
      }
    };

    return {
      fileInput,
      isUploading,
      errorMessage,
      previewUrl,
      triggerFileInput,
      handleFileChange
    };
  }
};
</script>

<style scoped>
.image-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.error-message {
  color: red;
  font-size: 0.9em;
}

.image-preview {
  max-width: 200px;
  max-height: 200px;
  overflow: hidden;
}

.image-preview img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
</style>