<template>
  <div style="display: none;">
    <!-- This component doesn't render anything visible, it only manages meta tags -->
  </div>
</template>

<script setup>
import { watch, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import metaDescriptions from '@/meta-descriptions'

const route = useRoute()

const updateMetaDescription = (description) => {
  let meta = document.querySelector('meta[name="description"]')
  if (!meta) {
    meta = document.createElement('meta')
    meta.setAttribute('name', 'description')
    document.head.appendChild(meta)
  }
  meta.setAttribute('content', description)
  
  // Update Open Graph and Twitter meta tags
  let ogDescription = document.querySelector('meta[property="og:description"]')
  let twitterDescription = document.querySelector('meta[property="twitter:description"]')
  
  if (!ogDescription) {
    ogDescription = document.createElement('meta')
    ogDescription.setAttribute('property', 'og:description')
    document.head.appendChild(ogDescription)
  }
  
  if (!twitterDescription) {
    twitterDescription = document.createElement('meta')
    twitterDescription.setAttribute('property', 'twitter:description')
    document.head.appendChild(twitterDescription)
  }
  
  ogDescription.setAttribute('content', description)
  twitterDescription.setAttribute('content', description)
}

const updateDescription = () => {
  if (route.name && metaDescriptions[route.name]) {
    updateMetaDescription(metaDescriptions[route.name])
  }
}

onMounted(() => {
  updateDescription()
})

watch(() => route.name, updateDescription)
</script>
