export const themes = {
  light: {
    primary: '#007bff',
    secondary: '#6c757d',
    background: '#ffffff',
    surface: '#f8f9fa',
    text: '#2c3e50',
    textSecondary: '#64748b',
    border: '#e2e8f0',
    hover: '#f1f5f9',
    shadow: 'rgba(0, 0, 0, 0.1)',
    overlay: 'rgba(255, 255, 255, 0.9)',
    
    fontHeading: "'Inter', system-ui, -apple-system, sans-serif",
    fontBody: "'Inter', system-ui, -apple-system, sans-serif",
    fontMono: "'JetBrains Mono', 'Fira Code', monospace",
    
    cardBackground: '#ffffff',
    inputBackground: '#f8fafc',
    buttonText: '#ffffff',
    linkColor: '#3b82f6',
    linkHover: '#2563eb',
    success: '#10b981',
    error: '#ef4444',
    warning: '#f59e0b',
    info: '#3b82f6',
    themeLogoOpacity: '0.2',
    themeSvgFilter: 'none',
    
    scheduleCardBooked: '#c8e6c9',
    scheduleCardDefault: '#ffffff',
    scheduleCardDisabled: '#f5f5f5',
    
    toastBackground: '#ffffff',
    toastText: '#2c3e50',
    toastBorder: '#e2e8f0',
    toastSuccessBackground: '#dcfce7',
    toastErrorBackground: '#fee2e2',
    toastInfoBackground: '#dbeafe',
    toastWarningBackground: '#fef3c7',
  },
  dark: {
    primary: '#60a5fa',
    secondary: '#94a3b8',
    background: '#0f172a',
    surface: '#1e293b',
    text: '#f1f5f9',
    textSecondary: '#cbd5e1',
    border: '#475569',
    hover: '#2d3b53',
    shadow: 'rgba(0, 0, 0, 0.4)',
    overlay: 'rgba(15, 23, 42, 0.95)',
    
    fontHeading: "'Inter', system-ui, -apple-system, sans-serif",
    fontBody: "'Inter', system-ui, -apple-system, sans-serif",
    fontMono: "'JetBrains Mono', 'Fira Code', monospace",
    
    cardBackground: '#1e293b',
    inputBackground: '#1e293b',
    buttonText: '#ffffff',
    linkColor: '#93c5fd',
    linkHover: '#bfdbfe',
    success: '#4ade80',
    error: '#fb7185',
    warning: '#fbbf24',
    info: '#93c5fd',
    themeLogoOpacity: '0.15',
    themeSvgFilter: 'invert(1)',
    
    scheduleCardBooked: '#166534',
    scheduleCardDefault: '#1e293b',
    scheduleCardDisabled: '#0f172a',
    
    toastBackground: '#1e293b',
    toastText: '#f1f5f9',
    toastBorder: '#475569',
    toastSuccessBackground: '#065f46',
    toastErrorBackground: '#991b1b',
    toastInfoBackground: '#1e40af',
    toastWarningBackground: '#92400e',
  }
}

// Add theme type definitions
export const themeTypes = {
  LIGHT: 'light',
  DARK: 'dark',
  SYSTEM: 'system'
}

// Helper to detect system theme
export const getSystemTheme = () => {
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    return themeTypes.DARK
  }
  return themeTypes.LIGHT
}

export const getThemeVariables = (theme) => {
  // If theme is system, determine the actual theme based on system preference
  const actualTheme = theme === themeTypes.SYSTEM ? getSystemTheme() : theme
  const themeValues = themes[actualTheme]
  
  return {
    '--primary-color': themeValues.primary,
    '--secondary-color': themeValues.secondary,
    '--background-color': themeValues.background,
    '--surface-color': themeValues.surface,
    '--text-color': themeValues.text,
    '--text-secondary-color': themeValues.textSecondary,
    '--border-color': themeValues.border,
    '--hover-color': themeValues.hover,
    '--shadow-color': themeValues.shadow,
    '--overlay-color': themeValues.overlay,
    
    '--font-heading': themeValues.fontHeading,
    '--font-body': themeValues.fontBody,
    '--font-mono': themeValues.fontMono,
    
    '--card-background': themeValues.cardBackground,
    '--input-background': themeValues.inputBackground,
    '--button-text': themeValues.buttonText,
    '--link-color': themeValues.linkColor,
    '--link-hover-color': themeValues.linkHover,
    '--success-color': themeValues.success,
    '--error-color': themeValues.error,
    '--warning-color': themeValues.warning,
    '--info-color': themeValues.info,
    
    '--shadow-sm': `0 1px 2px ${themeValues.shadow}`,
    '--shadow-md': `0 4px 6px ${themeValues.shadow}`,
    '--shadow-lg': `0 10px 15px ${themeValues.shadow}`,
    
    '--transition-speed': '0.3s',
    '--theme-logo-opacity': themeValues.themeLogoOpacity,
    '--theme-svg-filter': themeValues.themeSvgFilter,
    
    '--schedule-card-booked': themeValues.scheduleCardBooked,
    '--schedule-card-default': themeValues.scheduleCardDefault,
    '--schedule-card-disabled': themeValues.scheduleCardDisabled,
    
    '--toast-background': themeValues.toastBackground,
    '--toast-text': themeValues.toastText,
    '--toast-border': themeValues.toastBorder,
    '--toast-success-background': themeValues.toastSuccessBackground,
    '--toast-error-background': themeValues.toastErrorBackground,
    '--toast-info-background': themeValues.toastInfoBackground,
    '--toast-warning-background': themeValues.toastWarningBackground,
  }
} 