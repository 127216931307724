<template>
  <div class="login-page">
    <h1>Logga in</h1>
    <form @submit.prevent="handleLogin">
      <div class="form-group">
        <label for="email">E-post:</label>
        <input
          id="email"
          v-model="email"
          type="email"
          required
        >
      </div>
      <div class="form-group">
        <label for="password">Lösenord:</label>
        <input
          id="password"
          v-model="password"
          type="password"
          required
        >
      </div>
      <v-btn
        type="submit"
        color="primary"
        elevation="8"
        block
        class="login-button"
      >
        Logga in
      </v-btn>
    </form>
    <v-btn
      text
      color="primary"
      class="forgot-password-button"
      @click="handleForgotPassword"
    >
      Glömt lösenord?
    </v-btn>
    <v-btn
      text
      color="primary"
      class="register-button"
      @click="goToRegister"
    >
      Registrera
    </v-btn>
    <div class="social-login">
      <v-btn
        color="white"
        elevation="8"
        block
        class="google-button"
        @click="signInWithProvider('google')"
      >
        <img
          src="@/assets/google-logo.webp"
          alt="Google logo"
          class="provider-logo"
        >
        <span>Logga in med Google</span>
      </v-btn>
      <v-btn
        color="#1877f2"
        elevation="8"
        block
        class="facebook-button"
        @click="signInWithProvider('facebook')"
      >
        <img
          src="@/assets/facebook-logo.webp"
          alt="Facebook logo"
          class="provider-logo"
        >
        <span>Logga in med Facebook</span>
      </v-btn>
      <v-btn
        color="#2f2f2f"
        elevation="8"
        block
        class="microsoft-button"
        @click="signInWithProvider('microsoft')"
      >
        <img
          src="@/assets/microsoft-logo.webp"
          alt="Microsoft logo"
          class="provider-logo"
        >
        <span>Logga in med Microsoft</span>
      </v-btn>
    </div>
    <p
      v-if="error"
      class="error-message"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useFirebaseAuth } from 'vuefire'
import { signInWithEmailAndPassword, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider, signInWithPopup, sendPasswordResetEmail } from 'firebase/auth'
import { api } from '@/services/api'
import { useMainStore } from '@/stores'
import { onMounted } from 'vue'

export default {
  name: 'LoginPage',
  setup() {
    const email = ref('')
    const password = ref('')
    const error = ref(null)
    const router = useRouter()
    const auth = useFirebaseAuth()
    const store = useMainStore()

    const registerUser = async (user) => {
      try {
        const newUserData = {
          email: user.email,
          username: user.email,
          firstName: user.displayName || '',
          role: 'registered'
          // Lägg till eventuella andra fält du vill spara
        }

        const createdUser = await api.createUser(newUserData)
        console.log('Användare skapad i backend:', createdUser)

        // Returnera skapade användardata
        return createdUser.data
      } catch (err) {
        console.error('Fel vid registrering av användare i backend:', err)
        error.value = 'Misslyckades att registrera användare. Vänligen försök igen.'
        // Eventuellt ta bort Firebase-användaren om backend-registrering misslyckas
        await user.delete()
        throw err
      }
    }

    const fetchUserData = async (user) => {
      try {
        const userData = await api.getUserByEmail(user.email)
        console.log('Användardata från API:', userData);
        
        if (userData && userData.data && !userData.data.error) {
          return userData.data
        } else {
          // Om användaren inte hittas, returnera null
          return null
        }
      } catch (err) {
        console.error('Fel vid hämtning av användardata:', err)
        error.value = 'Misslyckades att hämta användardata. Vänligen kontakta support.'
        throw err
      }
    }

    const handleUserAuthentication = async (user) => {
      try {
        console.log('Autentiserad användare:', user);
        
        let userEmail = user.email;
        if (!userEmail && user.providerData && user.providerData.length > 0) {
          userEmail = user.providerData[0].email;
        }

        if (!userEmail) {
          console.error('Användarens e-post saknas i autentiseringsresultatet');
          error.value = 'Kunde inte hämta din e-postadress. Vänligen försök igen eller använd en annan inloggningsmetod.';
          await auth.signOut();
          return;
        }

        console.log('Användarens e-post:', userEmail);

        let userData = await fetchUserData({ ...user, email: userEmail });
        
        if (!userData) {
          // Skapa lokal användare i backend om den inte finns
          userData = await registerUser({ ...user, email: userEmail });
        }

        if (userData) {
          // Uppdatera användardata i butiken
          await store.updateUser({ ...userData })
          
          // Omdirigera till startsidan eller annan sida
          router.push('/')
        } else {
          console.error('Misslyckades att hämta eller skapa användardata');
          error.value = 'Misslyckades att hämta användardata. Vänligen kontakta support.';
        }
      } catch (err) {
        console.error('Fel vid hantering av autentisering:', err)
        error.value = 'Misslyckades att autentisera. Vänligen försök igen.'
      }
    }

    // Lägg till denna funktion för att hämta användardata vid sidinladdning
    const retrieveUserData = async () => {
      const storedUserData = localStorage.getItem('userData');
      if (storedUserData) {
        const userData = JSON.parse(storedUserData);
        await store.updateUser(userData);
        console.log('Hämtade användardata från localStorage:', userData);
      }
    }

    onMounted(() => {
      retrieveUserData();
    });

    const handleLogin = async () => {
      try {
        error.value = null
        const userCredential = await signInWithEmailAndPassword(auth, email.value, password.value)
        await handleUserAuthentication(userCredential.user)
      } catch (err) {
        console.error('Login error:', err)
        error.value = 'Failed to log in. Please check your credentials and try again.'
      }
    }

    const signInWithProvider = async (providerName) => {
      let provider;
      switch (providerName) {
        case 'google':
          provider = new GoogleAuthProvider();
          provider.addScope('email');  // Request email scope
          break;
        case 'facebook':
          provider = new FacebookAuthProvider();
          provider.addScope('email');  // Request email scope
          break;
        case 'microsoft':
          provider = new OAuthProvider('microsoft.com');
          provider.addScope('email');  // Request email scope
          break;
        default:
          console.error('Unsupported provider');
          return;
      }

      try {
        const result = await signInWithPopup(auth, provider)
        await handleUserAuthentication(result.user)
      } catch (error) {
        console.error('Sign-in failed:', error)
        if (error.code === 'auth/account-exists-with-different-credential') {
          error.value = 'An account already exists with the same email address but different sign-in credentials. Please try signing in using a different method.';
        } else {
          error.value = 'Failed to sign in. Please try again.'
        }
      }
    }

    const handleForgotPassword = async () => {
      if (!email.value) {
        error.value = 'Vänligen ange din e-postadress först.'
        return
      }

      try {
        await sendPasswordResetEmail(auth, email.value)
        error.value = null
        alert('Ett e-postmeddelande för återställning av lösenord har skickats. Kontrollera din inkorg.')
      } catch (err) {
        console.error('Error sending password reset email:', err)
        error.value = 'Det gick inte att skicka e-postmeddelandet för återställning av lösenord. Kontrollera din e-postadress och försök igen.'
      }
    }

    // Add this new method
    const goToRegister = () => {
      router.push('/register')
    }

    return {
      email,
      password,
      error,
      handleLogin,
      signInWithProvider,
      handleForgotPassword,
      goToRegister // Add this new method to the returned object
    }
  }
}
</script>

<style scoped>
.login-page {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.login-button {
  margin-top: 10px;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.social-login {
  margin-top: 20px;
}

.social-login .v-btn {
  margin-bottom: 10px;
  text-transform: none;
}

.provider-logo {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.google-button {
  color: #757575;
}

.facebook-button,
.microsoft-button {
  color: white;
}

.forgot-password-button,
.register-button {
  margin-top: 10px;
  width: 100%;
}
</style>