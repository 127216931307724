<template>
  <div class="admin-statistics-card">
    <h2>Statistik</h2>
    <div v-if="isLoading">
      Loading...
    </div>
    <div v-else>
      <!-- Användarroller chart moved to the top -->
      <div class="statistic-item chart-container">
        <ApexChart
          type="donut"
          height="350"
          width="350"
          :options="userRolesChartOptions"
          :series="userRolesChartSeries"
        />
      </div>
      <!-- Existing chart elements -->
      <div class="statistics-grid">
        <div class="statistic-item chart-container">
          <ApexChart
            type="radialBar"
            height="350"
            :options="activeUsersChartOptions"
            :series="activeUsersChartSeries"
          />
        </div>
        <div class="statistic-item chart-container">
          <ApexChart
            type="area"
            height="350"
            :options="usersOverTimeChartOptions"
            :series="usersOverTimeChartSeries"
          />
        </div>
        <div class="statistic-item chart-container">
          <ApexChart
            type="heatmap"
            height="350"
            :options="passAttendanceHeatmapOptions"
            :series="passAttendanceHeatmapData"
          />
        </div>
      </div>
      <!-- New statistics summary section -->
      <div class="statistics-summary">
        <!-- Removed "Totalt antal användare", "Antal användare", and "Antal Provanvändare" -->
        <div class="statistic-item">
          <table>
            <thead>
              <tr>
                <th><h3>Övningar</h3></th>
                <th><h3>Platser</h3></th>
                <th><h3>Utrustningar</h3></th>
                <th><h3>Pass</h3></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><p>{{ exerciseCount }}</p></td>
                <td><p>{{ placeCount }}</p></td>
                <td><p>{{ equipmentCount }}</p></td>
                <td><p>{{ sessionCount }}</p></td>              
              </tr>
            </tbody>
          </table>
        </div>
        <div class="statistic-item">
          <h3>Antal favoriter</h3>
          <p>Övningar: {{ favoritesCount.exercises }}, Utrustning: {{ favoritesCount.equipment }}, Pass: {{ favoritesCount.sessions }}</p>
        </div>
        <div class="statistic-item">
          <h3>Antal betyg</h3>
          <p>Övningar: {{ ratingsCount.exercises }}, Utrustning: {{ ratingsCount.equipment }}, Pass: {{ ratingsCount.sessions }}</p>
        </div>
        <div class="statistic-item">
          <h3>Kompletta övningar</h3>
          <p>{{ exercisesWithMuscleGroupsCount }} / {{ exerciseCount }}</p>
        </div>     
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useMainStore } from '@/stores'

export default {
  name: 'AdminStatisticsCard',
  setup() {
    const store = useMainStore()
    const isLoading = ref(true)
    const userRolesChart = ref(null)
    const userRolesDonutChart = ref(null)

    // Helper function to safely access nested properties
    const safeGet = (obj, path) => {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj) ?? 0
    }

    // Wrap all computed properties in try-catch blocks
    const wrapComputed = (fn) => {
      return computed(() => {
        try {
          return fn()
        } catch (error) {
          console.error(`Error in computed property: ${error.message}`, error)
          return null
        }
      })
    }

    // Computed properties for new statistics
    const totalUsers = wrapComputed(() => store.users?.length ?? 0)
    const memberCount = wrapComputed(() => store.users?.filter(user => user?.role === 'member').length ?? 0)
    const registeredCount = wrapComputed(() => store.users?.filter(user => user?.role === 'registered').length ?? 0)
    const exerciseCount = wrapComputed(() => store.exercises?.length ?? 0)
    const placeCount = wrapComputed(() => store.places?.length ?? 0)
    const equipmentCount = wrapComputed(() => store.equipment?.length ?? 0)
    const sessionCount = wrapComputed(() => store.sessions?.length ?? 0)
    const passCount = wrapComputed(() => store.passes?.length ?? 0)

    const favoritesCount = wrapComputed(() => ({
      exercises: store.users?.reduce((acc, user) => acc + safeGet(user, 'favorites.exercises.length'), 0) ?? 0,
      equipment: store.users?.reduce((acc, user) => acc + safeGet(user, 'favorites.equipment.length'), 0) ?? 0,
      places: store.users?.reduce((acc, user) => acc + safeGet(user, 'favorites.places.length'), 0) ?? 0,
      sessions: store.users?.reduce((acc, user) => acc + safeGet(user, 'favorites.sessions.length'), 0) ?? 0
    }))

    const ratingsCount = wrapComputed(() => ({
      exercises: store.exercises?.reduce((acc, exercise) => acc + safeGet(exercise, 'ratings.length'), 0) ?? 0,
      equipment: store.equipment?.reduce((acc, item) => acc + safeGet(item, 'ratings.length'), 0) ?? 0,
      places: store.places?.reduce((acc, place) => acc + safeGet(place, 'ratings.length'), 0) ?? 0,
      sessions: store.sessions?.reduce((acc, session) => acc + safeGet(session, 'ratings.length'), 0) ?? 0
    }))

    const exercisesWithMuscleGroupsCount = wrapComputed(() => 
      store.exercises?.filter(exercise => exercise?.muscleGroups?.length > 0).length ?? 0
    )

    const lastMonthParticipants = wrapComputed(() => {
      if (!store.passes) return 0
      const oneMonthAgo = new Date()
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)
      return store.passes.reduce((total, pass) => {
        if (new Date(pass?.date) >= oneMonthAgo) {
          return total + (pass?.users?.length ?? 0)
        }
        return total
      }, 0)
    })

    const activeMemberCount = wrapComputed(() => {
      if (!store.users) return 0
      const currentDate = new Date()
      return store.users.filter(user => 
        user?.memberships?.some(membership => 
          membership?.type === 'membership' &&
          membership?.status === 'confirmed' &&
          new Date(membership?.startDate) <= currentDate &&
          new Date(membership?.endDate) > currentDate
        )
      ).length
    })

    const activeTrainingCardCount = wrapComputed(() => {
      if (!store.users) return 0
      const currentDate = new Date()
      return store.users.filter(user => 
        user?.memberships?.some(membership => 
          membership?.type === 'trainingcard' &&
          membership?.status === 'confirmed' &&
          new Date(membership?.startDate) <= currentDate &&
          new Date(membership?.endDate) > currentDate
        )
      ).length
    })

    const userRoles = wrapComputed(() => {
      const roles = store.users.reduce((acc, user) => {
        acc[user.role] = (acc[user.role] || 0) + 1
        return acc
      }, {})
      return roles
    })

    // Add these new computed properties
    const participatedLastMonth = wrapComputed(() => {
      const thirtyDaysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
      return store.users?.filter(user => 
        store.passes?.some(pass => 
          new Date(pass.date) >= thirtyDaysAgo && 
          pass.users?.includes(user.id)
        )
      ).length ?? 0
    })

    const loggedInLastMonth = wrapComputed(() => {
      const thirtyDaysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
      return store.users?.filter(user => 
        new Date(user.lastLogin) >= thirtyDaysAgo
      ).length ?? 0
    })

    // Update the activeUsersChartOptions
    const activeUsersChartOptions = wrapComputed(() => ({
      chart: {
        height: 350,
        type: 'radialBar',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        }
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: -165,
          endAngle: 165,
          hollow: {
            margin: 5,
            size: '20%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            name: {
              show: true,
            },
            value: {
              show: true,
              fontSize: '16px',
              offsetY: 10,
            }
          },
          track: {
            background: '#e7e7e7',
            strokeWidth: '37%',
            margin: 5,
          },
        }
      },
      colors: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
      labels: ['Medlemskort', 'Träningskort', 'Pass denna månad', 'Inloggad denna månad'],
      legend: {
        show: true,
        floating: false,
        fontSize: '14px',
        position: 'top',
        offsetX: 0,
        offsetY: 0,
        labels: {
          useSeriesColors: true,
        },
        formatter: function(seriesName, opts) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
        },
        itemMargin: {
          vertical: 4
        }
      },
      title: {
        text: 'Aktiva användare',
        align: 'center',
        style: {
          fontSize: '18px',
          fontWeight: 'bold',
        },
      },
    }))

    // Update the activeUsersChartSeries to use actual numbers instead of percentages
    const activeUsersChartSeries = wrapComputed(() => [
      activeMemberCount.value,
      activeTrainingCardCount.value,
      participatedLastMonth.value,
      loggedInLastMonth.value
    ])

    // Modify the userRolesChartOptions to remove the inner ring
    const userRolesChartOptions = wrapComputed(() => {
      const roleLabels = {
        'staff': 'Personal',
        'member': 'Medlemmar',
        'registered': 'Nya'
      };

      return {
        chart: {
          type: 'donut',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          }
        },
        labels: Object.keys(userRoles.value).map(role => roleLabels[role] || role),
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            return opts.w.config.series[opts.seriesIndex]
          }
        },
        tooltip: {
          y: {
            formatter: function(value) {
              return value + " användare"
            }
          }
        },
        plotOptions: {
          pie: {
            donut: {
              expandOnClick: true,
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '14px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 400,
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: 'Totalt',
                  fontSize: '16px',
                  fontWeight: 600,
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                  }
                }
              }
            }
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              show: true,
              floating: true,
              fontSize: '14px',
              position: 'bottom',
              offsetX: 0,
              offsetY: 0,
              labels: {
                useSeriesColors: true,
              }
            }
          }}    ],
        colors: ['#FF6384', '#36A2EB', '#FFCE56'],
        title: {
          text: 'Användarroller',
          align: 'center',
          style: {
            fontSize: '18px',
            fontWeight: 'bold',
          },
        },
      }
    })

    // Modify the userRolesChartSeries to only include user roles
    const userRolesChartSeries = wrapComputed(() => Object.values(userRoles.value))

    const usersOverTime = wrapComputed(() => {
      const monthsToShow = 12 // Show data for the last 12 months

      // Get the current date and set it to the first day of the month
      const currentDate = new Date()
      currentDate.setDate(1)

      const dates = []
      const userData = [{ name: 'Totalt antal användare', data: [] }]

      for (let i = 0; i < monthsToShow; i++) {
        const date = new Date(currentDate)
        date.setMonth(date.getMonth() - i)
        const dateString = date.toISOString().slice(0, 7) // Format as YYYY-MM
        dates.unshift(dateString)

        const usersAtThisDate = store.users.filter(user => {
          const userCreationDate = new Date(user.userCreated)
          return userCreationDate <= date
        })

        userData[0].data.unshift(usersAtThisDate.length)
      }

      return {
        dates,
        series: userData
      }
    })

    const usersOverTimeChartOptions = wrapComputed(() => ({
      chart: {
        type: 'area',
        height: 350,
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        }
      },
      xaxis: {
        categories: usersOverTime.value.dates,
        title: {
          text: 'Månad'
        }
      },
      yaxis: {
        title: {
          text: 'Antal användare'
        }
      },
      legend: {
        position: 'top'
      },
      title: {
        text: 'Användare',
        align: 'center'
      },
      stroke: {
        curve: 'smooth'
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100]
        }
      }
    }))

    const usersOverTimeChartSeries = wrapComputed(() => usersOverTime.value.series)

    // Updated computed properties for statistics
    const averageSessionsPerUser = wrapComputed(() => {
      const totalSessions = store.passes.length
      const totalUsers = store.users.length
      return totalUsers > 0 ? (totalSessions / totalUsers).toFixed(2) : 'N/A'
    })

    const averageSessionsPerUserLastMonth = wrapComputed(() => {
      const oneMonthAgo = new Date()
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)
      
      const lastMonthSessions = store.passes.filter(pass => new Date(pass.date) >= oneMonthAgo).length
      const activeUsers = store.users.filter(user => {
        // Assuming each user has a 'lastActive' property. Adjust this condition as needed.
        return new Date(user.lastActive) >= oneMonthAgo
      }).length

      return activeUsers > 0 ? (lastMonthSessions / activeUsers).toFixed(2) : 'N/A'
    })

    const newUsersLastMonth = wrapComputed(() => {
      const oneMonthAgo = new Date()
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)
      return store.users.filter(user => new Date(user.createdAt) >= oneMonthAgo).length
    })

    const passAttendanceHeatmapData = wrapComputed(() => {
      if (!store.passes || !Array.isArray(store.passes)) return []
      const today = new Date()
      const twelveMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 11, 1)
      const data = Array(12).fill().map(() => Array(12).fill(0))
      
      const monthNames = []
      for (let i = 0; i < 12; i++) {
        const d = new Date(twelveMonthsAgo.getFullYear(), twelveMonthsAgo.getMonth() + i, 1)
        monthNames.push(d.toLocaleString('default', { month: 'short' }))
      }
      
      store.passes.forEach(pass => {
        if (pass && pass.date) {
          const passDate = new Date(pass.date)
          if (passDate >= twelveMonthsAgo && passDate <= today) {
            const monthIndex = (passDate.getMonth() - twelveMonthsAgo.getMonth() + 12) % 12
            const passIndex = Math.floor((passDate.getDate() - 1) / 2.5)
            if (passIndex >= 0 && passIndex < 12 && monthIndex >= 0 && monthIndex < 12) {
              data[passIndex][monthIndex] += (pass.users && Array.isArray(pass.users)) ? pass.users.length : 0
            }
          }
        }
      })
      
      return Array(12).fill().map((_, index) => ({
        name: `Pass ${index + 1}`,
        data: data[index]
      }))
    })

    const passAttendanceHeatmapOptions = wrapComputed(() => {
      const today = new Date()
      const twelveMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 11, 1)
      const monthNames = []
      for (let i = 0; i < 12; i++) {
        const d = new Date(twelveMonthsAgo.getFullYear(), twelveMonthsAgo.getMonth() + i, 1)
        monthNames.push(d.toLocaleString('default', { month: 'short' }))
      }

      return {
        chart: {
          type: 'heatmap',
          height: 350,
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
        }
        },
        dataLabels: {
          enabled: false
        },
        colors: ["#008FFB"],
        title: {
          text: 'Deltagare per pass',
          align: 'center'
        },
        xaxis: {
          type: 'category',
          categories: monthNames
        },
        yaxis: {
          type: 'category',
          categories: Array(12).fill().map((_, i) => `Pass ${i + 1}`)
        },
        tooltip: {
          custom: function({ series, seriesIndex, dataPointIndex, w }) {
            const value = series[seriesIndex][dataPointIndex];
            const month = w.globals.labels[dataPointIndex];
            const pass = w.globals.yLabelFormatters[0](seriesIndex);
            return `<div class="custom-tooltip">
                      <span><b>${month} - ${pass}</b></span><br>
                      <span>${value} deltagare</span>
                    </div>`;
          }
        }
      }
    })

    onMounted(async () => {
      try {
        await Promise.all([
          store.fetchUsers(),
          store.fetchPasses(),
          store.fetchSessions(),
          store.fetchExercises(),
          store.fetchPlaces(),
          store.fetchEquipment()
        ])
        console.log('Data fetched successfully')
        
        // Ensure store.passes is initialized
        if (!store.passes) {
          store.passes = []
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        isLoading.value = false
      }
    })

    return {
      isLoading,
      userRolesChart,
      userRolesDonutChart,
      totalUsers,
      memberCount,
      registeredCount,
      exerciseCount,
      placeCount,
      equipmentCount,
      sessionCount,
      passCount,
      favoritesCount,
      ratingsCount,
      lastMonthParticipants,
      activeMemberCount,
      activeTrainingCardCount,
      exercisesWithMuscleGroupsCount,
      userRolesChartOptions,
      userRolesChartSeries,
      usersOverTimeChartOptions,
      usersOverTimeChartSeries,
      averageSessionsPerUser,
      averageSessionsPerUserLastMonth,
      newUsersLastMonth,
      passAttendanceHeatmapData,
      passAttendanceHeatmapOptions,
      activeUsersChartOptions,
      activeUsersChartSeries,
      participatedLastMonth,
      loggedInLastMonth
    }
  }
}
</script>

<style scoped>
.admin-statistics-card {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.statistics-summary {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin-bottom: 30px;
}

.statistics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.statistic-item {
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* New class for chart containers */
.chart-container {
  min-height: 400px;
}

.statistics-summary .statistic-item {
  min-height: auto; /* Override min-height for summary items */
}

h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1rem;
}

p {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
}

.custom-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 3px;
}
</style>