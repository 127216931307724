<!-- src/views/EditPlacesPage.vue -->
<template>
  <div class="edit-page">
    <h1>Hantera platser</h1>
    
    <!-- Add new place button -->
    <button
      class="btn btn-primary"
      @click="startAddingNewPlace"
    >
      Lägg till ny plats
    </button>

    <!-- Layer switch -->
    <div class="layer-switch">
      <label>
        <input
          v-model="useSatelliteLayer"
          type="checkbox"
        >
        Använd satellitlager
      </label>
    </div>

    <!-- Dropdown list of all places -->
    <div
      v-if="!isAddingNewPlace && !currentPlace"
      class="place-dropdown"
    >
      <select
        v-model="selectedPlaceId"
        @change="selectPlaceFromDropdown"
      >
        <option value="">
          Välj en plats att redigera
        </option>
        <option
          v-for="place in allPlaces"
          :key="place.id"
          :value="place.id"
        >
          {{ place.name || 'Unnamed Place' }}
        </option>
      </select>
    </div>

    <!-- Search bar (only show when not adding a new place) -->
    <div
      v-if="!isAddingNewPlace && !currentPlace"
      class="search-bar"
    >
      <input
        v-model="searchTerm"
        placeholder="Sök efter en plats..."
        @input="searchPlaces"
      >
    </div>

    <!-- Edit form -->
    <div
      v-if="currentPlace"
      class="edit-form"
    >
      <h2>{{ isAddingNewPlace ? 'Lägg till ny plats' : 'Redigerar: ' + currentPlace.name }}</h2>
      
      <!-- Form fields -->
      <form @submit.prevent="savePlace">
        <input
          v-model="currentPlace.name"
          placeholder="Namn"
          required
        >
        <textarea
          v-model="currentPlace.description"
          placeholder="Beskrivning"
        />
        
        <!-- Map component -->
        <div class="map-container">
          <MapComponent
            v-if="currentPlace && currentPlace.location"
            :key="mapKey"
            :latitude="currentPlace?.location?.coordinates[1] || 0"
            :longitude="currentPlace?.location?.coordinates[0] || 0"
            :layer="useSatelliteLayer ? 'satellite' : 'osm'"
            @update:coordinates="updateCoordinates"
          />
        </div>

        <input
          v-model="currentPlace.location.coordinates[1]"
          type="number"
          step="any"
          placeholder="Latitud"
          required
        >
        <input
          v-model="currentPlace.location.coordinates[0]"
          type="number"
          step="any"
          placeholder="Longitud"
          required
        >
        <input
          v-model="currentPlace.location.coordinates[2]"
          type="number"
          step="any"
          placeholder="Höjd över havet (m)"
          required
        >
        
        <!-- Image uploader -->
        <div>
          <h3>Bilder</h3>
          <ImageUploader
            :accepted-file-types="'image/*'"
            :button-text="'Ladda upp bild'"
            :button-color="'primary'"
            :max-file-size="5 * 1024 * 1024"
            @upload-success="handleImageUpload"
            @upload-error="handleUploadError"
          />
        </div>
        
        <!-- Display uploaded images -->
        <div
          v-if="currentPlace.imageUrl && currentPlace.imageUrl.length > 0"
          class="image-preview"
        >
          <div
            v-for="(image, index) in currentPlace.imageUrl"
            :key="index"
            class="image-item"
          >
            <img
              :src="getFullImageUrl(image)"
              alt="Preview"
            >
            <button
              class="remove-image"
              @click.prevent="removeImage(index)"
            >
              Ta bort
            </button>
          </div>
        </div>
        
        <div class="button-group">
          <button
            type="button"
            class="btn btn-secondary"
            @click="getCurrentPosition"
          >
            Använd nuvarande position
          </button>
          <button
            type="submit"
            class="btn btn-primary"
          >
            {{ isAddingNewPlace ? 'Lägg till' : 'Spara' }}
          </button>
          <button
            v-if="isAddingNewPlace"
            type="button"
            class="btn btn-secondary"
            @click="cancelEditing"
          >
            Avbryt
          </button>
          <button
            v-if="!isAddingNewPlace"
            type="button"
            class="btn btn-secondary"
            @click="copyPlace"
          >
            Kopiera
          </button>
          <button
            v-if="!isAddingNewPlace"
            type="button"
            class="btn btn-danger"
            @click="deletePlace"
          >
            Ta bort
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, shallowRef, onMounted, watch, markRaw } from 'vue'
import api from '@/services/api'
import { getImageUrl } from '@/utils/imageUtils'
import ImageUploader from '@/components/ImageUploader.vue'
import MapComponent from '@/components/MapComponent.vue'

export default {
  name: 'EditPlacesPage',
  components: {
    ImageUploader,
    MapComponent: markRaw(MapComponent)
  },
  setup() {
    const allPlaces = ref([])
    const currentPlace = shallowRef(null)
    const isAddingNewPlace = ref(false)
    const searchTerm = ref('')
    const searchResults = ref([])
    const selectedPlaceId = ref('')
    const useSatelliteLayer = ref(true) // Default to satellite layer
    const mapKey = ref(0)

    const fetchPlaces = async () => {
      try {
        const response = await api.getPlaces()
        allPlaces.value = response.data
      } catch (error) {
        console.error('Error fetching places:', error)
      }
    }

    onMounted(fetchPlaces)

    const startAddingNewPlace = () => {
      currentPlace.value = { 
        name: '', 
        description: '', 
        location: { 
          type: 'Point', 
          coordinates: [0, 0, 0] // Default to [0, 0, 0] instead of [null, null, null]
        }, 
        imageUrl: [] 
      }
      isAddingNewPlace.value = true
    }

    const selectPlaceFromDropdown = () => {
      if (selectedPlaceId.value) {
        const selected = allPlaces.value.find(place => place.id === selectedPlaceId.value)
        if (selected) {
          currentPlace.value = { ...selected }
          isAddingNewPlace.value = false
        }
      }
    }

    const handleImageUpload = (imagePath) => {
      if (!currentPlace.value.imageUrl) {
        currentPlace.value.imageUrl = []
      }
      currentPlace.value.imageUrl.push(imagePath)
    }

    const handleUploadError = (errorMessage) => {
      console.error('Upload error:', errorMessage)
      // You can add code here to display the error message to the user
    }

    const removeImage = (index) => {
      currentPlace.value.imageUrl.splice(index, 1)
    }

    const savePlace = async () => {
      try {
        if (isAddingNewPlace.value) {
          await api.createPlace(currentPlace.value)
        } else {
          await api.updatePlace(currentPlace.value.id, currentPlace.value)
        }
        // Refresh the list of places
        fetchPlaces()
        // Reset the form
        currentPlace.value = null
        isAddingNewPlace.value = false
      } catch (error) {
        console.error('Error saving place:', error)
        // Handle error (e.g., show error message to user)
      }
    }

    const copyPlace = () => {
      const placeCopy = { ...currentPlace.value }
      delete placeCopy.id
      placeCopy.name += ' (kopia)'
      currentPlace.value = placeCopy
      isAddingNewPlace.value = true
    }

    const deletePlace = async () => {
      if (confirm('Är du säker på att du vill ta bort denna plats?')) {
        try {
          await api.deletePlace(currentPlace.value.id)
          fetchPlaces()
          currentPlace.value = null
        } catch (error) {
          console.error('Error deleting place:', error)
          // Handle error (e.g., show error message to user)
        }
      }
    }

    const cancelEditing = () => {
      currentPlace.value = null
      isAddingNewPlace.value = false
    }

    const searchPlaces = () => {
      if (searchTerm.value) {
        searchResults.value = allPlaces.value.filter(place =>
          place.name.toLowerCase().includes(searchTerm.value.toLowerCase())
        )
      } else {
        searchResults.value = []
      }
    }

    const getCurrentPosition = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            currentPlace.value.location.coordinates = [
              position.coords.longitude,
              position.coords.latitude,
              position.coords.altitude || 0 // Use 0 as default if altitude is not available
            ]
            // Trigger the updateCoordinates function to fetch elevation
            updateCoordinates({ lat: position.coords.latitude, lng: position.coords.longitude })
          },
          (error) => {
            console.error('Error getting current position:', error)
            // Set default coordinates if there's an error
            currentPlace.value.location.coordinates = [0, 0, 0]
          },
          { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
        )
      } else {
        console.error('Geolocation is not supported by this browser.')
        // Set default coordinates if geolocation is not supported
        currentPlace.value.location.coordinates = [0, 0, 0]
      }
    }

    const updateCoordinates = async (newCoordinates) => {
      console.log('updateCoordinates called:', newCoordinates);
      if (currentPlace.value && currentPlace.value.location) {
        console.log('Updating coordinates:', newCoordinates);
        currentPlace.value = {
          ...currentPlace.value,
          location: {
            ...currentPlace.value.location,
            coordinates: [
              newCoordinates.lng,
              newCoordinates.lat,
              currentPlace.value.location.coordinates[2]
            ]
          }
        }

        try {
          const locations = `${newCoordinates.lat},${newCoordinates.lng}`
          const response = await api.getElevation(locations)
          console.log('Elevation API response:', response)

          if (response.data && typeof response.data === 'number') {
            currentPlace.value = {
              ...currentPlace.value,
              location: {
                ...currentPlace.value.location,
                coordinates: [
                  currentPlace.value.location.coordinates[0],
                  currentPlace.value.location.coordinates[1],
                  response.data
                ]
              }
            }
          } else {
            console.error('Unexpected elevation data format:', response.data)
          }
        } catch (error) {
          console.error('Error fetching elevation:', error)
        }
      }
    }

    const resetMapKey = () => {
      mapKey.value += 1;
    }

    watch(() => currentPlace.value, (newPlace, oldPlace) => {
      if (newPlace?.id !== oldPlace?.id) {
        resetMapKey();
      }
    });

    // Modified watch function
    watch(() => currentPlace.value?.location?.coordinates, (newCoords, oldCoords) => {
      if (newCoords && oldCoords && 
          (newCoords[0] !== oldCoords[0] || newCoords[1] !== oldCoords[1])) {
        updateCoordinates({ lat: newCoords[1], lng: newCoords[0] })
      }
    }, { deep: true })

    return {
      allPlaces,
      currentPlace,
      isAddingNewPlace,
      searchTerm,
      searchResults,
      selectedPlaceId,
      startAddingNewPlace,
      selectPlaceFromDropdown,
      savePlace,
      copyPlace,
      deletePlace,
      cancelEditing,
      searchPlaces,
      getCurrentPosition,
      handleImageUpload,
      handleUploadError,
      removeImage,
      getFullImageUrl: getImageUrl,
      updateCoordinates,
      useSatelliteLayer,
      mapKey,
    }
  }
}
</script>

<style scoped>
.edit-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1, h2 {
  color: #333;
  margin-bottom: 20px;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.search-results {
  margin-top: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
}

.search-result-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #e9ecef;
}

.search-result-item:hover {
  background-color: #e9ecef;
}

.edit-form {
  margin-top: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

form label {
  display: block;
  margin-bottom: 10px;
}

form input, form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.button-group {
  margin-top: 20px;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
  margin-right: 10px;
  margin-bottom: 10px;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
}

.btn-secondary:hover {
  background-color: #545b62;
}

.btn-danger {
  background-color: #dc3545;
  color: white;
}

.btn-danger:hover {
  background-color: #c82333;
}

.place-dropdown {
  margin-bottom: 20px;
}

.place-dropdown select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: white;
}

.image-input {
  margin-bottom: 15px;
}

.image-input input[type="file"] {
  display: none;
}

.image-input button {
  margin-left: 10px;
}

.image-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}

.image-item {
  position: relative;
  width: 150px;
}

.image-item img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.remove-image {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}

.editor {
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 10px;
  min-height: 150px;
  background-color: white;
}

.map-container {
  height: 400px;
  margin-bottom: 20px;
}

.layer-switch {
  margin-bottom: 20px;
}

.layer-switch label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.layer-switch input[type="checkbox"] {
  margin-right: 10px;
}
</style>