import { initializeMessaging } from '@/firebase';
import { getToken, onMessage } from 'firebase/messaging';

let messaging = null;

export const fcmService = {
  async init() {
    messaging = await initializeMessaging();
    if (!messaging) {
      console.log('Messaging initialization failed');
    }
  },

  async getToken() {
    if (!messaging) {
      console.log('Messaging is not initialized');
      return null;
    }
    try {
      console.log('Requesting FCM token...');
      const currentToken = await getToken(messaging, { vapidKey: process.env.VUE_APP_FIREBASE_WEB_PUSH_PUBLIC_KEY });
      if (currentToken) {
        console.log('FCM token generated:', currentToken);
        return currentToken;
      } else {
        console.log('No registration token available. Request permission to generate one.');
        return null;
      }
    } catch (err) {
      console.error('An error occurred while retrieving token:', err);
      return null;
    }
  },

  onMessageListener() {
    if (!messaging) {
      console.log('Messaging is not initialized');
      return Promise.resolve(null);
    }
    return new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });
  }
};
