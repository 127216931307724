<template>
  <div class="policies-page">
    <div class="content-container">
      <h1 class="page-title">
        Användarvillkor och Policys
      </h1>

      <section class="policy-section">
        <h2>Integritetspolicy</h2>
        <p>
          Vi värnar om din integritet och följer GDPR. Här är hur vi hanterar dina personuppgifter:
        </p>
        <ul>
          <li>Vi samlar endast in nödvändig information</li>
          <li>Din data lagras säkert och delas aldrig med tredje part</li>
          <li>Du har rätt att begära ut eller radera din data</li>
        </ul>
      </section>

      <section class="policy-section">
        <h2>Användarvillkor</h2>
        <p>
          Genom att använda vår tjänst godkänner du följande villkor:
        </p>
        <ul>
          <li>Du ansvarar för din egen säkerhet under träning</li>
          <li>Respektera andra användare och platser</li>
          <li>Följ våra riktlinjer för användning av utrustning</li>
        </ul>
      </section>

      <div class="last-updated">
        Senast uppdaterad: {{ currentDate }}
      </div>
    </div>
  </div>
</template>

<script setup>
const currentDate = new Date().toLocaleDateString('sv-SE', {
  year: 'numeric',
  month: 'long',
  day: 'numeric'
});
</script>

<style scoped>
.policies-page {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: var(--background-color);
  color: var(--text-color);
}

.content-container {
  background-color: var(--card-background);
  border-radius: 0.5rem;
  padding: 2rem;
  box-shadow: var(--shadow-sm);
  transition: box-shadow var(--transition-speed) ease;
}

.content-container:hover {
  box-shadow: var(--shadow-md);
}

.page-title {
  color: var(--text-color);
  font-family: var(--font-heading);
  font-size: 2.5rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--primary-color);
}

.policy-section {
  margin-bottom: 2rem;
  padding: 1.5rem;
  background-color: var(--surface-color);
  border-radius: 0.5rem;
  border: 1px solid var(--border-color);
}

.policy-section h2 {
  color: var(--text-color);
  font-family: var(--font-heading);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.policy-section p {
  color: var(--text-secondary-color);
  line-height: 1.6;
  margin-bottom: 1rem;
}

.policy-section ul {
  list-style-type: disc;
  margin-left: 1.5rem;
  color: var(--text-secondary-color);
}

.policy-section li {
  margin-bottom: 0.5rem;
}

.last-updated {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid var(--border-color);
  color: var(--text-secondary-color);
  font-style: italic;
  text-align: right;
}

a {
  color: var(--link-color);
  text-decoration: none;
  transition: color var(--transition-speed) ease;
}

a:hover {
  color: var(--link-hover-color);
}
</style>