<template>
  <div class="anatomy-visualization">
    <div 
      ref="svgContainer"
      class="svg-container"
      v-html="svgContent"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, watch, nextTick } from 'vue';
import anatomyData from '@/assets/anatomi.json';
import anatomiSvg from '@/assets/anatomi.svg';

const props = defineProps({
  exerciseId: {
    type: String,
    required: true
  },
  muscleGroups: {
    type: Array,
    required: true
  }
});

const emit = defineEmits(['muscle-clicked']);
const svgContainer = ref(null);
const svgContent = ref('');

const loadSvgContent = async () => {
  try {
    const response = await fetch(anatomiSvg);
    if (!response.ok) {
      throw new Error(`Failed to load SVG: ${response.statusText}`);
    }
    const text = await response.text();
    console.log('Loaded SVG content:', text);
    svgContent.value = text;

    await nextTick();
    const svgElement = svgContainer.value?.querySelector('svg');
    if (!svgElement) {
      console.error('No SVG element found after loading');
      return;
    }

    // Set viewBox if not present
    if (!svgElement.getAttribute('viewBox')) {
      svgElement.setAttribute('viewBox', '0 0 500 500');
    }

    // Add event listeners and styles to paths
    const paths = svgElement.querySelectorAll('path');
    console.log('Found paths:', paths.length);
    paths.forEach(path => {
      path.style.transition = 'fill 0.3s ease';
      path.style.cursor = 'pointer';
      path.style.fill = path.id === 'outline' ? 'none' : 'lightgrey';
      path.addEventListener('click', () => handlePathClick(path.id));
    });

    colorMuscles();
  } catch (error) {
    console.error('Error loading SVG:', error);
  }
};

const handlePathClick = (pathId) => {
  const muscle = anatomyData.find(m => m.id.includes(pathId));
  if (muscle) {
    emit('muscle-clicked', muscle.name);
  }
};

const colorMuscles = () => {
  const svgElement = svgContainer.value?.querySelector('svg');
  if (!svgElement) {
    console.error('No SVG element found');
    return;
  }

  // Reset all paths to default color
  svgElement.querySelectorAll('path').forEach(path => {
    if (path.id !== 'outline') {
      path.style.fill = 'lightgrey';
    }
  });

  // Color muscles based on intensity
  props.muscleGroups.forEach(muscleGroup => {
    console.log('Coloring muscles with data:', muscleGroup);
    const muscleData = anatomyData.find(m => 
      m.name === muscleGroup.Name || 
      (Array.isArray(m.namn) ? m.namn.includes(muscleGroup.Name) : m.namn === muscleGroup.Name)
    );

    if (muscleData) {
      const intensity = muscleGroup.Percentage / 100;
      const color = `rgb(${Math.round(255 * intensity)}, ${Math.round(100 * (1 - intensity))}, ${Math.round(100 * (1 - intensity))})`;

      if (Array.isArray(muscleData.id)) {
        muscleData.id.forEach(id => {
          const path = svgElement.getElementById(id);
          if (path && id !== '2' && id !== '3') {
            path.style.fill = color;
          }
        });
      }
    }
  });
};

onMounted(() => {
  loadSvgContent();
});

watch(() => props.muscleGroups, () => {
  colorMuscles();
}, { deep: true });
</script>

<style scoped>
.anatomy-visualization {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  aspect-ratio: 1;
  background-color: var(--surface-color);
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  padding: 1rem;
}

.svg-container {
  width: 100%;
  height: 100%;
}

:deep(svg) {
  width: 100%;
  height: 100%;
  filter: var(--theme-svg-filter);
}

:deep(path) {
  transition: all 0.3s ease;
  stroke: var(--text-color);
  stroke-width: 1;
}

:deep(path:hover) {
  filter: brightness(1.1);
}

:deep(#outline) {
  stroke: var(--text-color);
  stroke-width: 2;
  fill: none !important;
}
</style>
