import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { h } from 'vue';

export default createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'fa',
    aliases: {
      delete: 'fa-duotone fa-trash-can',
      close: 'fa-duotone fa-xmark',
      menu: 'fa-duotone fa-bars',
      plus: 'fa-duotone fa-plus',
      edit: 'fa-duotone fa-pen',
      drag: 'fa-duotone fa-grip-lines',
      refresh: 'fa-duotone fa-arrows-rotate'
    },
    sets: {
      fa: {
        component: (props) => {
          // Convert array of classes to string and combine with any additional classes
          const classes = [props.icon, props.class].filter(Boolean).join(' ')
          return h('i', { class: classes })
        }
      }
    }
  },
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#2c3e50',    // Deep blue-gray for headings
          secondary: '#34495e',   // Lighter blue-gray for subheadings
          accent: '#3498db',      // Bright blue for interactive elements
          background: '#f8fafc',  // Light gray-blue for background
          surface: '#ffffff',     // White for cards
          text: '#2c3e50'        // Main text color
        }
      }
    },
    defaults: {
      VContainer: {
        class: ['mx-auto'],
        style: 'max-width: 800px; padding: 24px;'
      },
      VCard: {
        flat: true,
        class: ['pa-6', 'rounded-lg'],
        style: 'background-color: rgba(255, 255, 255, 0.95); box-shadow: 0 1px 3px rgba(0,0,0,0.1);'
      },
      VCardTitle: {
        class: ['text-h3', 'mb-8', 'font-weight-medium'],
        style: `
          color: #2c3e50;
          font-size: 2.5rem !important;
          letter-spacing: -0.5px;
        `
      },
      VCardText: {
        class: ['text-body-1'],
        style: `
          color: #374151;
          line-height: 1.8;
          font-size: 1.1rem;
        `
      }
    }
  }
});
