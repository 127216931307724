<template>
  <div class="edit-sessions-page">
    <h1>Hantera pass</h1>
    
    <!-- Search and create section -->
    <div class="search-and-create">
      <v-text-field
        v-model="searchTerm"
        label="Sök pass"
        prepend-icon="fas fa-search" 
        @input="filterSessions"
      />
      <select
        v-model="selectedSessionId"
        class="session-select"
        @change="selectSession"
      >
        <option value="">
          Välj pass
        </option>
        <option
          v-for="session in filteredSessions"
          :key="session.id || session.Id"
          :value="session.id || session.Id"
        >
          {{ formatDate(session.date || session.Date) }} - {{ session.name || session.Name }}
        </option>
      </select>
      <button
        class="create-button"
        @click="createNew"
      >
        Skapa nytt
      </button>
    </div>

    <!-- Loading and error messages -->
    <div
      v-if="isLoading"
      class="loading"
    >
      Loading sessions...
    </div>
    <div
      v-else-if="error"
      class="error"
    >
      {{ error }}
    </div>

    <!-- Sessions list -->
    <div
      v-else
      class="sessions-container"
    >
      <div
        v-if="filteredSessions.length"
        class="sessions-list"
      >
        <div
          v-for="session in filteredSessions"
          :key="session.id"
          class="session-card"
          @click="selectSession(session.id)"
        >
          <h2>{{ formatDate(session.date || session.Date) }} 
            <i v-if="session.type !== undefined && sessionTypes[session.type || session.Type]" 
               :class="sessionTypes[session.type || session.Type].icon" />
          </h2>
          <!-- Add UserAvatars component -->
          <UserAvatars 
            :user-ids="getSessionParticipants(session)" 
            :max-visible="5"
            :get-user-profile-picture="getUserProfilePicture"
            :user-names="userNames"
          />
          <!-- Add "Visa deltagare" button -->
          <v-btn
            v-if="isStaff"
            variant="text"
            size="small"
            color="primary"
            @click.stop="showParticipants(session)"
          >
            Visa deltagare
          </v-btn>
        </div>
      </div>
      <div v-else>
        No sessions found
      </div>
    </div>

    <!-- Add the new EditSessionModal component -->
    <EditSessionModal
      v-model:show-modal="showModal"
      :current-session="currentSessionComputed"
      :session-types="sessionTypes"
      :trainers="trainers"
      :record-type-options="recordTypeOptions"
      :is-creating-new="isCreatingNew"
      :places="places"
      :fetch-sessions="fetchSessions"
      @update:current-session="updateCurrentSession"
      @save="saveSession"
    />

    <!-- Participants Modal -->
    <v-dialog
      v-model="showParticipantsModal"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          <span>Deltagare</span>
          <v-spacer />
          <v-btn
            icon
            @click="closeParticipantsModal"
          >
            <v-icon 
              icon="fas fa-xmark"
            />
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-list v-if="selectedSession">
            <v-list-subheader>Bokade deltagare</v-list-subheader>
            <v-list-item
              v-for="userId in selectedSession.users"
              :key="userId"
            >
              <template #prepend>
                <v-avatar size="32">
                  <v-img
                    :src="getUserProfilePicture(userId)"
                    :alt="userNames[userId]"
                  />
                </v-avatar>
              </template>
              <v-list-item-title>{{ userNames[userId] || 'Laddar...' }}</v-list-item-title>
              <template #append>
                <v-btn
                  icon
                  size="small"
                  color="error"
                  @click="removeParticipant(userId, selectedSession.id)"
                >
                  <v-icon>fas fa-trash</v-icon>
                </v-btn>
              </template>
            </v-list-item>

            <v-divider />

            <v-list-subheader>Tillgängliga användare</v-list-subheader>
            <v-text-field
              v-model="userSearch"
              label="Sök anvndare"
              prepend-icon="fas fa-search" 
              @input="filterUsers"
            />
            <v-list-item
              v-for="user in filteredUsers"
              :key="user.id"
            >
              <template #prepend>
                <v-avatar size="32">
                  <v-img
                    :src="user.profilePicture"
                    :alt="user.fullName"
                  />
                </v-avatar>
              </template>
              <v-list-item-title>{{ user.fullName }}</v-list-item-title>
              <template #append>
                <v-btn
                  icon="fas fa-plus" 
                  size="small"
                  color="success"
                  @click="addParticipant(user.id)"
                />
              </template>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Add this new dialog for displaying the analysis -->
    <v-dialog
      v-model="showAnalysisDialog"
      max-width="800px"
    >
      <v-card>
        <v-card-title>AI Analys av passet</v-card-title>
        <v-card-text>
          <div v-safe-html="renderedAnalysis" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="showAnalysisDialog = false"
          >
            Stäng
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="currentSessionComputed">
      <draggable 
        v-model="currentSessionComputed.segments" 
        item-key="id"
        @change="onSegmentMove"
      >
        <template #item="{ element: segment }">
          <div class="segment">
            <div class="segment-header">
              <span class="segment-drag-handle">
                <i 
                  class="fas fa-grip-vertical"
                />
              </span>
              <!-- Other segment header content -->
            </div>
            
            <draggable 
              v-model="segment.records" 
              item-key="id"
              @change="onRecordMove"
            >
              <template #item="{ element: record }">
                <div class="record">
                  <div class="record-header">
                    <span class="record-drag-handle">
                      <i 
                        class="fas fa-grip-vertical"
                      />
                    </span>
                    <!-- Use the record variable here -->
                    <span>{{ record.exerciseName || record.placeName }}</span>
                  </div>
                  <!-- Add more record content here using the record variable -->
                  <div v-if="record.recordType === 0">
                    Exercise: {{ record.exerciseName }}
                    Repetitions: {{ record.repetitions }}
                  </div>
                  <div v-else-if="record.recordType === 2">
                    Place: {{ record.placeName }}
                  </div>
                  <!-- Add more conditions for other record types -->
                </div>
              </template>
            </draggable>
            
            <!-- Add record button -->
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>


<script>
// All imports should be at the very top
import { ref, onMounted, computed, watch, nextTick, reactive } from 'vue'
import { api } from '@/services/api.js'
import { debounce } from 'lodash'
import { useMainStore } from '@/stores'
import { parseISO, format, isWeekend, set, isValid } from 'date-fns'
import UserAvatars from '@/components/UserAvatars.vue'
import { storeToRefs } from 'pinia'
import { useUserState } from '@/composables/useAuth'
import { useToast } from 'vue-toast-notification'
import { VDialog, VCard, VBtn, VIcon, VCardText } from 'vuetify/components'
import { marked } from 'marked'
import DOMPurify from 'dompurify'
import EditSessionModal from '@/components/EditSessionModal.vue'
import { VueDraggableNext } from 'vue-draggable-next'
import { vSafeHtml } from '@/directives/safe-html'

export default {
  name: 'EditSessionsPage',
  components: {
    UserAvatars,
    VDialog,
    VCard,
    VBtn,
    VIcon,
    VCardText,
    EditSessionModal,
    draggable: VueDraggableNext,
  },
  directives: {
    'safe-html': vSafeHtml
  },
  setup() {
    const store = useMainStore()
    const { userProfilePictures } = storeToRefs(store)
    const { getUserProfilePicture } = store
    const { currentUser, isLoggedIn, isStaff } = useUserState()
    const toast = useToast()

    console.log('EditSessionsPage setup - currentUser:', currentUser.value)
    console.log('EditSessionsPage setup - isStaff:', isStaff.value)

    watch(() => store.user, (newUser) => {
      console.log('User changed in EditSessionsPage:', newUser)
      console.log('isStaff value:', isStaff.value)
      console.log('currentUser value:', currentUser.value)
    }, { immediate: true })

    // Reactive state
    const currentSession = reactive({
      id: null,
      date: '',
      name: '',
      type: 0,
      description: '',
      trainer: '',
      segments: [],
      // Add any other necessary properties
    })
    const allSessions = ref([])
    const filteredSessions = ref([])
    const searchTerm = ref('')
    const selectedSessionId = ref('')
    const trainerSearchResults = ref([])
    const isEditing = computed(() => !!currentSession.id)
    const showModal = ref(false)

    const exercises = ref([])
    const places = ref([])
    const trainers = ref([])

    const currentSessionComputed = computed({
      get: () => {
        return currentSession
      },
      set: (value) => {
        console.log('Setting currentSessionComputed:', JSON.stringify(value, null, 2))
        Object.assign(currentSession, value)
      }
    })

    // Define sessionTypes only once
    const sessionTypes = {
      0: { name: 'Slinga', icon: 'fad fa-route' },
      1: { name: 'Stege', icon: 'fad fa-stairs' },
      2: { name: 'Pyramid', icon: 'fad fa-triangle' },
      3: { name: 'Tabata', icon: 'fad fa-stopwatch' },
      4: { name: 'AMRAP', icon: 'fad fa-infinity' },
      5: { name: 'EMOM', icon: 'fad fa-clock' },
      6: { name: 'På tid', icon: 'fad fa-hourglass-half' },
      7: { name: 'Chipper', icon: 'fad fa-list-ol' },
      8: { name: 'Hero WOD', icon: 'fad fa-medal' },
      9: { name: 'Styrka', icon: 'fad fa-dumbbell' },
      10: { name: 'Teknik', icon: 'fad fa-bullseye-arrow' },
      11: { name: 'Cirkelträning', icon: 'fad fa-circle-notch' },
      12: { name: 'Intervalltrning', icon: 'fad fa-wave-square' }
    }

    const recordTypeOptions = [
      { value: 0, text: 'exercise' },
      { value: 1, text: 'distance' },
      { value: 2, text: 'skill' },
    ];

    // Modify the fetchSessions function to include error handling and loading state
    const isLoading = ref(false)
    const error = ref(null)

    const userNames = ref({})

    const fetchUserNames = async (userIds) => {
      for (const userId of userIds) {
        if (!userNames.value[userId]) {
          try {
            const user = await store.getUserById(userId)
            userNames.value[userId] = `${user.firstName} ${user.lastName}`
          } catch (error) {
            console.error(`Error fetching user name for user ${userId}:`, error)
            userNames.value[userId] = 'Unknown User'
          }
        }
      }
    }

    const getSessionParticipants = (session) => {
      if (!session) return []
      console.log('Getting participants for session:', session)
      // Use the uppercase Participants property
      const participants = session.Participants || []
      console.log('Participants found:', participants)
      return participants
    }

    // Modify the fetchSessions function to fetch user names
    const fetchSessions = async () => {
      console.log('Fetching sessions...')
      isLoading.value = true
      error.value = null
      try {
        const response = await api.getSessions()
        console.log('Sessions API response:', response)
        
        if (response && response.data && Array.isArray(response.data)) {
          // Filter out invalid sessions
          const validSessions = response.data.filter(isValidSession)
          console.log('Valid sessions count:', validSessions.length)
          
          // Sort valid sessions by date
          allSessions.value = validSessions.sort((a, b) => {
            const dateA = parseISO(a.date || a.Date)
            const dateB = parseISO(b.date || b.Date)
            console.log(`Comparing: ${a.date || a.Date} (${dateA}) and ${b.date || b.Date} (${dateB})`)
            return dateB.getTime() - dateA.getTime()
          })
          
          console.log('Sorted sessions:', allSessions.value)
          filteredSessions.value = [...allSessions.value]
          
          // Fetch user names for all participants
          const allParticipantIds = allSessions.value.flatMap(session => session.participants || session.Participants || [])
          await fetchUserNames(Array.from(new Set(allParticipantIds)))
        } else {
          console.error('Unexpected sessions response structure:', response)
          allSessions.value = []
          filteredSessions.value = []
        }
      } catch (err) {
        console.error('Error fetching sessions:', err)
        error.value = 'Failed to fetch sessions. Please try again.'
        allSessions.value = []
        filteredSessions.value = []
      } finally {
        isLoading.value = false
      }
    }

    // Update the filterSessions function
    const filterSessions = () => {
      filteredSessions.value = allSessions.value
        .filter(session => 
          searchTerm.value === '' || // Include all sessions if search term is empty
          (session.name || session.Name)?.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
          formatDate(session.date || session.Date)?.toLowerCase().includes(searchTerm.value.toLowerCase())
        )
    }

    // Add this helper function to get user information
    const getUserById = async (userId) => {
      try {
        return await store.getUserById(userId)
      } catch (error) {
        console.error(`Error fetching user with id ${userId}:`, error)
        return null
      }
    }

    // Update the selectSession function
    const selectSession = async (id) => {
      if (!id) {
        console.error('Invalid session id')
        return
      }
      selectedSessionId.value = id
      const selectedSession = allSessions.value.find(s => s.Id === id || s.id === id)
      if (selectedSession) {
        console.log('Original session data:', selectedSession)
        
        // Add specific logging for records and descriptions
        const segments = selectedSession.Segments || selectedSession.segments || []
        segments.forEach((segment, i) => {
          const records = segment.Records || segment.records || []
          records.forEach((record, j) => {
            console.log(`Segment ${i}, Record ${j} description:`, record.Description || record.description)
          })
        })
        
        console.log('Selected session:', JSON.stringify(selectedSession, null, 2))
        
        // Fetch all exercises and places if not already loaded
        if (exercises.value.length === 0) {
          await fetchExercises()
        }
        if (Object.keys(places.value).length === 0) {
          await fetchPlaces()
        }
        
        currentSessionComputed.value = {
          id: selectedSession.Id || selectedSession.id,
          date: selectedSession.Date || selectedSession.date,
          name: selectedSession.Name || selectedSession.name,
          type: selectedSession.Type || selectedSession.type,
          description: selectedSession.Description || selectedSession.description,
          trainer: selectedSession.Trainer || selectedSession.trainer,
          segments: Array.isArray(selectedSession.Segments || selectedSession.segments) 
            ? (selectedSession.Segments || selectedSession.segments).map(segment => ({
                id: segment.Id || segment.id || Date.now() + Math.random(),
                label: segment.Label || segment.label,
                type: segment.Type || segment.type,
                records: Array.isArray(segment.Records || segment.records)
                  ? (segment.Records || segment.records).map(record => ({
                      id: record.Id || record.id || Date.now() + Math.random(),
                      recordType: record.RecordType || record.recordType,
                      exerciseId: record.ExerciseId || record.exerciseId,
                      exerciseName: getExerciseName(record.ExerciseId || record.exerciseId),
                      repetitions: record.Repetitions || record.repetitions,
                      description: record.Description || record.description || '',
                      placeId: record.PlaceId || record.placeId || '',
                      placeName: getPlaceName(record.PlaceId || record.placeId),
                      duration: record.Duration || record.duration,
                      unit: record.Unit || record.unit || '',
                      exerciseSearchResults: [],
                      placeSearchResults: [],
                      notes: record.Description || record.description || ''
                    }))
                  : [],
              }))
            : [],
          metadata: selectedSession.Metadata || selectedSession.metadata,
          ratings: selectedSession.Ratings || selectedSession.ratings || [],
          weather: selectedSession.Weather || selectedSession.weather || [],
          imageUrl: selectedSession.ImageUrl || selectedSession.imageUrl || [],
          distance: selectedSession.Distance || selectedSession.distance,
          elevation: selectedSession.Elevation || selectedSession.elevation,
          cal: selectedSession.Cal || selectedSession.cal,
          participants: selectedSession.Participants || selectedSession.participants || []
        }
        
        // Add debug logging for records
        const records = selectedSession.Segments?.[0]?.Records || selectedSession.segments?.[0]?.records || []
        console.log('First segment records:', records)
        
        // Add debug logging for computed value
        console.log('Current session computed value:', JSON.stringify(currentSessionComputed.value, null, 2))
        
        showModal.value = true
      } else {
        console.error(`Session with id ${id} not found in:`, allSessions.value)
        toast.error('Session not found')
      }
    }

    // Add this helper function
    const getDefaultTime = (date) => {
      return isWeekend(date) ? '10:00:00' : '18:00:00'
    }

    // Update the createNew function
    const createNew = () => {
      console.log('Creating new session, current user:', currentUser.value)
      const currentDate = new Date()
      const defaultTime = getDefaultTime(currentDate)
      const formattedDate = format(currentDate, `yyyy-MM-dd'T'${defaultTime}`)
      
      currentSessionComputed.value = {
        id: null,
        date: formattedDate,
        name: `Pass ${format(currentDate, 'yyyy-MM-dd')}`,
        trainer: currentUser.value ? currentUser.value.id : null,
        type: 0,
        segments: [
          {
            id: Date.now(),
            label: 'Slinga',
            type: 0,
            records: [
              {
                id: Date.now() + 1,
                recordType: 0,
                exerciseId: null,
                exerciseName: '',
                repetitions: '',
                description: '',
                placeId: '',
                placeName: '',
                duration: '',
                unit: '',
                exerciseSearchResults: [],
                placeSearchResults: [],
                notes: ''
              }
            ]
          }
        ],
        description: '',
        metadata: {},
        ratings: [],
        weather: [],
        imageUrl: [],
        participants: []
      }
      
      selectedTrainer.value = currentUser.value ? {
        id: currentUser.value.id,
        name: `${currentUser.value.firstName} ${currentUser.value.lastName}`
      } : null
      
      console.log('New session created:', currentSessionComputed.value)
      selectedSessionId.value = ''
      showModal.value = true
    }

    // Save session (create or update)
    const saveSession = async (updatedSession) => {
      try {
        console.log('Saving session:', updatedSession);
        // Add your API call or state update logic here
        toast.success('Session saved successfully');
        await fetchSessions(); // Refresh the sessions list
        showModal.value = false; // Close the modal
      } catch (error) {
        console.error('Error saving session:', error);
        toast.error('Failed to save session');
      }
    };

    // Cancel editing/creating session
    const cancel = () => {
      currentSessionComputed.value = null
      selectedSessionId.value = ''
    }

    // Add a new segment
    const addSegment = () => {
      if (!currentSessionComputed.value) {
        console.error('No current session available')
        return
      }
      
      const newSegment = {
        id: Date.now(),
        label: `Slinga ${currentSessionComputed.value.segments.length + 1}`, // Updated label
        type: '0', // Default to Trail
        records: [
          {
            id: Date.now() + 1,
            recordType: 0,
            exerciseId: null,
            exerciseName: '',
            repetitions: '',
            description: '',
            placeId: '',
            placeName: '',
            duration: '',
            unit: '',
            exerciseSearchResults: [],
            placeSearchResults: [],
            notes: ''
          }
        ]
      }
      
      updateSegmentFields(newSegment);
      currentSessionComputed.value.segments.push(newSegment)
    }

    // Remove a segment
    const removeSegment = (id) => {
      currentSessionComputed.value.segments = currentSessionComputed.value.segments.filter(segment => segment.id !== id)
    }

    // Add a new record to a segment
    const addRecord = (segmentId, recordType = 0) => {
      const segment = currentSessionComputed.value.segments.find(s => s.id === segmentId)
      if (segment) {
        const newRecord = {
          id: Date.now(),
          recordType: recordType,
          placeId: '',
          placeName: '',
          placeSearchResults: []
        }

        if (recordType === 0) {
          newRecord.exerciseId = null
          newRecord.exerciseName = ''
          newRecord.repetitions = ''
          newRecord.description = ''
          newRecord.exerciseSearchResults = []
        } else if (recordType === 1 || recordType === 4) {
          newRecord.duration = ''
          newRecord.unit = ''
        }

        segment.records.push(newRecord)
      }
    }

    // Remove a record from a segment
    const removeRecord = (segmentId, recordId) => {
      const segment = currentSessionComputed.value.segments.find(s => s.id === segmentId)
      if (segment) {
        segment.records = segment.records.filter(r => r.id !== recordId)
      }
    }

    // Add this to your reactive state declarations
    const trainerSearchInput = ref('')

    // Update the searchTrainers function
    const searchTrainers = debounce(async () => {
      console.log('Searching trainers with term:', trainerSearchInput.value)
      if (trainerSearchInput.value.length > 1) {
        try {
          const response = await api.getUsers()
          console.log('API response:', response)
          const filteredUsers = response.data.filter(user => 
            `${user.firstName} ${user.lastName}`.toLowerCase().includes(trainerSearchInput.value.toLowerCase())
          )
          console.log('Filtered users:', filteredUsers)
          
          trainerSearchResults.value = filteredUsers
          console.log('Trainer search results:', trainerSearchResults.value)
        } catch (error) {
          console.error('Error searching trainers:', error)
          trainerSearchResults.value = []
        }
      } else {
        trainerSearchResults.value = []
      }
    }, 300)

    // Update the selectTrainer function
    const selectTrainer = (trainer) => {
      console.log('Selecting trainer:', trainer);
      if (currentSessionComputed.value) {
        currentSessionComputed.value.trainer = trainer.id; // Save the ID directly
        trainerSearchInput.value = `${trainer.firstName} ${trainer.lastName}`; // Keep the name for display
      }
      trainerSearchResults.value = [];
      console.log('Updated currentSession:', JSON.stringify(currentSessionComputed.value, null, 2));
    }

    // Fetch all exercises
    const fetchExercises = async () => {
      try {
        const response = await api.getExercises()
        exercises.value = response.data
      } catch (error) {
        console.error('Error fetching exercises:', error)
      }
    }

    // Update the searchExercises function
    const searchExercises = debounce((recordId) => {
      const record = findRecordById(recordId)
      if (record && record.exerciseName.length > 2) {
        const allExercises = exercises.value
        record.exerciseSearchResults = allExercises.filter(result => 
          result.name.toLowerCase().includes(record.exerciseName.toLowerCase())
        ).slice(0, 10) // Limit to 10 results for better performance
      } else if (record) {
        record.exerciseSearchResults = []
      }
    }, 300)

    // Add a new function to select an exercise
    const selectExercise = (exercise, record) => {
      record.exerciseId = exercise.id
      record.exerciseName = exercise.name
      record.exerciseSearchResults = []
    }

    // Fetch all places
    const fetchPlaces = async () => {
      try {
        const response = await api.getPlaces()
        places.value = Array.isArray(response.data) ? response.data : []
      } catch (error) {
        console.error('Error fetching places:', error)
      }
    }

    // Update the searchPlaces function
    const searchPlaces = debounce((recordId) => {
      const record = findRecordById(recordId)
      if (record && record.placeName.length > 2) {
        const allPlaces = places.value
        record.placeSearchResults = allPlaces.filter(result => 
          result.name.toLowerCase().includes(record.placeName.toLowerCase())
        ).slice(0, 10) // Limit to 10 results for better performance
      } else if (record) {
        record.placeSearchResults = []
      }
    }, 300)

    // Update the selectPlace function
    const selectPlace = (place, record) => {
      console.log('Selecting place:', place)
      record.placeId = place.id
      record.placeName = place.name
      record.placeSearchResults = []
    }

    // Helper to find a record by ID
    const findRecordById = (recordId) => {
      for (const segment of currentSessionComputed.value.segments) {
        const record = segment.records.find(r => r.id === recordId)
        if (record) return record
      }
      return null
    }

    // Get exercise name by ID
    const getExerciseName = (exerciseId) => {
      const exercise = exercises.value.find(e => e.id === exerciseId)
      return exercise ? exercise.name : ''
    }

    // Get place name by ID
    // eslint-disable-next-line no-unused-vars
    const getPlaceName = (placeId) => {
      const place = places.value.find(p => p.id === placeId)
      return place ? place.name : ''
    }

    // Capitalize first letter utility
    const capitalize = (s) => {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }

    // Format date for display
    const formatDate = (dateString) => {
      if (!dateString) {
        console.warn('No date string provided to formatDate')
        return 'Invalid Date'
      }
      try {
        const date = parseISO(dateString)
        if (!isValid(date)) {
          console.warn('Invalid date parsed:', dateString)
          return 'Invalid Date'
        }
        const options = { 
          year: 'numeric', 
          month: 'long', 
          day: 'numeric', 
          hour: '2-digit', 
          minute: '2-digit' 
        }
        return date.toLocaleDateString('sv-SE', options)
      } catch (error) {
        console.error('Error formatting date:', error)
        return 'Invalid Date'
      }
    }

    // Update the getSegmentCount function
    const getSegmentCount = (session) => {
      return session.Segments ? session.Segments.length : 0
    }

    // Open the modal
    const openModal = () => {
      showModal.value = true
    }

    // Close the modal
    const closeModal = () => {
      showModal.value = false
      currentSessionComputed.value = {}
      selectedSessionId.value = ''
    }

    // Fetch initial data on mount
    onMounted(async () => {
      console.log('EditSessionsPage mounted')
      console.log('Is staff:', isStaff.value)
      console.log('User:', currentUser.value)
      console.log('Is logged in:', isLoggedIn.value)
      console.log('Store user:', store.user)
      
      try {
        if (!currentUser.value) {
          console.log('User data not available, fetching...')
          await store.fetchCurrentUser()
        }
        await fetchSessions()
        await fetchExercises()
        await fetchPlaces()
        await fetchTrainers()
      } catch (err) {
        console.error('Error fetching data:', err)
        toast.error('Failed to load initial data. Please try again.')
      }
    })

    const drag = ref(false)

    // Add this near the other watch statements
    watch(currentUser, (newValue) => {
      console.log('Current user updated:', newValue)
    }, { immediate: true })

    watch(() => store.user, (newUser) => {
      console.log('Store user state changed:', newUser)
      if (newUser && !newUser.error) {
        // Update any local state or perform actions when user data becomes available
      }
    })

    const formattedDate = computed({
      get: () => {
        if (!currentSessionComputed.value || !currentSessionComputed.value.date) return ''
        return format(parseISO(currentSessionComputed.value.date), 'yyyy-MM-dd')
      },
      set: (newValue) => {
        if (currentSessionComputed.value) {
          const newDate = parseISO(newValue)
          currentSessionComputed.value.date = format(newDate, "yyyy-MM-dd'T'HH:mm:ss")
          updateSessionTime(newDate)
        }
      }
    })

    const formattedTime = computed({
      get: () => {
        if (!currentSessionComputed.value || !currentSessionComputed.value.date) return ''
        return format(parseISO(currentSessionComputed.value.date), 'HH:mm')
      },
      set: (newValue) => {
        if (currentSessionComputed.value) {
          const [hours, minutes] = newValue.split(':')
          const newDate = set(parseISO(currentSessionComputed.value.date), { hours, minutes })
          currentSessionComputed.value.date = format(newDate, "yyyy-MM-dd'T'HH:mm:ss")
        }
      }
    })

    const updateSessionTime = (date) => {
      if (!date) return
      const defaultTime = isWeekend(date) ? { hours: 10, minutes: 0 } : { hours: 18, minutes: 0 }
      const newDate = set(date, defaultTime)
      currentSessionComputed.value.date = format(newDate, "yyyy-MM-dd'T'HH:mm:ss")
      currentSessionComputed.value.name = `Pass ${format(newDate, 'yyyy-MM-dd')}` // Update the name
    }

    // Add these new refs
    const showParticipantsModal = ref(false)
    const selectedSession = ref(null)
    const availableUsers = ref([])
    const userSearch = ref('')
    const filteredUsers = ref([])

    const showParticipants = async (session) => {
      console.log('Showing participants for session:', session);
      selectedSession.value = {
        ...session,
        id: session.Id || session.id,
        users: session.Participants || session.participants || [] // Use either Participants or participants
      };
      console.log('Selected session:', selectedSession.value);
      showParticipantsModal.value = true;
      await fetchUserProfilePictures();
      await fetchUserNames(selectedSession.value.users);
      await fetchAvailableUsers();
    };

    const closeParticipantsModal = () => {
      showParticipantsModal.value = false
      selectedSession.value = null
      userSearch.value = ''
    }

    const fetchAvailableUsers = async () => {
      try {
        const response = await api.getUsers()
        if (Array.isArray(response.data)) {
          availableUsers.value = response.data
            .filter((availableUser) => !selectedSession.value.users.includes(availableUser.id))
            .map((availableUser) => ({
              ...availableUser,
              profilePicture: availableUser.profilePicture ? getUserProfilePicture(availableUser.id) : null,
              fullName: `${availableUser.firstName} ${availableUser.lastName}`,
            }))
        } else {
          console.error('Unexpected response format from getUsers:', response)
          availableUsers.value = []
        }
        filteredUsers.value = availableUsers.value
      } catch (error) {
        console.error('Error fetching users:', error)
      }
    }

    const fetchUserProfilePictures = async () => {
      if (selectedSession.value && Array.isArray(selectedSession.value.users)) {
        for (const userId of selectedSession.value.users) {
          if (!userProfilePictures.value[userId]) {
            try {
              const user = await store.getUserById(userId);
              if (user) {
                userProfilePictures.value[userId] = getUserProfilePicture(userId);
                userNames.value[userId] = `${user.firstName} ${user.lastName}`;
              } else {
                console.error(`User data not found for userId: ${userId}`);
                userProfilePictures.value[userId] = require('@/assets/default-profile-picture.png');
                userNames.value[userId] = 'Unknown User';
              }
            } catch (error) {
              console.error(`Error fetching profile picture for user ${userId}:`, error);
              userProfilePictures.value[userId] = require('@/assets/default-profile-picture.png');
              userNames.value[userId] = 'Unknown User';
            }
          }
        }
      }
    }

    const filterUsers = () => {
      const searchTerm = userSearch.value.toLowerCase()
      filteredUsers.value = availableUsers.value.filter(
        (user) =>
          user.firstName.toLowerCase().includes(searchTerm) ||
          user.lastName.toLowerCase().includes(searchTerm)
      )
    }

    const addParticipant = async (userId) => {
      try {
        await api.createBooking(selectedSession.value.id, userId)
        const addedUser = availableUsers.value.find((user) => user.id === userId)
        if (addedUser) {
          selectedSession.value.users.push(userId)
          userNames.value[userId] = `${addedUser.firstName} ${addedUser.lastName}`
          userProfilePictures.value[userId] =
            addedUser.profilePicture || require('@/assets/default-profile-picture.png')
        }
        await fetchAvailableUsers()
        toast.success('Deltagare tillagd framgångsrikt.')
      } catch (error) {
        console.error('Error adding participant:', error)
        toast.error('Det gick inte att lägga till deltagaren. Försök igen.')
      }
    }

    const removeParticipant = async (userId, sessionId) => {
      try {
        await api.deleteBooking(sessionId, userId)
        selectedSession.value.users = selectedSession.value.users.filter(id => id !== userId)
        // Update the session in allSessions.value
        const sessionIndex = allSessions.value.findIndex(s => s.id === sessionId)
        if (sessionIndex !== -1) {
          allSessions.value[sessionIndex].participants = allSessions.value[sessionIndex].participants.filter(id => id !== userId)
        }
        await fetchAvailableUsers()
        toast.success('Deltagare borttagen framgångsrikt.')
      } catch (error) {
        console.error('Error removing participant:', error)
        toast.error('Det gick inte att ta bort deltagaren. Försök igen.')
      }
    }

    const handleSessionEnhanced = (enhancedData) => {
      if (enhancedData && enhancedData.enhancedSegments) {
        // Preserve existing segment IDs where possible and ensure records are properly added
        const updatedSegments = enhancedData.enhancedSegments.map((enhancedSegment, index) => {
          const existingSegment = currentSessionComputed.value.segments[index];
          return {
            ...enhancedSegment,
            id: existingSegment ? existingSegment.id : `new-segment-${Date.now()}-${index}`,
            records: enhancedSegment.records.map((record, recordIndex) => ({
              ...record,
              id: `new-record-${Date.now()}-${index}-${recordIndex}`,
              exerciseSearchResults: [],
              placeSearchResults: []
            }))
          };
        });

        // Update the currentSession with the new segments
        currentSessionComputed.value = {
          ...currentSessionComputed.value,
          segments: updatedSegments
        };

        toast.success('Passet har förbättrats med AI!');
        console.log('AI förklaring:', enhancedData.explanation);
        console.log('Updated session:', JSON.stringify(currentSessionComputed.value, null, 2));
        
        // Force a re-render of the component
        nextTick(() => {
          currentSessionComputed.value = { ...currentSessionComputed.value };
        });
        
      } else {
        console.error('Invalid enhanced data received:', enhancedData);
        toast.error('Kunde inte förbättra passet. Ogiltig data mottagen.');
      }
    };

    const handleEnhanceError = (errorMessage) => {
      toast.error(errorMessage);
    };

    // Add this function to handle segment type changes
    const updateSegmentFields = (segment) => {
      // Reset all fields
      segment.startReps = null;
      segment.endReps = null;
      segment.step = null;
      segment.workTime = null;
      segment.restTime = null;
      segment.rounds = null;
      segment.duration = null;
      segment.intervalTime = null;
      segment.totalTime = null;

      // Set default values based on segment type
      switch (segment.type) {
        case 'ladder':
        case 'pyramid':
          segment.startReps = 1;
          segment.endReps = 10;
          segment.step = 1;
          break;
        case 'tabata':
          segment.workTime = 20;
          segment.restTime = 10;
          segment.rounds = 8;
          break;
        case 'amrap':
        case 'for_time':
          segment.duration = 20;
          break;
        case 'emom':
          segment.intervalTime = 60;
          segment.totalTime = 10;
          break;
      }
    }

    const showAnalysisDialog = ref(false);
    const analysisResult = ref('');
    const renderedAnalysis = computed(() => {
      return DOMPurify.sanitize(marked(analysisResult.value));
    });

    const handleSessionAnalyzed = (analysis) => {
      analysisResult.value = analysis;
      showAnalysisDialog.value = true;
    };

    const handleAnalyzeError = (errorMessage) => {
      toast.error(errorMessage);
    };

    const selectedTrainer = ref(null)

    const formattedTrainers = computed(() => {
      return trainers.value.map(trainer => ({
        id: trainer.id,
        name: `${trainer.firstName} ${trainer.lastName}`
      }))
    })

    const updateTrainer = (trainer) => {
      if (trainer) {
        currentSessionComputed.value.trainer = trainer.id
        currentSessionComputed.value.trainerName = trainer.name
      } else {
        currentSessionComputed.value.trainer = null
        currentSessionComputed.value.trainerName = ''
      }
    }

    // Watch for changes in currentSession.trainer
    watch(() => currentSessionComputed.value.trainer, (newTrainerId) => {
      if (newTrainerId) {
        const trainer = formattedTrainers.value.find(t => t.id === newTrainerId)
        if (trainer) {
          selectedTrainer.value = trainer
        }
      } else {
        selectedTrainer.value = null
      }
    }, { immediate: true })

    const fetchTrainers = async () => {
      try {
        const response = await api.getUsers()
        trainers.value = response.data
        console.log('Fetched users:', trainers.value)
      } catch (error) {
        console.error('Error fetching users:', error)
        toast.error('Failed to fetch users. Please try again.')
      }
    }

    const updateCurrentSession = (updatedSession) => {
      console.log('EditSessionsPage: updateCurrentSession called with:', updatedSession);
      currentSessionComputed.value = updatedSession;
    }

    const updateShowModal = (value) => {
      showModal.value = value
      if (!value) {
        // Reset currentSession to an empty object when closing the modal
        currentSessionComputed.value = {}
      }
    }

    // Add a new computed property to check if we're creating a new session
    const isCreatingNew = computed(() => {
      return !!currentSessionComputed.value && !currentSessionComputed.value.id
    })

    // Update the watch for currentSession
    watch(() => currentSessionComputed.value, (newSession) => {
      if (newSession) {
        console.log('Current session updated:', newSession)
      }
    }, { deep: true, immediate: true })

    const onSegmentMove = (e) => {
      console.log('Segment moved', e);
      // You can add logic here to update the backend if needed
    }

    const onRecordMove = (e) => {
      console.log('Record moved', e);
      // You can add logic here to update the backend if needed
    }

    const isValidSession = (session) => {
      if (!session.Id && !session.id) {
        console.log('Invalid session: missing id', session)
        return false
      }
      if (!session.Date && !session.date) {
        console.log('Invalid session: missing date', session)
        return false
      }
      if (!Array.isArray(session.Segments) && !Array.isArray(session.segments)) {
        console.log('Invalid session: segments is not an array', session)
        return false
      }
      return true
    }

    // Add this watch for date changes
    watch(() => currentSessionComputed.value?.date, (newDate) => {
      if (newDate) {
        const date = parseISO(newDate)
        const defaultTime = isWeekend(date) ? { hours: 10, minutes: 0 } : { hours: 18, minutes: 0 }
        const updatedDate = set(date, defaultTime)
        currentSessionComputed.value.date = format(updatedDate, "yyyy-MM-dd'T'HH:mm:ss")
        currentSessionComputed.value.name = `Pass ${format(updatedDate, 'yyyy-MM-dd')}`
      }
    })

    return {
      currentSessionComputed,
      isEditing,
      trainerSearchResults,
      saveSession,
      cancel,
      addSegment,
      removeSegment,
      addRecord,
      removeRecord,
      searchTrainers,
      selectTrainer,
      searchExercises,
      selectExercise,
      searchPlaces,
      selectPlace,
      allSessions,
      filteredSessions,
      searchTerm,
      selectedSessionId,
      filterSessions,
      selectSession,
      createNew,
      formatDate,
      getSegmentCount,
      showModal,
      openModal,
      closeModal,
      exercises,
      places,
      sessionTypes,
      capitalize,
      getExerciseName,
      isLoading,
      error,
      drag,
      recordTypeOptions,
      formattedDate,
      trainerSearchInput,
      getSessionParticipants,
      getUserProfilePicture,
      userNames,
      showParticipantsModal,
      selectedSession,
      availableUsers,
      userSearch,
      filteredUsers,
      showParticipants,
      closeParticipantsModal,
      addParticipant,
      removeParticipant,
      filterUsers,
      handleSessionEnhanced,
      handleEnhanceError,
      formattedTime,
      updateSessionTime,
      getUserById,
      updateSegmentFields,
      showAnalysisDialog,
      renderedAnalysis,
      handleSessionAnalyzed,
      handleAnalyzeError,
      trainers,
      selectedTrainer,
      formattedTrainers,
      updateTrainer,
      fetchSessions,
      fetchTrainers,
      updateCurrentSession,
      updateShowModal,
      isCreatingNew,
      currentUser,
      isStaff,
      isLoggedIn,
      onSegmentMove,
      onRecordMove,
      VueDraggableNext,
    }
  }
}
</script>

<style scoped>
.edit-sessions-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.search-and-create {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.search-input,
.session-select,
.create-button {
  flex: 1;
  min-width: 200px;
  padding: 10px;
  font-size: 16px;
}

.sessions-container {
  margin-bottom: 20px;
}

.sessions-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.session-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.session-card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

/* Update these styles for the new modal structure */
.edit-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.edit-modal-content {
  flex-grow: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0px;
}



/* Ensure the dialog takes up the full screen on mobile */
@media (max-width: 600px) {
  .v-dialog {
    margin: 0 !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    max-width: none !important;
  }
}

.edit-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 100%;
  overflow-x: auto;
}

.session-details {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.date-time-container {
  display: flex;
  gap: 15px;
}

.date-group,
.time-group {
  flex: 1;
  max-width: calc(50% - 7.5px); /* Subtracting half of the gap */
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.segments-container,
.records-container {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
}

.segments-list,
.records-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.segment,
.record {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  min-width: 1300px;
}

.segment-header,
.record-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.segment-drag-handle,
.record-drag-handle {
  cursor: move;
  padding: 5px;
  color: #999;
}


.segment-type,
.record-type {
  flex: 1;
  min-width: 0;
  max-width: 60px;
}
.segment-label{
  flex: 1;
  min-width: 0;
  max-width: 100%;
}

.record-details {
  display: flex;
  flex-grow: 1;
  gap: 10px;
  align-items: center;
}

.exercise-search,
.place-search {
  flex-grow: 1;
  position: relative;
}

.repetitions-input {
  width: 80px; /* Adjusted to be smaller */
}

.description-input {
  flex-grow: 1;
}

.remove-button,
.add-button {
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
}

.remove-button {
  background: none;
  border: none;
  color: #ff4d4d;
}

.add-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
}

.map-container {
  width: 100%;
  height: 400px; /* Adjust this value as needed */
  margin-bottom: 20px;
}

.session-map {
  width: 100%;
  height: 100%;
}

.session-stats {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 10px;
}

.save-button,
.cancel-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.save-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
}

.cancel-button {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
}

@media (max-width: 768px) {
  :deep(.modal-container) {
    width: 95% !important;
    max-width: none !important;
  }
}

/* Add these new styles for search results */
.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.search-result-item {
  padding: 5px 10px;
  cursor: pointer;
}

.search-result-item:hover {
  background-color: #f0f0f0;
}

/* Add this to the existing styles */
.ai-enhance-container {
  margin-bottom: 20px;
}

/* Add this new style for the time input */
.form-group input[type="time"] {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.trainer-search-container {
  position: relative;
}

.trainer-search-container .search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.trainer-search-container .search-result-item {
  padding: 5px 10px;
  cursor: pointer;
}

.trainer-search-container .search-result-item:hover {
  background-color: #f0f0f0;
}

/* Add these new styles for mobile devices */
@media (max-width: 767px) {
  .edit-sessions-page {
    padding: 10px;
  }

  .search-and-create {
    flex-direction: column;
  }

  .search-input,
  .session-select,
  .create-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .sessions-list {
    grid-template-columns: 1fr;
  }

  .edit-modal {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    padding: 0;
    border-radius: 0;
  }

  :deep(.modal-container) {
    width: 100% !important;
    height: 100% !important;
    max-width: none !important;
    max-height: none !important;
    margin: 0;
    padding: 0;
    border-radius: 0;
  }

  :deep(.modal-content) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  :deep(.modal-body) {
    flex-grow: 1;
    overflow-y: auto;
  }

  .edit-form {
    padding: 0px;
  }

  .session-details {
    grid-template-columns: 1fr;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-group input,
  .form-group select {
    width: 100%;
    padding: 12px;
    font-size: 16px; /* Minimum font size for iOS to prevent zooming */
  }

  .segments-container,
  .records-container {
    padding: 10px;
  }

  .segment,
  .record {
    min-width: unset;
    width: 100%;
  }

  .segment-header,
  .record-header {
    flex-wrap: wrap;
  }

  .record-details {
    flex-direction: column;
    align-items: stretch;
  }

  .exercise-search,
  .place-search {
    width: 100%;
    margin-bottom: 10px;
  }

  .repetitions-input,
  .description-input {
    width: 100%;
    margin-bottom: 10px;
  }

  .map-container {
    height: 300px;
  }

  .save-button,
  .cancel-button {
    width: 100%;
    margin: 10px 0;
    padding: 15px;
    font-size: 18px;
  }

  /* Improve touch targets */
  .remove-button,
  .add-button {
    padding: 10px;
    font-size: 16px;
  }

  .segment-drag-handle,
  .record-drag-handle {
    padding: 10px;
    font-size: 20px;
  }

  /* Adjust CustomSelect component for mobile */
  :deep(.custom-select) {
    width: 100%;
  }

  :deep(.custom-select .selected-option) {
    padding: 12px;
    font-size: 16px;
  }

  :deep(.custom-select .options) {
    max-height: 50vh;
  }

  :deep(.custom-select .option) {
    padding: 12px;
    font-size: 16px;
  }

  /* Adjust AIEnhanceSession component for mobile */
  .ai-enhance-container {
    margin-bottom: 15px;
  }

  /* Adjust search results for mobile */
  .search-results {
    max-height: 50vh;
  }

  .search-result-item {
    padding: 12px;
    font-size: 16px;
  }
}

/* Add this new style for the modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999998; /* Just below the modal */
}

/* Add this new style for the session type select in the toolbar */
.session-type-select {
  margin-right: 10px;
}

/* Remove these styles as they're no longer needed */
/*
.session-type-select {
  margin-right: 10px;
}

:deep(.v-toolbar__items .custom-select .selected-option i) {
  color: white;
  font-size: 24px;
}
*/

/* Update this style for the session type select in the form */
.session-type-select {
  width: 100%;
}

.trainer-type-group {
  margin-bottom: 15px;
}

.trainer-type-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.session-type-select {
  width: 40px;
  flex-shrink: 0;
  align-self: center;
}

.trainer-input-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.trainer-input-container label {
  margin-bottom: 5px;
}

.trainer-search-container {
  position: relative;
  width: 100%;
}

.trainer-search-container input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.workout-specifics {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.workout-input {
  width: 100px;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

@media (max-width: 767px) {
  .workout-specifics {
    flex-direction: column;
  }

  .workout-input {
    width: 100%;
  }
}

.segment {
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 1rem;
  padding: 1rem;
}

.segment h3 {
  margin-top: 0;
}

.record {
  background-color: #f9f9f9;
  border: 1px solid #eee;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
}

.record p {
  margin: 0.25rem 0;
}

.segment-drag-handle,
.record-drag-handle {
  cursor: move;
  padding: 5px;
  color: #999;
}

.segment-drag-handle:hover,
.record-drag-handle:hover {
  color: #333;
}

.sortable-ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.sortable-drag {
  opacity: 0.9;
  background: #f0f0f0;
}

/* Add any necessary styles */
.text-red-500 {
  color: #ef4444;
}

/* Include any additional CSS here */
</style>