<template>
  <div 
    class="custom-select"
    :class="{ 'show-text': showText }"
  >
    <v-menu
      v-model="menu"
      :close-on-content-click="true"
      location="bottom"
    >
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          :color="selectedOption ? selectedOption.color : 'surface-variant'"
          class="selected-option"
          variant="tonal"
        >
          <i :class="selectedOption ? selectedOption.icon : 'fa-duotone fa-question'" />
        </v-btn>
      </template>

      <v-card
        class="options-card"
        color="background"
      >
        <v-list
          bg-color="background"
          class="options-list"
        >
          <v-list-item
            v-for="option in options"
            :key="option.value"
            :value="option.value"
            @click="selectOption(option)"
          >
            <template #prepend>
              <i :class="option.icon" />
            </template>
            <v-list-item-title>
              {{ option.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'CustomSelect',
  props: {
    modelValue: {
      type: [String, Number],
      default: null
    },
    options: {
      type: Array,
      required: true
    },
    showText: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      menu: false
    }
  },
  computed: {
    selectedOption() {
      return this.options.find(option => option.value === this.modelValue)
    }
  },
  methods: {
    selectOption(option) {
      this.$emit('update:modelValue', option.value)
      this.menu = false
    }
  }
}
</script>

<style scoped>
.custom-select {
  display: inline-block;
}

.selected-option {
  min-width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 4px;
}

.selected-option i {
  font-size: 1.2rem;
}

:deep(.v-list-item) {
  min-height: 40px;
  padding: 0 12px;
  cursor: pointer;
}

:deep(.v-list-item:hover) {
  background-color: rgb(var(--v-theme-surface-variant));
}

:deep(.v-list-item--active) {
  background-color: rgb(var(--v-theme-surface-variant));
}

.options-card {
  border-radius: 4px;
  overflow: hidden;
  min-width: 200px;
}

.options-list {
  padding: 4px;
}

:deep(.v-list-item) {
  display: flex;
  align-items: center;
  gap: 12px;
}

:deep(.v-list-item i) {
  font-size: 1.2rem;
  width: 24px;
  text-align: center;
}

:deep(.v-list-item-title) {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgb(var(--v-theme-on-surface));
}

/* FontAwesome icon styles */
i {
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 0.6;
}

/* Ensure proper vertical alignment of icons */
i {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>