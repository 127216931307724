window.onerror = function(message, source, lineno, colno, error) {
    console.error('Global error:', { message, source, lineno, colno, error });
};

const originalConsoleError = console.error;
console.error = (...args) => {
  if (args[0] instanceof Error && args[0].message.includes('ResizeObserver loop')) {
    return;
  }
  originalConsoleError.apply(console, args);
};

import { createApp } from 'vue';
import { VueFire, VueFireAuth } from 'vuefire';
import { createPinia } from 'pinia';
import { firebaseApp } from './firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import { store, useMainStore } from './stores';
import Toast from "vue-toast-notification";
import 'vue-toast-notification/dist/theme-default.css';
import { setupCharts } from './components/charts'
import { getThemeVariables } from '@/config/themes'

const applyTheme = (theme) => {
  const html = document.documentElement
  html.setAttribute('data-theme', theme)
  
  const variables = getThemeVariables(theme)
  Object.entries(variables).forEach(([key, value]) => {
    html.style.setProperty(key, value)
  })
}

// Apply theme immediately before Vue initialization
const initialTheme = localStorage.getItem('theme') || 'system'
applyTheme(initialTheme)

// Create app ONCE
const app = createApp(App)
const pinia = createPinia()

app.use(pinia)
const mainStore = useMainStore()

app.use(VueFire, {
  firebaseApp,
  modules: [
    VueFireAuth(),
  ],
});

app.use(router);
app.use(vuetify);
app.use(store);
app.use(Toast);

// Initialize user from localStorage
mainStore.initializeUserFromLocalStorage();

// Fetch exercises when the app is created
mainStore.fetchExercises();

// Handle authentication state changes
const auth = getAuth(firebaseApp);
const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
  if (firebaseUser) {
    console.log('Firebase user authenticated:', firebaseUser);
    try {
      let userEmail = firebaseUser.email;
      if (!userEmail && firebaseUser.providerData && firebaseUser.providerData.length > 0) {
        userEmail = firebaseUser.providerData[0].email;
      }

      if (!userEmail) {
        console.error('User email is missing from authentication result');
        return;
      }

      const userData = await mainStore.fetchCurrentUser(userEmail);
      if (userData && userData.id) {
        console.log('User data fetched and updated in store:', userData);
      } else {
        console.error('Failed to fetch or update user data');
      }
    } catch (error) {
      console.error('Error fetching user data on auth state change:', error);
    }
  } else {
    console.log('No Firebase user');
    mainStore.setUser(null);
  }
});

// Save the original unmount method
const originalUnmount = app.unmount;

// Override the unmount method
app.unmount = () => {
  unsubscribe();
  originalUnmount.call(app);
};

// Add theme change watcher
if (initialTheme === 'system') {
  const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
  mediaQuery.addEventListener('change', () => {
    applyTheme('system') // This will re-evaluate based on new system preference
  })
}

// Setup charts
setupCharts(app)

// Add error handling for uncaught Vue errors
app.config.errorHandler = (err, instance, info) => {
    console.error('Vue error:', err);
    console.log('Error component:', instance);
    console.log('Error info:', info);
};

// Add warning handler
app.config.warnHandler = (msg, instance, trace) => {
    console.warn('Vue warning:', msg);
    console.log('Warning component:', instance);
    console.log('Warning trace:', trace);
};

// Mount the app
app.mount('#app')
