import DOMPurify from 'dompurify'
import { marked } from 'marked'

export const vSafeHtml = {
  mounted(el, binding) {
    if (binding.value) {
      const cleanHtml = DOMPurify.sanitize(marked(binding.value))
      el.innerHTML = cleanHtml
    }
  },
  updated(el, binding) {
    if (binding.value) {
      const cleanHtml = DOMPurify.sanitize(marked(binding.value))
      el.innerHTML = cleanHtml
    }
  }
} 