<template>
  <div class="training-page">
    <div class="content-container">
      <section class="mb-8">
        <h2 class="text-h4 mb-4">
          Naturens gym
        </h2>
        <p>
          Vi utnyttjar naturen och dess element som vårt gym. Genom att träna utomhus får du inte bara fysiska fördelar, 
          utan också mental återhämtning och energi från den friska luften och vackra omgivningen.
        </p>
      </section>

      <section class="mb-8">
        <h2 class="text-h4 mb-4">
          Varierad och rolig träning
        </h2>
        <p>
          Våra pass varierar från gång till gång för att hålla träningen intressant och utmanande. 
          Vi använder olika träningsmetoder och övningar för att ge dig en allsidig och rolig träningsupplevelse.
        </p>
      </section>

      <section class="mb-8">
        <h2 class="text-h4 mb-4">
          Gemenskap och motivation
        </h2>
        <p>
          Att träna tillsammans med andra ger extra motivation och gör träningen roligare. 
          Hos Banan Outdoor blir du en del av en gemenskap där vi stöttar och peppar varandra till att nå våra mål.
        </p>
      </section>
    </div>
  </div>
</template>

<style scoped>
.training-page {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: var(--background-color);
  color: var(--text-color);
}

.content-container {
  background-color: var(--card-background);
  border-radius: 0.5rem;
  padding: 2rem;
  box-shadow: var(--shadow-sm);
  transition: box-shadow var(--transition-speed) ease;
}

.content-container:hover {
  box-shadow: var(--shadow-md);
}

section {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 2rem;
}

section:last-child {
  border-bottom: none;
}

h2 {
  color: var(--text-color);
  font-family: var(--font-heading);
}

p {
  color: var(--text-secondary-color);
  line-height: 1.6;
}
</style>
