<template>
  <div class="membership-page">
    <h1>Medlemskap i Banan Outdoor</h1>
    <p>Här hittar du information om vårt medlemskap och dess fördelar.</p>
    <h2>Fördelar med medlemskap:</h2>
    <ul>
      <li>Möjlighet att köpa träningskort och därmed få bästa möjliga pris på träningspass</li>
      <li>Möjlighet att påverka föreningens utveckling</li>
    </ul>
    <p>För mer information om hur du blir medlem besök vår prissida.</p>
  </div>
</template>

<script>
export default {
  name: 'MembershipPage'
}
</script>
