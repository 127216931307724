<template>
  <div class="member-list-page">
    <h1>Medlemmar</h1>
    
    <div class="filters">
      <input
        v-model="searchTerm"
        placeholder="Sök medlemmar"
        @input="filterMembers"
      >
      <select
        v-model="sortBy"
        @change="sortMembers"
      >
        <option value="FirstName">
          Sortera efter förnamn
        </option>
        <option value="LastName">
          Sortera efter efternamn
        </option>
        <option value="Email">
          Sortera efter e-post
        </option>
        <option value="membershipEnd">
          Sortera efter medlemskapets slutdatum
        </option>
        <option value="trainingCardEnd">
          Sortera efter träningskortets slutdatum
        </option>
        <option value="CustomerNumber">
          Sortera efter kundnummer
        </option>
      </select>
    </div>

    <v-data-table
      :headers="headers"
      :items="filteredMembers"
      :search="searchTerm"
      :sort-by="[{ key: sortBy, order: sortDesc ? 'desc' : 'asc' }]"
      class="elevation-1"
      @click:row="viewMemberDetails"
    >
      <template #[`item.fullName`]="{ item }">
        <v-avatar
          size="40"
          class="mr-2 member-avatar"
        >
          <img
            v-if="item.profilePicture"
            :src="getProfileImageUrl(item.profilePicture)"
            :alt="item.firstName"
          >
          <v-icon v-else>
            fas fa-user 
          </v-icon>
        </v-avatar>
        {{ item.firstName || 'N/A' }} {{ item.lastName || 'N/A' }}
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialogOpen"
      max-width="800px"
    >
      <v-card v-if="selectedMember">
        <v-toolbar
          flat
          color="primary"
        >
          <v-avatar
            size="40"
            class="mr-2 member-avatar"
          >
            <img
              v-if="selectedMember.profilePicture"
              :src="getProfileImageUrl(selectedMember.profilePicture)"
              :alt="selectedMember.firstName"
            >
            <v-icon v-else>
              fas fa-user 
            </v-icon>
          </v-avatar>
          <v-toolbar-title class="white--text">
            {{ selectedMember.firstName }} {{ selectedMember.lastName }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="closeModal"
          >
            <v-icon 
              icon="fas fa-xmark"
            />
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <!-- Basic Information -->
          <v-card
            outlined
            class="mb-3"
          >
            <v-card-title>Information</v-card-title>
            <v-card-text>
              <v-list dense>
                <v-list-item>
                  <v-list-item-title>
                    <strong>Förnamn:</strong> {{ selectedMember.firstName }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <strong>Efternamn:</strong> {{ selectedMember.lastName }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <strong>E-post:</strong> {{ selectedMember.email }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <strong>Kundnummer:</strong> {{ selectedMember.customerNumber }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <strong>Roll:</strong> {{ getRoleText(selectedMember.role) }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>

          <!-- Editable Basic Information -->
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-title>Redigera information</v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-form @submit.prevent="updateMember">
                  <v-text-field
                    v-model="selectedMember.firstName"
                    label="Förnamn"
                    required
                  />
                  <v-text-field
                    v-model="selectedMember.lastName"
                    label="Efternamn"
                    required
                  />
                  <v-text-field
                    v-model="selectedMember.email"
                    label="E-post"
                    type="email"
                    required
                  />
                  <v-select
                    v-model="selectedMember.role"
                    :items="roleOptions"
                    item-title="text"
                    item-value="value"
                    label="Roll"
                    required
                  />
                  <v-btn
                    type="submit"
                    color="primary"
                  >
                    Uppdatera
                  </v-btn>
                </v-form>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- Address Section -->
          <v-card
            outlined
            class="mt-3"
          >
            <v-card-title>Adress</v-card-title>
            <v-card-text>
              <v-list dense>
                <v-list-item>
                  <v-list-item-title>
                    <strong>Gata:</strong> {{ selectedMember.address?.street || 'N/A' }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <strong>Stad:</strong> {{ selectedMember.address?.city || 'N/A' }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <strong>Postnummer:</strong> {{ selectedMember.address?.zipCode || 'N/A' }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>

          <!-- Editable Address -->
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-title>Redigera adress</v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-form @submit.prevent="updateAddress">
                  <v-text-field
                    v-model="selectedMember.address.street"
                    label="Gata"
                  />
                  <v-text-field
                    v-model="selectedMember.address.city"
                    label="Stad"
                  />
                  <v-text-field
                    v-model="selectedMember.address.zipCode"
                    label="Postnummer"
                  />
                  <v-btn
                    type="submit"
                    color="primary"
                  >
                    Uppdatera adress
                  </v-btn>
                </v-form>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- Memberships Section -->
          <v-card
            outlined
            class="mt-3"
          >
            <v-card-title>Medlemskap</v-card-title>
            <v-card-text>
              <v-list dense>
                <v-list-item
                  v-for="membership in selectedMember.memberships"
                  :key="membership.id"
                >
                  <v-list-item-title>
                    {{ getMembershipTypeText(membership.type) }} - 
                    Från: {{ formatDate(membership.startDate) }} 
                    Till: {{ formatDate(membership.endDate) }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>

          <!-- Add/Edit Membership -->
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-title>Lägg till/Redigera medlemskap</v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-form @submit.prevent="updateMembership">
                  <v-select
                    v-model="newMembership.type"
                    :items="membershipTypeOptions"
                    item-title="text"
                    item-value="value"
                    label="Typ"
                    required
                  >
                    <template #selection="{ item }">
                      {{ item.raw.text }}
                    </template>
                    <template #item="{ item, props }">
                      <v-list-item
                        v-bind="props"
                        :title="item.title"
                      >
                        {{ item.title }}
                      </v-list-item>
                    </template>
                  </v-select>
                  
                  <v-menu
                    ref="startDateMenu"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="formattedStartDate"
                        label="Startdatum"
                        prepend-icon="fas fa-calendar" 
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="newMembership.startDate"
                      @input="startDateMenu = false"
                    />
                  </v-menu>

                  <v-text-field
                    v-model="newMembership.durationMonths"
                    type="number"
                    label="Antal månader"
                    :rules="[v => v > 0 || 'Antal månader måste vara större än 0']"
                    required
                  />

                  <v-text-field
                    :value="computedEndDate"
                    label="Slutdatum"
                    readonly
                  />

                  <v-btn
                    type="submit"
                    color="primary"
                  >
                    Uppdatera medlemskap
                  </v-btn>
                </v-form>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- Payments Section -->
          <v-card
            outlined
            class="mt-3"
          >
            <v-card-title>Betalningar</v-card-title>
            <v-card-text>
              <v-list v-if="selectedMember.payments && selectedMember.payments.length">
                <v-list-item
                  v-for="payment in selectedMember.payments"
                  :key="payment.id"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ payment.name }} - {{ payment.total }} {{ payment.currency }} ({{ formatDate(payment.paymentDate) }})
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      icon
                      @click="viewPaymentDetails(payment)"
                    >
                      <v-icon 
                        icon="fas fa-receipt"
                      />  
                    </v-btn>
                    <v-btn
                      icon
                      @click="deletePayment(payment.id)"
                    >
                      <v-icon 
                        icon="fas fa-trash" 
                        class="text-red-500"
                      />
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <div v-else>
                <p>Inga betalningar hittades.</p>
              </div>
            </v-card-text>
          </v-card>

          <!-- Add Payment Form -->
          <v-card
            outlined
            class="mt-3"
          >
            <v-card-title>Lägg till betalning</v-card-title>
            <v-card-text>
              <v-form @submit.prevent="addPayment">
                <v-text-field
                  v-model="newPayment.Name"
                  label="Namn"
                  required
                />
                <v-text-field
                  v-model="newPayment.Total"
                  label="Belopp"
                  type="number"
                  step="0.01"
                  required
                />
                <v-text-field
                  v-model="newPayment.Currency"
                  label="Valuta"
                  required
                />
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="newPayment.PaymentDate"
                      label="Datum"
                      prepend-icon="fas fa-calendar"  
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="newPayment.PaymentDate"
                    no-title
                    @input="menu = false"
                  />
                </v-menu>
                <v-btn
                  type="submit"
                  color="primary"
                >
                  Lägg till betalning
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Add a new v-dialog for the payment receipt modal -->
    <v-dialog
      v-model="paymentModalOpen"
      max-width="600px"
      :z-index="9999"
    >
      <PaymentReceiptModal
        v-if="selectedPayment"
        :payment="selectedPayment"
        :user="selectedMember"
        @close="closePaymentModal"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed, watch } from 'vue'
import { api } from '@/services/api'
import { getImageUrl } from '@/utils/imageUtils';
import PaymentReceiptModal from '@/components/PaymentReceiptModal.vue'
import { useStore } from 'vuex';
import { useToast } from 'vue-toast-notification';

export default {
  name: 'MemberListPage',
  components: {
    PaymentReceiptModal
  },
  setup() {
    const store = useStore();
    const members = ref([])
    const loading = ref(true)
    const error = ref(null)
    const searchTerm = ref('')
    const sortBy = ref('LastName')
    const sortDesc = ref(false)
    const selectedMember = reactive({});
    const dialogOpen = ref(false)
    const paymentModalOpen = ref(false)
    const newPayment = reactive({
      Name: '',
      Total: 0,
      Currency: 'SEK',
      PaymentDate: new Date().toISOString().split('T')[0]
    })
    const selectedPayment = reactive({});
    const userRole = computed(() => store.getters.userRole);
    const toast = useToast();

    console.log('MemberListPage - User Role:', userRole.value);

    const headers = ref([
      { title: 'Namn', key: 'fullName', sortable: true },
      { title: 'E-post', key: 'email', sortable: true },
      { title: 'Medlemskap slutdatum', key: 'membershipEnd', sortable: true },
      { title: 'Träningskort slutdatum', key: 'trainingCardEnd', sortable: true },
    ])

    const getMembershipEndDate = (member) => {
      const membership = member.memberships?.find(m => m.type === 'membership' && m.status === 'confirmed')
      return membership ? formatDate(membership.endDate) : 'N/A'
    }

    const getTrainingCardEndDate = (member) => {
      const trainingCard = member.memberships?.find(m => m.type === 'trainingcard' && m.status === 'confirmed')
      return trainingCard ? formatDate(trainingCard.endDate) : 'N/A'
    }

    const filteredMembers = computed(() => {
      return members.value.map(member => ({
        ...member,
        fullName: `${member.firstName} ${member.lastName}`,
        membershipEnd: getMembershipEndDate(member),
        trainingCardEnd: getTrainingCardEndDate(member),
      }))
    })

    const fetchMembers = async () => {
      try {
        console.log('Fetching members...');
        const response = await api.getUsers();
        console.log('API response:', response);
        if (Array.isArray(response.data)) {
          members.value = response.data;
        } else if (Array.isArray(response)) {
          members.value = response;
        } else {
          throw new Error('Invalid response format');
        }
        console.log('Members after fetch:', members.value);
        loading.value = false;
      } catch (err) {
        console.error('Error fetching members:', err);
        error.value = 'Failed to load members. Please try again later.';
        loading.value = false;
        members.value = [];
      }
    }

    const changeSortBy = (column) => {
      if (sortBy.value === column.key) {
        sortDesc.value = !sortDesc.value
      } else {
        sortBy.value = column.key
        sortDesc.value = false
      }
    }

    const viewMemberDetails = (event, { item }) => {
      console.log('Selected member (raw):', item);
      if (item && typeof item === 'object') {
        const memberData = {
          id: item.id,
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email,
          customerNumber: item.customerNumber,
          role: item.role, // Make sure to include the role
          address: {
            street: item.address || '',
            city: item.city || '',
            zipCode: item.zipCode || '',
      },
          memberships: (item.memberships || []).map(membership => ({
            ...membership,
            startDate: membership.startDate ? new Date(membership.startDate) : null,
            endDate: membership.endDate ? new Date(membership.endDate) : null,
          })),
          payments: item.payments || [],
          // Add other properties as needed
        };
        console.log('Processed member data:', memberData);
        Object.assign(selectedMember, memberData);
        dialogOpen.value = true;
      } else {
        console.error('Invalid item:', item);
      }
    }

    const closeModal = () => {
      Object.keys(selectedMember).forEach(key => delete selectedMember[key]);
      dialogOpen.value = false;
    }

    const formatDate = (date) => {
      if (!date) return '';
      let dateObj;
      if (typeof date === 'string') {
        dateObj = new Date(date);
      } else if (date instanceof Date) {
        dateObj = date;
      } else {
        return 'Invalid Date';
      }
      if (isNaN(dateObj.getTime())) return 'Invalid Date';
      return dateObj.toLocaleDateString('sv-SE');
    }

    const updateMember = async () => {
      try {
        // Create an object with only the properties that have changed
        const updatedFields = {};
        const originalMember = members.value.find(m => m.id === selectedMember.id);

        for (const key in selectedMember) {
          if (selectedMember[key] !== originalMember[key]) {
            updatedFields[key] = selectedMember[key];
          }
        }

        // Only send the request if there are changes
        if (Object.keys(updatedFields).length > 0) {
          await api.updateUser(selectedMember.id, updatedFields);
          
          // Update the member in the list
          const index = members.value.findIndex(m => m.id === selectedMember.id);
          if (index !== -1) {
            members.value[index] = { ...members.value[index], ...updatedFields };
          }
          
          // Show success toast
          toast.success('Medlemmen uppdaterades framgångsrikt');
        } else {
          // Show info toast
          toast.info('Inga ändringar att uppdatera');
        }
        closeModal();
      } catch (err) {
        console.error('Error updating member:', err);
        // Show error toast
        toast.error('Kunde inte uppdatera medlemmen. Försök igen.');
      }
    }

    const addPayment = async () => {
      try {
        const response = await api.addUserPayment(selectedMember.id, newPayment)
        if (!selectedMember.payments) {
          selectedMember.payments = []
        }
        selectedMember.payments.push(response.data)
        // Reset the newPayment form
        Object.assign(newPayment, {
          Name: '',
          Total: 0,
          Currency: 'SEK',
          PaymentDate: new Date().toISOString().split('T')[0]
        });
      } catch (err) {
        console.error('Error adding payment:', err)
        error.value = 'Failed to add payment. Please try again.'
      }
    }

    const deletePayment = async (paymentId) => {
      try {
        await api.deletePayment(selectedMember.id, paymentId)
        selectedMember.payments = selectedMember.payments.filter(p => p.id !== paymentId)
      } catch (err) {
        console.error('Error deleting payment:', err)
        error.value = 'Failed to delete payment. Please try again.'
      }
    }

    const getProfileImageUrl = (imagePath) => {
      return getImageUrl(imagePath) || '/default-avatar.webp';
    };

    const viewPaymentDetails = (payment) => {
      console.log('Viewing payment details:', payment);
      console.log('Selected member:', selectedMember);
      
      // Create a new object with the correct property names
      const formattedPayment = {
        invoiceId: payment.InvoiceId,
        paymentDate: payment.PaymentDate,
        name: payment.Name,
        total: payment.Total,
        currency: payment.Currency,
        vatPercentage: payment.VatPercentage,
        vat: payment.Vat,
        type: payment.Type,
        validFrom: payment.ValidFrom,
        validTo: payment.ValidTo,
        description: payment.Description // Add this if it exists in your payment object
      };

      // Use Object.assign instead of reassigning selectedPayment
      Object.assign(selectedPayment, formattedPayment);
      paymentModalOpen.value = true;
    }

    const closePaymentModal = () => {
      console.log('Closing payment modal');
      // Reset selectedPayment without reassigning
      Object.keys(selectedPayment).forEach(key => delete selectedPayment[key]);
      paymentModalOpen.value = false;
    }

    const roleOptions = [
      { value: 'registered', text: 'Registrerad' },
      { value: 'member', text: 'Medlem' },
      { value: 'staff', text: 'Personal' },
    ]

    const membershipTypeOptions = [
      { value: 'member', text: 'Medlem' },
      { value: 'trainingCard', text: 'Träningskort' },
    ]

    const newMembership = reactive({
      type: membershipTypeOptions[0].value,
      durationMonths: 12,
      startDate: new Date().toISOString().split('T')[0] // This ensures a valid date string
    });

    const getRoleText = (role) => {
      return roleOptions.find(option => option.value === role)?.text || role
    }

    const getMembershipTypeText = (type) => {
      return membershipTypeOptions.find(option => option.value === type)?.text || type
    }

    const getAddressFieldLabel = (key) => {
      const labels = {
        street: 'Gata',
        city: 'Stad',
        zipCode: 'Postnummer',
      }
      return labels[key] || key
    }

    const updateAddress = async () => {
      try {
        const originalMember = members.value.find(m => m.id === selectedMember.id);
        const updatedFields = {};

        if (selectedMember.address.street !== originalMember.address?.street) {
          updatedFields.address = selectedMember.address.street;
        }
        if (selectedMember.address.city !== originalMember.city) {
          updatedFields.city = selectedMember.address.city;
        }
        if (selectedMember.address.zipCode !== originalMember.zipCode) {
          updatedFields.zipCode = selectedMember.address.zipCode;
        }

        if (Object.keys(updatedFields).length > 0) {
          await api.updateUserAddress(selectedMember.id, updatedFields);
          
          // Update the address in the list
          const index = members.value.findIndex(m => m.id === selectedMember.id);
          if (index !== -1) {
            members.value[index] = { ...members.value[index], ...updatedFields };
          }
          
          toast.success('Adress uppdaterad framgångsrikt');
        } else {
          toast.info('Inga ändringar att uppdatera för adressen');
        }
      } catch (err) {
        console.error('Error updating address:', err);
        toast.error('Kunde inte uppdatera adressen. Försök igen.');
      }
    }

    const updateMembership = async () => {
      try {
        const startDate = new Date(newMembership.startDate);
        if (isNaN(startDate.getTime())) throw new Error('Invalid start date');
        const endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + parseInt(newMembership.durationMonths));
        
        const updatedMembership = await api.updateUserMembership(selectedMember.id, {
          type: newMembership.type,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        });
        
        // Update or add the membership in the list
        const index = selectedMember.memberships.findIndex(m => m.id === updatedMembership.id);
        if (index !== -1) {
          selectedMember.memberships[index] = updatedMembership;
        } else {
          selectedMember.memberships.push(updatedMembership);
        }
        
        // Reset the newMembership form
        Object.assign(newMembership, {
          type: membershipTypeOptions[0].value,
          durationMonths: 12,
          startDate: new Date().toISOString().split('T')[0]
        });
      } catch (err) {
        console.error('Error updating membership:', err);
        error.value = 'Failed to update membership. Please try again.';
      }
    }

    const logMembershipType = (value) => {
      console.log('Membership type changed:', value);
      console.log('Current newMembership:', newMembership);
      console.log('Selected option:', membershipTypeOptions.find(option => option.value === value));
    };

    const computedEndDate = computed(() => {
      if (!newMembership.startDate || !newMembership.durationMonths) return '';
      const startDate = new Date(newMembership.startDate);
      if (isNaN(startDate.getTime())) return 'Invalid Date';
      const endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + parseInt(newMembership.durationMonths));
      return formatDate(endDate);
    });

    const startDateMenu = ref(false)

    const formattedStartDate = computed(() => {
      return formatDate(newMembership.startDate);
    });

    onMounted(fetchMembers)

    watch(members, (newValue) => {
      console.log('Members changed:', newValue);
    }, { deep: true });

    console.log('membershipTypeOptions:', membershipTypeOptions);
    console.log('newMembership.type:', newMembership.type);

    return {
      members,
      filteredMembers,
      loading,
      error,
      searchTerm,
      sortBy,
      sortDesc,
      selectedMember,
      dialogOpen,
      paymentModalOpen,
      newPayment,
      selectedPayment,
      headers,
      changeSortBy,
      viewMemberDetails,
      closeModal,
      formatDate,
      getMembershipEndDate,
      getTrainingCardEndDate,
      updateMember,
      addPayment,
      deletePayment,
      getProfileImageUrl,
      viewPaymentDetails,
      closePaymentModal,
      userRole,
      roleOptions,
      membershipTypeOptions,
      newMembership,
      getRoleText,
      getMembershipTypeText,
      getAddressFieldLabel,
      updateAddress,
      updateMembership,
      logMembershipType,
      computedEndDate,
      startDateMenu,
      formattedStartDate,
      toast,
    }
  }
}
</script>

<style scoped>
.member-list-page {
  padding: 20px;
}

.filters {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}

.member-list table {
  width: 100%;
  border-collapse: collapse;
}

.member-list th, .member-list td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.member-list th {
  background-color: #f2f2f2;
}

.member-list tr:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.member-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.member-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.member-detail-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}
</style>