import { createRouter, createWebHistory } from 'vue-router'
import { h } from 'vue'
import AdminPage from '@/views/AdminPage.vue'
import EditPlacesPage from '@/views/EditPlacesPage.vue'
import EditExercisesPage from '@/views/EditExercisesPage.vue'
import EditEquipmentPage from '@/views/EditEquipmentPage.vue'
import EditSessionsPage from '@/views/EditSessionsPage.vue'
import LoginPage from '@/views/LoginPage.vue'
import { getAuth } from 'firebase/auth'
import { useMainStore } from '@/stores'
import NewPostPage from '@/views/NewPostPage.vue'
import AdminStatisticsPage from '@/views/AdminStatisticsPage.vue'
import ManagePaymentsPage from '@/views/ManagePaymentsPage.vue'
import MemberListPage from '@/views/MemberListPage.vue'
import AboutUsPage from '@/views/AboutUsPage.vue'
import BoardPage from '@/views/BoardPage.vue'
import ContactPage from '@/views/ContactPage.vue'
import DocumentsPage from '@/views/DocumentsPage.vue'
import MembershipPage from '@/views/MembershipPage.vue'
import NewsPage from '@/views/NewsPage.vue'
import PoliciesPage from '@/views/PoliciesPage.vue'
import PricesPage from '@/views/PricesPage.vue'
import ProtocolsPage from '@/views/ProtocolsPage.vue'
import SocialMediaPage from '@/views/SocialMediaPage.vue'
import StatutesPage from '@/views/StatutesPage.vue'
import TrainingPage from '@/views/TrainingPage.vue'
import metaDescriptions from '@/meta-descriptions'

const PlaceholderComponent = {
  render: () => h('div', 'Loading...')
}

const loadComponent = (name) => {
  return () => import(`../views/${name}.vue`).catch(() => PlaceholderComponent)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: loadComponent('HomePage'),
    meta: {
      description: metaDescriptions.Home
    }
  },
  {
    path: '/schedule',
    name: 'Schedule',
    component: loadComponent('SchedulePage')
  },
  {
    path: '/sessions',
    name: 'Sessions',
    component: () => import('@/views/SessionsPage.vue'),
  },
  {
    path: '/sessions/:sessionId',
    name: 'SessionDetails',
    component: loadComponent('SessionsPage'),
    props: true
  },
  {
    path: '/exercises/:exerciseId?',
    name: 'Exercises',
    component: () => import('@/views/ExercisesPage.vue'),
  },
  {
    path: '/equipment/:equipmentId?',
    name: 'Equipment',
    component: loadComponent('EquipmentPage'),
  },
  {
    path: '/places/:placeId?',
    name: 'Places',
    component: loadComponent('PlacesPage')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: loadComponent('ProfilePage')
  },
  {
    path: '/members',
    name: 'Members',
    component: MemberListPage,
    meta: { requiresAuth: true, requiresStaff: true }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminPage,
    meta: { requiresAuth: true, requiresStaff: true }
  },
  {
    path: '/admin/statistics',
    name: 'AdminStatistics',
    component: AdminStatisticsPage,
    meta: { requiresAuth: true, requiresStaff: true }
  },
  {
    path: '/admin/edit-places',
    name: 'EditPlaces',
    component: EditPlacesPage,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/edit-exercises',
    name: 'EditExercises',
    component: EditExercisesPage,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/edit-equipment',
    name: 'EditEquipment',
    component: EditEquipmentPage,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/edit-sessions',
    name: 'EditSessions',
    component: EditSessionsPage,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/admin/new-post',
    name: 'AdminNewPost',
    component: NewPostPage,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/manage-posts',
    name: 'AdminManagePosts',
    component: loadComponent('ManagePostsPage'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/manage-payments',
    name: 'ManagePayments',
    component: ManagePaymentsPage,
    meta: { requiresAuth: true, requiresStaff: true }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/RegisterPage.vue'),
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUsPage
  },
  {
    path: '/board',
    name: 'Board',
    component: BoardPage
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactPage
  },
  {
    path: '/documents',
    name: 'Documents',
    component: DocumentsPage
  },
  {
    path: '/membership',
    name: 'Membership',
    component: MembershipPage
  },
  {
    path: '/news',
    name: 'News',
    component: NewsPage
  },
  {
    path: '/policies',
    name: 'Policies',
    component: PoliciesPage
  },
  {
    path: '/prices',
    name: 'Prices',
    component: PricesPage
  },
  {
    path: '/protocols',
    name: 'Protocols',
    component: ProtocolsPage
  },
  {
    path: '/social-media',
    name: 'SocialMedia',
    component: SocialMediaPage
  },
  {
    path: '/statutes',
    name: 'Statutes',
    component: StatutesPage
  },
  {
    path: '/training',
    name: 'Training',
    component: TrainingPage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Implement getCurrentUser function
const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const removeListener = getAuth().onAuthStateChanged(
      (user) => {
        removeListener();
        resolve(user);
      },
      reject
    );
  });
};

router.beforeEach(async (to, from, next) => {
  const store = useMainStore();
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresStaff = to.matched.some(record => record.meta.requiresStaff);
  const currentUser = await getCurrentUser();

  console.log('Route:', to.name);
  console.log('Requires auth:', requiresAuth);
  console.log('Requires staff:', requiresStaff);
  console.log('User:', currentUser);

  if (currentUser) {
    if (!store.user) {
      try {
        await store.fetchCurrentUser(currentUser.email);
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    }
    
    const isStaff = store.isStaff;
    const isAuthenticated = true;

    console.log('Is staff:', isStaff);
    console.log('Is authenticated:', isAuthenticated);

    if (requiresAuth && !isAuthenticated) {
      next('/login');
    } else if (requiresStaff && !isStaff) {
      console.log('Access denied, redirecting to Home');
      next({ name: 'Home' });
    } else {
      next();
    }
  } else {
    if (requiresAuth) {
      next('/login');
    } else {
      next();
    }
  }
});

export default router
