<template>
  <div class="new-post-page">
    <h1>Skapa nytt inlägg</h1>
    <form @submit.prevent="submitPost">
      <div class="form-group">
        <label for="content">Innehåll:</label>
        <textarea
          id="content"
          v-model="post.content"
          required
        />
      </div>
      <div class="form-group">
        <label for="messageType">Meddelandetyp:</label>
        <select
          id="messageType"
          v-model="post.messageType"
          required
        >
          <option value="Text">
            Text
          </option>
          <option value="Image">
            Bild
          </option>
          <option value="Video">
            Video
          </option>
          <option value="Audio">
            Ljud
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="audienceType">Målgrupp:</label>
        <select
          id="audienceType"
          v-model="post.audience.type"
          required
        >
          <option value="Public">
            Offentlig
          </option>
          <option value="Private">
            Privat
          </option>
          <option value="Group">
            Grupp
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="channels">Kanaler:</label>
        <select
          id="channels"
          v-model="post.channels"
          multiple
        >
          <option value="Internal">
            Intern
          </option>
          <option value="Facebook">
            Facebook
          </option>
          <option value="Twitter">
            Twitter
          </option>
          <option value="Instagram">
            Instagram
          </option>
          <option value="Email">
            E-post
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="status">Status:</label>
        <select
          id="status"
          v-model="post.status"
          required
        >
          <option value="Draft">
            Utkast
          </option>
          <option value="Scheduled">
            Schemalagd
          </option>
          <option value="Published">
            Publicerad
          </option>
          <option value="Archived">
            Arkiverad
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="publishAt">Publicera vid:</label>
        <input
          id="publishAt"
          v-model="publishAtLocal"
          type="datetime-local"
        >
      </div>
      <button
        type="submit"
        :disabled="isSubmitting"
      >
        {{ isSubmitting ? 'Skapar inlägg...' : 'Skapa inlägg' }}
      </button>
    </form>
    <div
      v-if="errorMessage"
      class="error-message"
    >
      {{ errorMessage }}
    </div>
    <div
      v-if="successMessage"
      class="success-message"
    >
      {{ successMessage }}
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { api } from '@/services/api';
import { useMainStore } from '@/stores';

export default {
  name: 'NewPostPage',
  setup() {
    const store = useMainStore();
    const publishAtLocal = ref('');
    const post = ref({
      content: '',
      messageType: 'Text',
      audience: {
        type: 'Public',
        recipients: []
      },
      channels: ['Internal'],
      senderId: store.user?.id || '',
      senderOnBehalfOf: store.user?.name || '',
      senderRole: store.user?.role || 'User',
      status: 'Draft',
      attachments: [],
      metadata: {},
      associatedEntities: []
    });
    const isSubmitting = ref(false);
    const errorMessage = ref('');
    const successMessage = ref('');

    const submitPost = async () => {
      isSubmitting.value = true;
      errorMessage.value = '';
      successMessage.value = '';

      const postData = { ...post.value };
      if (publishAtLocal.value) {
        postData.publishAt = new Date(publishAtLocal.value).toISOString();
      } else {
        postData.publishAt = null;
      }

      // Log the exact JSON message that will be sent
      console.log('Sending post data:', JSON.stringify(postData, null, 2));

      try {
        const response = await api.createMessage(postData);
        console.log('Post created:', response.data);
        successMessage.value = 'Inlägget har skapats framgångsrikt!';
        resetForm();
      } catch (error) {
        console.error('Error creating post:', error);
        errorMessage.value = 'Ett fel uppstod när inlägget skulle skapas. Vänligen försök igen senare.';
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
        }
      } finally {
        isSubmitting.value = false;
      }
    };

    const resetForm = () => {
      post.value = {
        content: '',
        messageType: 'Text',
        audience: {
          type: 'Public',
          recipients: []
        },
        channels: ['Internal'],
        senderId: store.user?.id || '',
        senderOnBehalfOf: store.user?.name || '',
        senderRole: store.user?.role || 'User',
        status: 'Draft',
        attachments: [],
        metadata: {},
        associatedEntities: []
      };
      publishAtLocal.value = '';
    };

    return {
      post,
      publishAtLocal,
      isSubmitting,
      errorMessage,
      successMessage,
      submitPost
    };
  }
};
</script>

<style scoped>
.new-post-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

textarea {
  height: 200px;
}

button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover:not(:disabled) {
  background-color: #45a049;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error-message {
  color: #ff0000;
  margin-top: 10px;
}

.success-message {
  color: #008000;
  margin-top: 10px;
}

select, input[type="datetime-local"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

select[multiple] {
  height: auto;
  min-height: 100px;
}
</style>