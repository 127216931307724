module.exports = {
  Home: 'Världens vackraste gym?',
  Schedule: 'Se vårt träningsschema på BananOutdoor',
  Sessions: 'Bläddra och boka träningspass på BananOutdoor',
  Exercises: 'Utforska vårt utbud av övningar på BananOutdoor',
  Equipment: 'Upptäck utrustningen vi använder på BananOutdoor',
  Places: 'Utforska våra träningsplatser på BananOutdoor',
  Profile: 'Hantera din BananOutdoor-profil',
  AboutUs: 'Lär dig mer om BananOutdoor och vårt uppdrag',
  Training: 'Upptäck vår träningsfilosofi på BananOutdoor',
  // Add more routes and their descriptions here
  Membership: 'Information om medlemskap på BananOutdoor',
  Prices: 'Se våra priser för träning på BananOutdoor',
  News: 'Senaste nytt från BananOutdoor',
  Contact: 'Kontakta oss på BananOutdoor',
  Board: 'Möt styrelsen för BananOutdoor',
  Documents: 'Viktiga dokument för BananOutdoor-medlemmar',
  Policies: 'Våra policyer på BananOutdoor',
  Protocols: 'Mötesprotokoll för BananOutdoor',
  SocialMedia: 'Följ BananOutdoor på sociala medier',
  Statutes: 'BananOutdoors stadgar och regler'
}
