<template>
  <ErrorBoundary>
    <v-dialog
      v-model="showModalComputed"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      :retain-focus="false"
      class="edit-session-dialog"
      theme="dark"
    >
      <v-card
        v-if="currentSessionComputed || isCreatingNew"
        class="edit-modal fill-height"
        color="background"
      >
        <v-toolbar
          color="surface-variant"
          class="edit-modal-toolbar"
        >
          <v-btn
            icon
            variant="text"
            @click="closeModal"
          >
            <v-icon>$close</v-icon>
          </v-btn>
          <v-toolbar-title class="text-primary">
            {{ isEditingComputed ? 'Redigera pass' : 'Skapa nytt pass' }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              variant="text"
              color="primary"
              @click="saveSession"
            >
              Spara
            </v-btn>
            <v-btn
              v-if="isEditingComputed"
              icon
              variant="text"
              color="error"
              @click="confirmDelete"
            >
              <v-icon>$delete</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        
        <v-card-text class="edit-modal-content pa-4">
          <form
            class="edit-form"
            @submit.prevent="saveSession"
          >
            <!-- Session details -->
            <div class="session-details">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="sessionDate"
                    type="date"
                    label="Datum"
                    required
                    variant="outlined"
                    density="comfortable"
                    bg-color="surface"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="sessionTime"
                    type="time"
                    label="Tid"
                    required
                    variant="outlined"
                    density="comfortable"
                    bg-color="surface"
                  />
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col cols="auto">
                  <CustomSelect
                    v-model="currentSessionComputed.type"
                    :options="sessionTypesComputed"
                    :show-text="false"
                    class="session-type-select"
                  />
                </v-col>
                <v-col>
                  <v-autocomplete
                    v-model="selectedTrainer"
                    :items="formattedTrainers"
                    item-title="name"
                    item-value="id"
                    label="Tränare"
                    placeholder="Välj tränare"
                    variant="outlined"
                    density="comfortable"
                    bg-color="surface"
                    return-object
                    @update:model-value="updateTrainer"
                  >
                    <template #item="{ item, props }">
                      <v-list-item
                        v-bind="props"
                        :title="item.raw.name"
                      />
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </div>

            <!-- Segments -->
            <div class="segments-container">
              <draggable
                v-model="currentSessionComputed.segments"
                item-key="id"
                handle=".segment-drag-handle"
              >
                <template #item="{ element: segment }">
                  <div class="segment">
                    <div class="segment-header">
                      <v-icon class="segment-drag-handle">$drag</v-icon>
                      <input
                        v-model="segment.label"
                        placeholder="Segment label"
                      >
                      <CustomSelect
                        v-model="segment.type"
                        :options="segmentTypes"
                        @update:model-value="updateSegmentType($event, segment)"
                      />
                      <v-btn
                        icon
                        variant="tonal"
                        color="error"
                        size="small"
                        class="delete-btn"
                        @click="removeSegment(segment)"
                      >
                        <v-icon>$delete</v-icon>
                      </v-btn>
                    </div>
                    
                    <!-- Records within segment -->
                    <div class="records-container">
                      <draggable
                        v-model="segment.records"
                        item-key="id"
                        handle=".record-drag-handle"
                      >
                        <template #item="{ element: record }">
                          <div class="record">
                            <v-row
                              align="center"
                              class="record-header"
                            >
                              <v-col
                                cols="auto"
                                class="d-flex align-center"
                              >
                                <v-icon class="record-drag-handle">$drag</v-icon>
                              </v-col>
                              <v-col>
                                <v-autocomplete
                                  v-model="record.exerciseId"
                                  :items="formattedExercises"
                                  item-title="name"
                                  item-value="id"
                                  label="Exercise"
                                  placeholder="Select exercise"
                                  @update:search="(val) => { 
                                    console.log('Search term updated:', val)
                                    record.exerciseName = val 
                                  }"
                                  @update:model-value="updateExercise(record)"
                                >
                                  <template #no-data>
                                    <v-list-item>
                                      <v-list-item-title>
                                        Ingen övning hittad
                                      </v-list-item-title>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click.stop="openNewExerciseDialog(record)"
                                      >
                                        Skapa ny övning "{{ record.exerciseName }}"
                                      </v-btn>
                                    </v-list-item>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col>
                                <v-text-field
                                  v-model.number="record.repetitions"
                                  label="Repetitions"
                                  type="number"
                                />
                              </v-col>
                              <v-col>
                                <v-text-field
                                  v-model="record.description"
                                  label="Description"
                                  placeholder="Add description"
                                />
                              </v-col>
                              <v-col>
                                <v-autocomplete
                                  v-model="record.placeId"
                                  :items="formattedPlaces"
                                  item-title="name"
                                  item-value="id"
                                  label="Place"
                                  placeholder="Select place"
                                  @update:model-value="updatePlace(record)"
                                />
                              </v-col>
                              <v-col cols="auto">
                                <v-btn
                                  icon
                                  variant="tonal"
                                  color="error"
                                  size="small"
                                  class="delete-btn"
                                  @click="removeRecord(segment, record)"
                                >
                                  <v-icon>$delete</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </div>
                        </template>
                      </draggable>
                      <v-btn
                        text
                        @click="addRecord(segment)"
                      >
                        Lägg Till Övning
                      </v-btn>
                    </div>
                  </div>
                </template>
              </draggable>
              <v-btn @click="addSegment">
                Lägg till avsnitt
              </v-btn>
            </div>

            <!-- Add this section at the bottom of the form -->
            <div class="map-container">
              <div class="session-stats">
                <p><strong>Totalt avstånd:</strong> {{ totalDistance.toFixed(2) }} meter</p>
                <p><strong>Höjdmeter:</strong> {{ totalAscent.toFixed(2) }} meter</p>
                <v-btn
                  icon
                  small
                  @click="refreshMap"
                >
                  <v-icon>$refresh</v-icon>
                </v-btn>
              </div>
              <div
                v-if="mapLoadError"
                class="map-error"
              >
                <p>Kunde inte ladda kartan. Vänligen försök igen senare.</p>
                <v-btn @click="retryLoadMap">
                  Försök igen
                </v-btn>
              </div>
              <div
                v-else
                id="session-map"
                ref="mapContainer"
                :style="{ height: '400px', width: '100%', opacity: mapLoading ? 0.5 : 1 }"
              />
              <div
                v-if="mapLoading"
                class="map-loading-overlay"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                />
              </div>
            </div>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Add this confirmation dialog -->
    <v-dialog
      v-model="showDeleteConfirmation"
      max-width="300"
    >
      <v-card>
        <v-card-title class="headline">
          Bekräfta borttagning
        </v-card-title>
        <v-card-text>Är du säker på att du vill ta bort detta pass?</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="showDeleteConfirmation = false"
          >
            Avbryt
          </v-btn>
          <v-btn
            icon
            @click="deleteSession"
          >
            <v-icon color="error">$delete</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add this dialog for creating a new exercise -->
    <v-dialog
      v-model="showNewExerciseDialog"
      max-width="90%"
      persistent
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between align-center">
          Lägg till ny övning
          <v-btn
            icon
            @click="closeNewExerciseDialog"
          >
            <v-icon>$close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <EditExercisesPage
            ref="editExercisesPageRef"
            :is-dialog="true"
            :initial-data="{
              name: currentRecord?.exerciseName || '',
              description: '',
              complexityRating: 1,
              intensityRating: 1,
              muscleGroups: [],
              muscleGroupIntensities: [],
              equipment: null,
              imageUrl: [],
              media: []
            }"
            @exercise-saved="handleNewExerciseSaved"
            @cancel="closeNewExerciseDialog"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </ErrorBoundary>
</template>

<script>
import { ref, computed, onMounted, watch, nextTick, onUnmounted } from 'vue'
import { useToast } from 'vue-toastification'
import CustomSelect from '@/components/CustomSelect.vue'
import api from '@/services/api'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import ErrorBoundary from '@/components/ErrorBoundary.vue'
import draggable from 'vuedraggable'
import EditExercisesPage from '@/views/EditExercisesPage.vue'
import { useMainStore } from '@/stores'
import { storeToRefs } from 'pinia'
import { format, parseISO, set, isWeekend } from 'date-fns'

export default {
  name: 'EditSessionModal',
  components: {
    CustomSelect,
    ErrorBoundary,
    draggable,
    EditExercisesPage,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    currentSession: {
      type: Object,
      required: true,
      default: () => ({
        segments: []
      })
    },
    sessionTypes: {
      type: Object,
      required: true,
    },
    fetchSessions: {
      type: Function,
      default: () => {}
    },
    trainers: {
      type: Array,
      required: true
    },
    recordTypeOptions: {
      type: Object,
      required: true,
    },
    isCreatingNew: {
      type: Boolean,
      required: true
    },
    places: {
      type: Array,
      required: true
    }
  },
  emits: ['update:showModal', 'update:currentSession', 'update:places', 'save'],
  setup(props, { emit }) {
    const toast = useToast()
    const store = useMainStore()
    const { exercises: storeExercises } = storeToRefs(store)
    const trainers = ref([])
    const exercises = ref([])
    const selectedTrainer = ref(null)
    
    // Add this for delete confirmation
    const showDeleteConfirmation = ref(false)

    const formattedTrainers = computed(() => {
      return props.trainers
        .map(user => ({
          id: user.id,
          name: `${user.firstName} ${user.lastName}`
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
    })

    const formattedExercises = computed(() => {
      return storeExercises.value.map(exercise => ({
        id: exercise.id,
        name: exercise.name
      })).sort((a, b) => a.name.localeCompare(b.name))
    })

    const formattedPlaces = computed(() => {
      return props.places.map(place => ({
        id: place.id,
        name: place.name
      })).sort((a, b) => a.name.localeCompare(b.name))
    })

    const showModalComputed = computed({
      get: () => props.showModal,
      set: (value) => {
        console.log('Setting showModal to:', value)
        emit('update:showModal', value)
      }
    })

    const currentSessionComputed = computed({
      get: () => {
        console.log('Getting currentSessionComputed:', JSON.stringify(props.currentSession, null, 2))
        return props.currentSession
      },
      set: (value) => {
        console.log('Setting currentSessionComputed:', JSON.stringify(value, null, 2))
        emit('update:currentSession', value)
      }
    })

    const sessionTypesComputed = computed(() => Object.entries(props.sessionTypes).map(([value, { name, icon }]) => ({
      value: parseInt(value),
      name,
      icon
    })))

    const isEditingComputed = computed(() => !props.isCreatingNew && !!currentSessionComputed.value.id)

    const sessionDate = computed({
      get: () => {
        if (currentSessionComputed.value && currentSessionComputed.value.date) {
          return currentSessionComputed.value.date.split('T')[0]
        }
        return new Date().toISOString().split('T')[0] // Default to today's date
      },
      set: (value) => {
        if (currentSessionComputed.value) {
          const time = currentSessionComputed.value.date 
            ? currentSessionComputed.value.date.split('T')[1] 
            : '00:00:00'
          currentSessionComputed.value.date = `${value}T${time}`
        }
      }
    })

    const sessionTime = computed({
      get: () => {
        if (currentSessionComputed.value && currentSessionComputed.value.date) {
          return currentSessionComputed.value.date.split('T')[1].substring(0, 5)
        }
        return '00:00' // Default time
      },
      set: (value) => {
        if (currentSessionComputed.value) {
          const date = currentSessionComputed.value.date 
            ? currentSessionComputed.value.date.split('T')[0] 
            : new Date().toISOString().split('T')[0]
          currentSessionComputed.value.date = `${date}T${value}:00`
        }
      }
    })

    // Update the segmentTypes computed property
    const segmentTypes = computed(() => {
      const types = [
        { value: '0', name: 'Slinga', icon: 'fad fa-route' },
        { value: '11', name: 'Cirkelträning', icon: 'fad fa-circle-notch' },
        { value: '3', name: 'Tabata', icon: 'fad fa-stopwatch' },
        { value: '1', name: 'Stege', icon: 'fad fa-stairs' },
        { value: '2', name: 'Pyramid', icon: 'fad fa-triangle' },
        { value: '4', name: 'AMRAP', icon: 'fad fa-infinity' },
        { value: '5', name: 'EMOM', icon: 'fad fa-clock' },
        { value: '6', name: 'På tid', icon: 'fad fa-hourglass-half' },
        { value: '7', name: 'Chipper', icon: 'fad fa-list-ol' },
        { value: '8', name: 'Hero WOD', icon: 'fad fa-medal' },
        { value: '9', name: 'Styrka', icon: 'fad fa-dumbbell' },
        { value: '10', name: 'Teknik', icon: 'fad fa-bullseye-arrow' },
        { value: '12', name: 'Intervalltrning', icon: 'fad fa-wave-square' }
      ]


      // Log the types for debugging
      console.log('Segment types:', types)
      return types
    })

    // Update the recordTypeOptionsFormatted to be a computed property
    const recordTypeOptionsFormatted = computed(() => 
      Object.entries(props.recordTypeOptions).map(([value, { name, icon }]) => ({
        value,
        name,
        icon
      }))
    )

    const fetchTrainers = async () => {
      try {
        const response = await api.getUsers()
        trainers.value = response.data
      } catch (error) {
        console.error('Error fetching trainers:', error)
      }
    }

    const fetchExercises = async () => {
      try {
        const response = await api.getExercises()
        exercises.value = response.data
        console.log('Fetched exercises:', exercises.value)
      } catch (error) {
        console.error('Error fetching exercises:', error)
      }
    }

    const placesLoaded = ref(false)

    const fetchPlaces = async () => {
      try {
        const response = await api.getPlaces()
        emit('update:places', response.data)
        verifyPlacesData()
      } catch (error) {
        console.error('Error fetching places:', error)
        toast.error('Kunde inte hämta platser. Försök igen.')
      }
    }

    const verifyPlacesData = () => {
      console.log('Verifying places data...')
      props.places.forEach((place, index) => {
        if (!place.id || !place.name || !place.location || !Array.isArray(place.location.coordinates)) {
          console.warn(`Invalid place data at index ${index}:`, place)
        } else {
          const [lng, lat] = place.location.coordinates
          if (isNaN(lat) || isNaN(lng)) {
            console.warn(`Invalid coordinates for place ${place.name}: [${lat}, ${lng}]`)
          }
        }
      })
      console.log('Places data verification complete')
    }

    onMounted(async () => {
      fetchTrainers()
      fetchExercises()
      initializeRecordTypes()
      if (!currentSessionComputed.value.date) {
        currentSessionComputed.value.date = new Date().toISOString()
      }
      await fetchPlaces()
      placesLoaded.value = true
      console.log('onMounted: Calling updateMap')
      await nextTick()
      updateMap()
    })

    const updateTrainer = (trainer) => {
      if (trainer) {
        emit('update:currentSession', {
          ...props.currentSession,
          trainer: trainer.id,
          trainerName: trainer.name
        })
      } else {
        emit('update:currentSession', {
          ...props.currentSession,
          trainer: null,
          trainerName: ''
        })
      }
    }

    watch(() => props.currentSession.trainer, (newTrainerId) => {
      if (newTrainerId) {
        const trainer = formattedTrainers.value.find(t => t.id === newTrainerId)
        if (trainer) {
          selectedTrainer.value = trainer
        }
      } else {
        selectedTrainer.value = null
      }
    }, { immediate: true })

    // Add the formatDate function
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleDateString('sv-SE', { year: 'numeric', month: '2-digit', day: '2-digit' });
    }

    const validateSession = (session) => {
      if (!session) {
        console.error('Invalid session data')
        return false
      }
      if (!Array.isArray(session.segments)) {
        console.error('Session segments is not an array')
        return false
      }
      return true
    }

    const saveSession = async () => {
      try {
        console.log('Current session before saving:', JSON.stringify(currentSessionComputed.value, null, 2));

        if (!currentSessionComputed.value.date) {
          console.error('Date is missing from the current session');
          toast.error('Vänligen ange ett datum för passet.');
          return;
        }

        if (!validateSession(currentSessionComputed.value)) {
          toast.error('Invalid session data. Unable to save.');
          return;
        }

        const sessionData = {
          id: currentSessionComputed.value.id,
          name: currentSessionComputed.value.name || `Session on ${formatDate(currentSessionComputed.value.date)}`,
          date: currentSessionComputed.value.date,
          type: currentSessionComputed.value.type,
          description: currentSessionComputed.value.description || '',
          trainer: currentSessionComputed.value.trainer,
          segments: currentSessionComputed.value.segments.map(segment => ({
            label: segment.label,
            type: segment.type,
            records: segment.records.map(record => {
              const transformedRecord = {
                recordType: record.recordType,
                placeId: record.placeId || ''
              }

              if (record.recordType === 0) {
                transformedRecord.exerciseId = record.exerciseId
                transformedRecord.exerciseName = record.exerciseName
                transformedRecord.repetitions = record.repetitions
                transformedRecord.description = record.description || ''
              } else if (record.recordType === 2) {
                transformedRecord.placeName = record.placeName
              }

              if (record.recordType === 1 || record.recordType === 4 ) {
                transformedRecord.duration = record.duration
                transformedRecord.unit = record.unit
              }

              return transformedRecord
            })
          })),
          metadata: currentSessionComputed.value.metadata,
          ratings: currentSessionComputed.value.ratings || [],
          weather: currentSessionComputed.value.weather || [],
          imageUrl: currentSessionComputed.value.imageUrl || [],
          distance: currentSessionComputed.value.distance,
          elevation: currentSessionComputed.value.elevation,
          cal: currentSessionComputed.value.cal,
          participants: currentSessionComputed.value.participants || [],
        }

        console.log('Saving session with transformed data:', JSON.stringify(sessionData, null, 2));

        let response;
        if (isEditingComputed.value) {
          console.log(`Updating session with ID: ${sessionData.id}`);
          response = await api.updateSession(sessionData.id, sessionData);
        } else {
          console.log('Creating new session');
          response = await api.createSession(sessionData);
        }

        console.log('API response:', response);

        toast.success('Passet har sparats!');
        await props.fetchSessions(); // Refresh the list
        
        // Emit the save event with the current session data
        emit('save', currentSessionComputed.value);
        
        // Close the modal
        closeModal();
      } catch (error) {
        console.error('Error saving session:', error);
        if (error.response) {
          console.error('Error response:', error.response.data);
        }
        toast.error('Kunde inte spara passet. Försök igen.');
      }
    }

    const closeModal = () => {
      emit('update:showModal', false)
    }

    const addSegment = () => {
      currentSessionComputed.value.segments.push({
        id: Date.now(),
        label: 'Nytt avsnitt',
        type: '0',
        records: []
      })
    }

    const removeSegment = (segment) => {
      const index = currentSessionComputed.value.segments.indexOf(segment)
      if (index > -1) {
        currentSessionComputed.value.segments.splice(index, 1)
      }
    }

    const addRecord = (segment) => {
      segment.records.push({
        id: Date.now(),
        recordType: 0,
        exerciseId: null,
        exerciseName: '',
        repetitions: null,
        description: '',
        placeId: '',
        placeName: '',
        duration: null,
        unit: 'seconds'
      })
    }

    const removeRecord = (segment, record) => {
      const index = segment.records.indexOf(record)
      if (index > -1) {
        segment.records.splice(index, 1)
      }
    }

    // Update the updateSegmentType function
    const updateSegmentType = (newType, segment) => {
      console.log('Updating segment type:', newType, 'for segment:', segment)
      segment.type = newType.toString() // Ensure type is stored as string
    }

    const updateSessionType = (newType) => {
      currentSessionComputed.value.type = newType
      // Add any additional logic needed when session type changes
    }

    const mapContainer = ref(null)
    const mapInstance = ref(null)
    const totalDistance = ref(0)
    const totalAscent = ref(0)

    const initMap = async () => {
      console.log('Initializing map')
      await nextTick()
      
      // Wait for the map container to be available
      const waitForContainer = () => {
        return new Promise((resolve) => {
          const checkContainer = () => {
            const container = document.getElementById('session-map')
            if (container) {
              resolve(container)
            } else {
              setTimeout(checkContainer, 100)
            }
          }
          checkContainer()
        })
      }

      try {
        const container = await waitForContainer()
        console.log('Map container found:', container)
        
        if (!mapInstance.value) {
          mapInstance.value = L.map(container).setView([58.93208, 17.57791], 13)
          L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap contributors'
          }).addTo(mapInstance.value)
          console.log('Map instance created successfully')
        }
        mapLoading.value = false
      } catch (error) {
        console.error('Error creating map instance:', error)
        mapLoadError.value = true
        mapLoading.value = false
        toast.error('Kunde inte skapa kartan. Försök igen.')
      }
    }

    // Move this function inside the setup function
    const getExerciseName = (exerciseId) => {
      const exercise = exercises.value.find(e => e.id === exerciseId)
      return exercise ? exercise.name : 'Unknown Exercise'
    }

    const updateMap = () => {
      console.log('updateMap called')
      if (!currentSessionComputed.value || !placesLoaded.value) {
        console.warn('Not ready to update map: session or places not loaded')
        return
      }
      console.log('Updating map with current session:', JSON.stringify(currentSessionComputed.value, null, 2))
      console.log('Available places:', JSON.stringify(props.places, null, 2))
      if (!mapInstance.value) {
        console.log('Map instance not ready, initializing...')
        nextTick(() => {
          initMap().then(() => {
            if (mapInstance.value) {
              updateMapMarkers()
            } else {
              console.warn('Failed to initialize map')
              toast.error('Kunde inte ladda kartan. Försök igen.')
            }
          }).catch(error => {
            console.error('Error initializing map:', error)
            toast.error('Kunde inte ladda kartan. Försök igen.')
          })
        })
      } else {
        updateMapMarkers()
      }
    }

    const updateMapMarkers = () => {
      console.log('Updating map markers...')
      if (!mapInstance.value) {
        console.warn('Map instance not available')
        return
      }

      if (!props.places || props.places.length === 0) {
        console.warn('No places data available')
        return
      }

      console.log('Current session segments:', currentSessionComputed.value.segments)

      // Clear existing markers
      mapInstance.value.eachLayer((layer) => {
        if (layer instanceof L.Marker || layer instanceof L.Polyline) {
          mapInstance.value.removeLayer(layer)
        }
      })

      const markers = []
      const latlngs = []

      currentSessionComputed.value.segments.forEach((segment, segmentIndex) => {
        console.log(`Processing segment ${segmentIndex + 1}: ${segment.label}`)
        if (Array.isArray(segment.records)) {
          const segmentLatLngs = []
          segment.records.forEach((record, recordIndex) => {
            console.log(`Processing record ${recordIndex + 1} in segment ${segmentIndex + 1}`)
            const place = props.places.find(p => p.id === record.placeId)
            if (place && place.location && Array.isArray(place.location.coordinates) && place.location.coordinates.length >= 2) {
              const [lng, lat, elevation] = place.location.coordinates
              if (isNaN(lat) || isNaN(lng)) {
                console.warn(`Invalid coordinates for place ${place.name}: [${lat}, ${lng}]`)
                return
              }
              console.log(`Creating marker for ${place.name} at [${lat}, ${lng}]`)
              const marker = L.marker([lat, lng], {
                icon: L.divIcon({
                  className: 'custom-div-icon',
                  html: `<div style="background-color: ${getSegmentColor(segmentIndex)};" class="marker-pin"></div><i class="material-icons">${getRecordTypeIcon(record.recordType)}</i>`,
                  iconSize: [30, 42],
                  iconAnchor: [15, 42]
                })
              }).addTo(mapInstance.value)
              marker.bindPopup(`<b>${place.name}</b><br>${getExerciseName(record.exerciseId)}`)
              markers.push(marker)
              segmentLatLngs.push([lat, lng])
              latlngs.push([lat, lng, elevation])
            } else {
              console.warn(`Invalid place data for record ${recordIndex + 1} in segment ${segmentIndex + 1}`)
            }
          })

          // Create a polyline for each segment
          if (segmentLatLngs.length > 1) {
            L.polyline(segmentLatLngs, { 
              color: getSegmentColor(segmentIndex),
              weight: 3,
              opacity: 0.7,
              dashArray: '10, 10'
            }).addTo(mapInstance.value)
          }
        } else {
          console.warn(`Invalid records data for segment ${segmentIndex + 1}`)
        }
      })

      if (latlngs.length > 0) {
        console.log(`Creating polyline with ${latlngs.length} points`)
        const polyline = L.polyline(latlngs.map(([lat, lng]) => [lat, lng]), { color: 'red' }).addTo(mapInstance.value)
        mapInstance.value.fitBounds(polyline.getBounds())
      } else {
        console.warn('No valid coordinates found for polyline')
        mapInstance.value.setView([58.93208, 17.57791], 13)
      }

      calculateDistanceAndElevation(latlngs)
    }

    const calculateDistanceAndElevation = (latlngs) => {
      totalDistance.value = 0
      totalAscent.value = 0

      for (let i = 0; i < latlngs.length - 1; i++) {
        const [lat1, lng1, elevation1] = latlngs[i]
        const [lat2, lng2, elevation2] = latlngs[i + 1]
        
        totalDistance.value += calculateHaversineDistance([lat1, lng1], [lat2, lng2])
        
        if (elevation1 !== undefined && elevation2 !== undefined) {
          const elevationChange = Math.abs(elevation2 - elevation1)
          totalAscent.value += elevationChange
        }
      }
    }

    const calculateHaversineDistance = (point1, point2) => {
      const R = 6371e3 // Earth's radius in meters
      const φ1 = point1[0] * Math.PI / 180
      const φ2 = point2[0] * Math.PI / 180
      const Δφ = (point2[0] - point1[0]) * Math.PI / 180
      const Δλ = (point2[1] - point1[1]) * Math.PI / 180

      const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
                Math.cos(φ1) * Math.cos(φ2) *
                Math.sin(Δλ/2) * Math.sin(Δλ/2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))

      return R * c
    }

    watch(() => currentSessionComputed.value, (newSession, oldSession) => {
      if (newSession && (!oldSession || newSession.id !== oldSession.id)) {
        console.log('Current session changed, updating map')
        updateMap()
      }
    }, { deep: true })

    watch(showModalComputed, async (newValue) => {
      if (newValue) {
        console.log('Modal opened, initializing map')
        await nextTick()
        await initMap()
        if (mapInstance.value) {
          updateMapMarkers()
        }
      }
    });

    const updateRecordType = (record, newType) => {
      record.recordType = newType
      // Add any additional logic needed when record type changes
    }

    // Add this watch to update the session type when it changes
    watch(() => currentSessionComputed.value.type, (newType) => {
      updateSessionType(newType)
    })

    // Add this method to initialize record types
    const initializeRecordTypes = () => {
      if (props.currentSession && props.currentSession.segments) {
        props.currentSession.segments.forEach(segment => {
          segment.records.forEach(record => {
            if (!record.recordType) {
              record.recordType = record.recordTypeString || 0 // Default to 'exercise' if not set
            }
          })
        })
      }
    }

    watch(() => props.currentSession, (newSession) => {
      if (newSession) {
        initializeRecordTypes()
      }
    }, { deep: true })

    // Add this method to get the correct label for a record type
    const getRecordTypeLabel = (recordType) => {
      const option = props.recordTypeOptions[recordType]
      return option ? option.name : recordType
    }

    const deleteSession = async () => {
      try {
        await api.deleteSession(currentSessionComputed.value.id)
        toast.success('Passet har tagits bort!')
        await props.fetchSessions() // Refresh the list
        closeModal() // Close the modal after successful delete
      } catch (error) {
        console.error('Error deleting session:', error)
        toast.error('Kunde inte ta bort passet. Försök igen.')
      } finally {
        showDeleteConfirmation.value = false // Close the confirmation dialog
      }
    }

    const confirmDelete = () => {
      showDeleteConfirmation.value = true
    }

    const newExerciseName = ref('')
    const showNewExerciseDialog = ref(false)
    const editExercisesPageRef = ref(null)

    const openNewExerciseDialog = (record) => {
      // Store the current record for later use
      currentRecord.value = record
      
      // Get the search term directly from the record's exerciseName
      const searchTerm = record.exerciseName || record.$el?.querySelector('input')?.value
      
      console.log('Opening new exercise dialog with search term:', searchTerm)
      
      // Set the initial data before showing the dialog
      if (searchTerm) {
        const initialData = {
          name: searchTerm,
          description: '',
          complexityRating: 1,
          intensityRating: 1,
          muscleGroups: [],
          muscleGroupIntensities: [],
          equipment: null,
          imageUrl: [],
          media: []
        }
        
        // Set the initial data and show the dialog
        nextTick(() => {
          if (editExercisesPageRef.value) {
            editExercisesPageRef.value.startAddingNewExercise(initialData)
            showNewExerciseDialog.value = true
          }
        })
      }
    }

    const closeNewExerciseDialog = () => {
      nextTick(() => {
        showNewExerciseDialog.value = false
        currentRecord.value = null // Clear the current record
        // Reset the EditExercisesPage component
        if (editExercisesPageRef.value) {
          editExercisesPageRef.value.cancelEditing()
        }
      })
    }

    const handleNewExerciseSaved = async (newExercise) => {
      console.log('New exercise saved:', newExercise)
      
      // Update the current record with the new exercise first
      if (currentRecord.value) {
        currentRecord.value.exerciseId = newExercise.id
        currentRecord.value.exerciseName = newExercise.name
      }
      
      // Refresh the store's exercise list
      await store.fetchExercises()
      
      // Close the dialog after everything is updated
      closeNewExerciseDialog()
      
      toast.success('Ny övning har lagts till!')
    }

    watch(() => props.places, (newPlaces) => {
      console.log('Places prop updated:', newPlaces);
      updateMap();
    }, { deep: true, immediate: true });

    const updatePlace = (record) => {
      const place = props.places.find(p => p.id === record.placeId)
      if (place) {
        record.placeName = place.name
      }
    }

    const updateExercise = (record) => {
      const exercise = exercises.value.find(e => e.id === record.exerciseId)
      if (exercise) {
        record.exerciseName = exercise.name
      }
    }

    watch(() => props.currentSession, (newSession) => {
      console.log('props.currentSession changed:', newSession);
      if (newSession) {
        currentSessionComputed.value = { ...newSession };
      }
    }, { deep: true });

    const handleResize = () => {
      if (mapInstance.value) {
        mapInstance.value.invalidateSize()
      }
    }

    onMounted(() => {
      window.addEventListener('resize', handleResize)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
    })

    const mapLoadError = ref(false)
    const mapLoading = ref(true)

    const retryLoadMap = () => {
      mapLoadError.value = false
      mapLoading.value = true
      initMap()
    }

    const getSegmentColor = (index) => {
      const colors = ['#FF5733', '#33FF57', '#3357FF', '#FF33F1', '#33FFF1', '#F1FF33']
      return colors[index % colors.length]
    }

    const getRecordTypeIcon = (recordType) => {
      const icons = {
        0: 'fitness_center',
        1: 'timer',
        2: 'school'
      }
      return icons[recordType] || 'place'
    }

    const refreshMap = () => {
      mapLoading.value = true
      updateMap()
    }

    watch(() => currentSessionComputed.value, (newValue) => {
      console.log('currentSessionComputed changed:', JSON.stringify(newValue, null, 2))
    }, { deep: true })

    const onDragStart = (evt) => {
      console.log('Drag started', evt);
    };

    const onDragEnd = (evt) => {
      console.log('Drag ended', evt);
    };

    // Add currentRecord to the reactive state
    const currentRecord = ref(null)

    // Add this helper function
    const updateSessionTime = (date) => {
      if (!date) return
      const defaultTime = isWeekend(date) ? { hours: 10, minutes: 0 } : { hours: 18, minutes: 0 }
      const newDate = set(date, defaultTime)
      currentSessionComputed.value.date = format(newDate, "yyyy-MM-dd'T'HH:mm:ss")
      currentSessionComputed.value.name = `Pass ${format(newDate, 'yyyy-MM-dd')}` // Update the name
    }

    // Update the watch for sessionDate
    watch(() => sessionDate.value, (newDate) => {
      if (newDate) {
        const date = parseISO(newDate)
        updateSessionTime(date)
      }
    })

    // Add this watch
    watch(() => currentSessionComputed.value?.segments, (newSegments) => {
      if (newSegments) {
        newSegments.forEach((segment, index) => {
          console.log(`Segment ${index} type:`, segment.type)
        })
      }
    }, { deep: true })

    return {
      showModalComputed,
      currentSessionComputed,
      sessionTypesComputed,
      isEditingComputed,
      sessionDate,
      sessionTime,
      formattedTrainers,
      formattedExercises,
      formattedPlaces,
      selectedTrainer,
      updateTrainer,
      saveSession,
      closeModal,
      formatDate,
      addSegment,
      removeSegment,
      updateSegmentType,
      updateSessionType,
      segmentTypes,
      recordTypeOptionsFormatted,
      updateRecordType,
      getRecordTypeLabel,
      addRecord,
      removeRecord,
      confirmDelete,
      showDeleteConfirmation,
      deleteSession,
      newExerciseName,
      showNewExerciseDialog,
      openNewExerciseDialog,
      closeNewExerciseDialog,
      handleNewExerciseSaved,
      editExercisesPageRef,
      mapContainer,
      totalDistance,
      totalAscent,
      getExerciseName,
      updateMap,
      updatePlace,
      updateExercise,
      mapLoadError,
      mapLoading,
      retryLoadMap,
      refreshMap,
      onDragStart,
      onDragEnd,
      currentRecord,
    }
  }
}
</script>

<style scoped>
/* Base theme variables */
.edit-session-dialog {
  --modal-bg: rgb(var(--v-theme-background));
  --modal-text: rgb(var(--v-theme-on-background));
  --modal-border: rgb(var(--v-theme-outline));
  --modal-hover: rgb(var(--v-theme-surface-variant));
}

/* Dialog and overlay styles */
:deep(.v-dialog),
:deep(.v-overlay__content) {
  background-color: rgb(var(--v-theme-background)) !important;
}

:deep(.v-card) {
  background-color: rgb(var(--v-theme-background)) !important;
  color: rgb(var(--v-theme-on-background));
}

:deep(.v-card-text) {
  background-color: rgb(var(--v-theme-background)) !important;
}

/* Add these new styles */
:deep(.v-overlay__scrim) {
  background-color: rgb(var(--v-theme-background)) !important;
  opacity: 1 !important;
}

.edit-modal {
  background-color: rgb(var(--v-theme-background)) !important;
  min-height: 100vh !important;
}

.edit-form {
  background-color: rgb(var(--v-theme-background)) !important;
}

/* Form styles */
:deep(.v-text-field .v-field__input),
:deep(.v-autocomplete .v-field__input) {
  color: rgb(var(--v-theme-on-surface));
}

:deep(.v-text-field .v-field__outline),
:deep(.v-autocomplete .v-field__outline) {
  --v-field-border-color: rgb(var(--v-theme-outline));
}

:deep(.v-text-field--focused .v-field__outline),
:deep(.v-autocomplete--focused .v-field__outline) {
  --v-field-border-color: rgb(var(--v-theme-primary));
}

/* Segment and record styles */
.segment {
  background-color: rgb(var(--v-theme-surface)) !important;
  border: 1px solid rgb(var(--v-theme-outline));
  margin-bottom: 1rem;
  border-radius: 4px;
}

.segment-drag-handle,
.record-drag-handle {
  cursor: grab;
}

.records-container {
  background-color: rgb(var(--v-theme-background)) !important;
  padding: 1rem;
}

.record {
  background-color: rgb(var(--v-theme-surface)) !important;
  border: 1px solid rgb(var(--v-theme-outline));
  margin-bottom: 0.5rem;
  border-radius: 4px;
}

/* Map container styles */
.map-container {
  position: relative;
  border: 1px solid rgb(var(--v-theme-outline));
  border-radius: 4px;
  overflow: hidden;
}

.map-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(var(--v-theme-surface), 0.7);
}

/* Custom transitions */
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.3s ease-in-out;
}

.dialog-bottom-transition-enter-from,
.dialog-bottom-transition-leave-to {
  transform: translateY(100%);
}

/* Icon styles */
:deep(.v-icon) {
  color: rgb(var(--v-theme-on-surface-variant));
}

:deep(.v-btn:hover .v-icon) {
  color: rgb(var(--v-theme-on-surface));
}

:deep(.v-btn.error .v-icon) {
  color: rgb(var(--v-theme-error));
}

/* List item styles */
:deep(.v-list-item) {
  color: rgb(var(--v-theme-on-surface));
}

:deep(.v-list-item:hover) {
  background-color: rgb(var(--v-theme-surface-variant));
}

:deep(.v-list-item--active) {
  background-color: rgb(var(--v-theme-primary));
  color: rgb(var(--v-theme-on-primary));
}
</style>