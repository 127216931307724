<template>
  <div class="map-container">
    <div
      ref="mapContainer"
      class="map"
    />
  </div>
</template>

<script>
import { ref, onMounted, watch, onBeforeUnmount } from 'vue'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

// Add this code to set up default icons
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  name: 'MapComponent',
  props: {
    latitude: {
      type: Number,
      required: true,
      default: 0
    },
    longitude: {
      type: Number,
      required: true,
      default: 0
    },
    layer: {
      type: String,
      default: 'satellite'
    },
    markers: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:coordinates', 'marker-click'],
  setup(props, { emit }) {
    const mapContainer = ref(null)
    const map = ref(null)
    const marker = ref(null)
    const osmLayer = ref(null)
    const satelliteLayer = ref(null)

    const initializeMap = () => {
      console.log('Initializing map');
      if (map.value) {
        console.log('Map already initialized, skipping');
        return;
      }

      const lat = props.latitude || 0
      const lng = props.longitude || 0

      map.value = L.map(mapContainer.value).setView([lat, lng], 13)

      osmLayer.value = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors'
      })

      satelliteLayer.value = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
      })

      if (props.layer === 'satellite') {
        satelliteLayer.value.addTo(map.value)
      } else {
        osmLayer.value.addTo(map.value)
      }

      marker.value = L.marker([lat, lng], { draggable: true }).addTo(map.value)

      marker.value.on('dragend', (event) => {
        const position = event.target.getLatLng()
        emit('update:coordinates', { lat: position.lat, lng: position.lng })
      })

      map.value.on('click', (event) => {
        marker.value.setLatLng(event.latlng)
        emit('update:coordinates', { lat: event.latlng.lat, lng: event.latlng.lng })
      })

      console.log('Map initialized successfully');
    }

    const switchLayer = (layerType) => {
      console.log('Switching to layer:', layerType);
      if (!map.value) {
        console.error('Map is not initialized');
        return;
      }

      try {
        if (layerType === 'osm') {
          if (map.value.hasLayer(satelliteLayer.value)) {
            map.value.removeLayer(satelliteLayer.value);
          }
          if (!map.value.hasLayer(osmLayer.value)) {
            map.value.addLayer(osmLayer.value);
          }
        } else if (layerType === 'satellite') {
          if (map.value.hasLayer(osmLayer.value)) {
            map.value.removeLayer(osmLayer.value);
          }
          if (!map.value.hasLayer(satelliteLayer.value)) {
            map.value.addLayer(satelliteLayer.value);
          }
        }
        console.log('Layer switch completed');
      } catch (error) {
        console.error('Error switching layers:', error);
      }
    }

    const addMarkers = () => {
      props.markers.forEach(markerData => {
        const marker = L.marker([markerData.lat, markerData.lng]).addTo(map.value)
        marker.on('click', () => {
          emit('marker-click', markerData.id)
        })
      })
    }

    watch(() => props.layer, (newLayer) => {
      console.log('Layer prop changed:', newLayer);
      if (map.value) {
        switchLayer(newLayer);
      }
    })

    watch([() => props.latitude, () => props.longitude], ([newLat, newLng]) => {
      console.log('Coordinates updated:', newLat, newLng);
      if (map.value && marker.value && newLat !== null && newLng !== null) {
        const newLatLng = L.latLng(newLat, newLng)
        marker.value.setLatLng(newLatLng)
        map.value.setView(newLatLng)
      }
    })

    watch(() => props.markers, () => {
      if (map.value) {
        map.value.eachLayer(layer => {
          if (layer instanceof L.Marker) {
            map.value.removeLayer(layer)
          }
        })
        addMarkers()
      }
    }, { deep: true })

    onMounted(() => {
      console.log('MapComponent mounted');
      initializeMap();
      addMarkers();
    })

    onBeforeUnmount(() => {
      console.log('MapComponent unmounting');
      if (map.value) {
        map.value.remove();
        map.value = null;
      }
    })

    return {
      mapContainer
    }
  }
}
</script>

<style scoped>
.map-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.map {
  height: 100%;
  width: 100%;
}
</style>
